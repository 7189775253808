<template>
    <div class="container">
        <div class="common-topbox">
            <!-- 标题区域 -->
            <Title title="退件" intro="退回/退款快递记录">
                <template v-slot:right>
                    <!-- 右侧按钮区域插槽 -->
                    <!-- <div class="common-title-btn-secondary" @click="reflashAll()">
                            <font-awesome-icon icon="fa-solid fa-arrow-rotate-right" />
                        </div> -->
                    <div class="common-title-btn-secondary common-title-btn-icon copy" :data-clipboard-text="numbers"
                        v-tippy content="复制本页单号">
                        <font-awesome-icon icon="fa-solid fa-copy" />
                    </div>
                    <div class="common-title-btn-main common-title-btn-icon" @click="openAddModal()" v-tippy
                        content="添加需退回的快递">
                        <font-awesome-icon icon="fa-solid fa-plus" />
                    </div>
                    <div class="common-title-btn-main" @click="openBackModal()">
                        退货入库
                    </div>
                </template>
            </Title>
            <!-- 筛选 -->
            <FilterExpress :statusCode="returnStatusCode" :filterValue="filterValue" :selectedCode="selectedReturn"
                :showFilterBtn="true" @changeStatus="changeReturn" @submitFilter="submitFilter"
                @resetFilter="resetFilter" />
        </div>
        <!-- 表格 -->
        <ExpressTable :tableData="tableData" v-if="tableData" />
        <div class="common-bottombox">
            <Pager :pageSizes="[16, 50, 100, 500]" :tablePage="tablePage" @handlePageChange="handlePageChange" />
        </div>
        <!-- 弹窗 -->
        <van-dialog v-model:show="showAddModal" @confirm="submitAdd()" theme="round" title="添加需退回快递" show-cancel-button
            confirm-button-text="提交" confirm-button-color="#6667ab" cancel-button-text="取消" cancel-button-color="#666">
            <div class="common-modalbox">
                <textarea class="modal-add-numbers2" v-model="addInfo.numbers"
                    placeholder="快递单号(使用空格或换行分隔多个单号)"></textarea>
                <textarea class="modal-add-textarea" v-model="addInfo.remarks" placeholder="备注(选填)"></textarea>
                <!-- Todo 快捷输入 -->
            </div>
        </van-dialog>
        <!-- 弹窗 -->
        <van-dialog v-model:show="showBackModal" @confirm="submitBack()" theme="round" title="退货入库" show-cancel-button
            confirm-button-text="入库" confirm-button-color="#6667ab" cancel-button-text="取消" cancel-button-color="#666">
            <div class="common-modalbox">
                <textarea class="modal-add-numbers" v-model="backInfo.numbers"
                    placeholder="快递单号(使用空格或换行分隔多个单号)"></textarea>
            </div>
        </van-dialog>
        <!-- 入库结果 Add -->
        <van-dialog v-model:show="showAddResult" width="500" title="提交结果" confirmButtonText="好的"
            confirmButtonColor="#6667ab">
            <div class="modal-backresult" v-if="addRseult">
                <div class="modal-backresult-infobox">
                    <div>
                        共提交{{ addRseult.allLength }}单
                    </div>
                    <div>
                        成功{{ addRseult.okLength }}单
                    </div>

                    <div v-if="addRseult.errorLength">
                        失败<b>{{ addRseult.errorLength }}</b>单
                    </div>

                    <!-- ，成功{{}}}}单，失败{{}}单 -->
                </div>
                <table v-if="addRseult.errors && addRseult.errors.length > 0" class="modal-backresult-table">
                    <vxe-table round border highlight-hover-row resizable ref="xTableResult" :data="addRseult.errors"
                        maxHeight="400">
                        <vxe-table-column field="number" title="失败单号"></vxe-table-column>
                        <vxe-table-column field="errorMessage" title="失败原因"></vxe-table-column>
                    </vxe-table>
                </table>
            </div>
        </van-dialog>
        <!-- 入库结果 Back -->
        <van-dialog v-model:show="showBackResult" width="500" title="提交结果" confirmButtonText="好的"
            confirmButtonColor="#6667ab">
            <div class="modal-backresult" v-if="backRseult">
                <div class="modal-backresult-infobox">
                    <div>
                        共提交{{ backRseult.allLength }}单
                    </div>
                    <div>
                        成功{{ backRseult.okLength }}单
                    </div>

                    <div v-if="backRseult.errorLength">
                        失败<b>{{ backRseult.errorLength }}</b>单
                    </div>

                    <!-- ，成功{{}}}}单，失败{{}}单 -->
                </div>
                <table v-if="backRseult.errors && backRseult.errors.length > 0" class="modal-backresult-table">
                    <vxe-table round border highlight-hover-row resizable ref="xTableResult" :data="backRseult.errors"
                        maxHeight="400">
                        <vxe-table-column field="number" title="失败单号"></vxe-table-column>
                        <vxe-table-column field="errorMessage" title="失败原因"></vxe-table-column>
                    </vxe-table>
                </table>
            </div>
        </van-dialog>
    </div>
</template>

<script>
    import Clipboard from 'clipboard'
    import Navbar from '@/components/common/navbar'
    import ExpressTable from '@/components/express/expressTable'
    import Title from '@/components/common/title'
    import FilterExpress from '@/components/express/filterExpress'
    import Pager from '@/components/common/pager'
    import { Toast, Dialog } from 'vant';
    import { apiGetExpressList, apiAddReturnPacket, apiAddNeedReturn, apiGetReturnNumber, apiReflashExpress } from '@/request/api'
    import axios from 'axios'; // 引入axios

    export default {
        components: {
            Navbar,
            ExpressTable,
            Title,
            FilterExpress,
            Pager,
        },
        name: 'Index',
        // props: {
        //   msg: String
        // },
        data() {
            return {
                loading: false,
                showAddModal: false,
                showBackModal: false,
                showFilterModal: false,
                tableData: null,
                tablePage: {
                    currentPage: 1,
                    pageSize: 100,
                    totalResult: 0
                },
                selectedReturn: 701,
                addInfo: {
                    numbers: null,
                    remarks: null,
                },
                addRseult: null,
                showAddResult: false,
                backInfo: {
                    numbers: null,
                },
                backRseult: null,
                showBackResult: false,
                returnStatusCode: [],
                filterValue: {
                    com: null,
                    platformId: null,
                    brandId: null,
                },
                companyList: null,
                brandList: null,
                platformList: null,
                device: this.GLOBAL.device,
                numbers: null,
            }
        },
        mounted() {
            // url参数
            let query = this.$route.query
            if (query.status) {
                this.selectedReturn = query.status
            }
            this.init()
        },
        methods: {
            init() {
                this.initClipboard()
                this.getReturnStatusCode()
                this.getList()
                this.getCompanyList()
                this.getBrandList()
                this.getPlatformList()
            },
            initClipboard() {
                const clipboard = new Clipboard(".copy");
                clipboard.on("success", function (e) {
                    Toast.success('已复制到剪贴板');
                    e.clearSelection();
                });
                clipboard.on("error", function (e) {
                    Toast.fail('复制失败');
                });
            },
            getList() {
                let self = this
                let params = {}
                params.page = this.tablePage.currentPage
                params.size = this.tablePage.pageSize
                params.returnStatus = this.selectedReturn
                if (this.filterValue.com) {
                    params.com = this.filterValue.com
                }
                if (this.filterValue.platformId) {
                    params.platformId = this.filterValue.platformId
                }
                if (this.filterValue.brandId) {
                    params.brandId = this.filterValue.brandId
                }
                apiGetExpressList(params).then(res => {
                    self.tableData = res.data.items
                    self.tablePage.totalResult = res.data.count
                    self.getNumbers()
                }).catch(err => {
                    console.warn(err)
                })
            },

            getNumbers() {
                let result = ""
                for (let index = 0; index < this.tableData.length; index++) {
                    result = result + '\n' + this.tableData[index].number
                }
                this.numbers = result
            },
            getCompanyList() {
                this.companyList = JSON.parse(localStorage.getItem('companyList'))
            },
            getBrandList() {
                this.brandList = JSON.parse(localStorage.getItem('brandList'))
            },
            getPlatformList() {
                this.platformList = JSON.parse(localStorage.getItem('platformList'))
            },
            handlePageChange({ currentPage, pageSize }) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getList()
            },
            getReturnStatusCode() {
                this.returnStatusCode = JSON.parse(localStorage.getItem('constantReturnStatusCode'))
                this.getNumber()
            },
            getNumber() {
                let self = this
                apiGetReturnNumber().then(res => {
                    self.updateStatusCode(res.data)
                }).catch(err => {
                    console.warn(err)
                })
            },
            // updateStatusCode(data) {
            //     for (let index = 0; index < this.returnStatusCode.length; index++) {
            //         this.returnStatusCode[index].number = data[this.returnStatusCode[index].key]
            //         let importantStatus = "701,702,703,704,706"
            //         if (importantStatus.indexOf(this.returnStatusCode[index].key) > -1) {
            //             this.returnStatusCode[index].important = true
            //         }
            //     }
            // },
            updateStatusCode(data) {
                let returnStatusCodeNew = JSON.parse(JSON.stringify(this.returnStatusCode))
                for (let index = 0; index < returnStatusCodeNew.length; index++) {
                    returnStatusCodeNew[index].number = data[returnStatusCodeNew[index].key]
                    let importantStatus = "701,702,703,704,706"
                    if (importantStatus.indexOf(returnStatusCodeNew[index].key) > -1) {
                        returnStatusCodeNew[index].important = true
                    }
                }
                this.returnStatusCode = returnStatusCodeNew
            },
            openAddModal() {
                this.showAddModal = true
            },
            openBackModal() {
                this.showBackModal = true
                // apiAddNeedReturn
            },
            submitAdd() {
                Toast.loading('提交中')
                let self = this
                let numbers = this.addInfo.numbers.split(/[\s\n]/) //转化为数组
                numbers = this.unique(numbers) // 去重
                let params = []
                for (let index = 0; index < numbers.length; index++) {
                    if (numbers[index]) {
                        let obj
                        if (self.addInfo.remarks) {
                            obj = {
                                number: numbers[index],
                                remarks: self.addInfo.remarks,
                            }
                        } else {
                            obj = {
                                number: numbers[index],
                            }
                        }
                        params.push(obj)
                    }
                }
                let allLength = params.length
                apiAddNeedReturn(params).then(res => {
                    let result = res.data
                    result.allLength = allLength
                    if (result.errors.length > 0) {
                        result.errorLength = result.errors.length
                        result.okLength = allLength - result.errors.length
                    } else {
                        result.errorLength = null
                        result.okLength = allLength
                    }
                    self.addRseult = result
                    Toast.clear()
                    self.showAddResult = true
                    if (result.okLength > 0) {
                        self.getList()
                    }
                }).catch(err => {
                    console.warn(err)
                })
            },
            submitBack() {
                Toast.loading('提交中')
                let self = this
                let numbers = this.backInfo.numbers.split(/[\s\n]/) //转化为数组
                numbers = this.unique(numbers) // 去重
                let params = []
                for (let index = 0; index < numbers.length; index++) {
                    if (numbers[index]) {
                        // 圆通特殊处理
                        let theNumber = numbers[index]
                        // R02TYT8967926184883 => YT8967926184883
                        if (theNumber.indexOf('R02TYT') > -1) {
                            theNumber = theNumber.replace('R02T', '')
                        }
                        let obj = {
                            number: theNumber
                        }
                        params.push(obj)
                    }
                }
                let allLength = params.length
                apiAddReturnPacket(params).then(res => {
                    let result = res.data
                    result.allLength = allLength
                    if (result.errors.length > 0) {
                        result.errorLength = result.errors.length
                        result.okLength = allLength - result.errors.length
                    } else {
                        result.errorLength = null
                        result.okLength = allLength
                    }
                    self.backRseult = result
                    Toast.clear()
                    self.showBackResult = true
                    if (result.okLength > 0) {
                        self.getList()
                    }
                    self.webhook(self.backInfo.numbers)
                    self.backInfo.numbers = null
                }).catch(err => {
                    console.warn(err)
                })
            },
            webhook(data) {
                let url = 'https://fc.moemee.com/mrp-webhook'
                let params = {
                    msgtype: 'text',
                    message: '退回快递入库：' + data
                }
                let self = this
                axios({
                    method: "post",
                    url: url,
                    params: params,
                    headers: {
                        'content-type': 'application/json', // 默认值
                    }
                }).then(res => {
                    console.log("res", res)
                }).catch(err => {
                    console.log("err", err)
                })
            },
            unique(arr) {
                // 去重功能
                if (!Array.isArray(arr)) {
                    console.warn('type error!')
                    return
                }
                let array = [];
                for (let i = 0; i < arr.length; i++) {
                    if (array.indexOf(arr[i]) === -1) {
                        array.push(arr[i])
                    }
                }
                return array;
            },
            changeReturn(id) {
                this.selectedReturn = id
                this.tablePage.currentPage = 1
                this.getList()
                this.getNumber()
            },
            submitFilter() {
                this.tablePage.currentPage = 1
                this.getList()
                this.getNumber()
            },
            resetFilter() {
                this.tablePage.currentPage = 1
                this.filterValue = {
                    com: null,
                    platformId: null,
                    brandId: null,
                    overTime: null,
                }
                this.getList()
                this.getNumber()
            },
            openFilterModal() {
                this.showFilterModal = true
            },
            reflashAll() {
                Toast.loading('刷新中')
                let data = JSON.parse(JSON.stringify(this.tableData))
                // for (let index = 0; index < data.length; index++) {
                //     this.reflashOne(data[index])
                // }
                let promiseArr = []
                data.forEach((item, index) => {
                    promiseArr.push((async (item) => {
                        await this.reflashOne(item, index)
                    })(item))
                })
                Promise.all(promiseArr).then(itemList => {
                    Toast.clear()
                    Toast.success('全部刷新完成');
                    this.getList()
                })
            },
            reflashOne(oneData, index) {
                let self = this
                return new Promise((resolve, reject) => {
                    apiReflashExpress(oneData).then(res => {
                        // 
                        Toast.loading('正在刷新 ' + index);
                        resolve('成功')
                    }).catch(err => {
                        console.warn(err)
                        reject('失败')
                    })
                });
            },
        }
    }
</script>

<style scoped>

    /* 筛选功能 */
    .selectbox {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 15px;
    }

    .select-left {
        width: 1px;
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;

    }

    .select-single {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: #e9e9eb;
        color: #666;
        border-right: 1px solid #fff;
        height: 32px;
        cursor: pointer;
        padding: 0 6px;
    }

    .select-single:first-child {
        border-radius: 16px 0 0 16px;
        padding-left: 4px;
    }

    .select-single:last-child {
        border-radius: 0 16px 16px 0;
        border-right: none;
        padding-right: 4px;
    }

    .select-single:hover {
        opacity: 0.7;
    }

    .select-name {
        font-size: 14px;
        word-break: keep-all;
        white-space: nowrap;
    }

    .select-number {
        margin-left: 3px;
        font-size: 12px;
        background-color: #e9e9eb;
        font-weight: bold;
        color: #666;
        padding: 0 4px;
        height: 18px;
        min-width: 18px;
        text-align: center;
        line-height: 18px;
        border-radius: 9px;
        word-break: keep-all;
        white-space: nowrap;
    }

    .select-number.active {
        background-color: #fd4343;
        color: #fff;
    }

    .select-single.active {
        background-color: #333;
        color: #fff;
    }

    /* 弹窗 手动录入快递 */
    /* .modal-add-select {
        width: 100%;
        height: 30px;
        border-radius: 15px;
        border: 1px solid #e6e6ef;
        outline: none;
        margin-bottom: 15px;
        padding: 0 10px;
        font-size: 14px;
        color: #333;
    } */

    .modal-add-numbers {
        width: 100%;
        height: 300px;
        border-radius: 15px;
        border: 1px solid #e6e6ef;
        outline: none;
        padding: 10px 10px;
        font-size: 14px;
        color: #333;
        resize: none;
    }

    .modal-add-numbers2 {
        width: 100%;
        height: 200px;
        border-radius: 15px;
        border: 1px solid #e6e6ef;
        outline: none;
        padding: 10px 10px;
        font-size: 14px;
        color: #333;
        resize: none;
    }

    .modal-add-textarea {
        width: 100%;
        height: 80px;
        border-radius: 15px;
        border: 1px solid #e6e6ef;
        outline: none;
        padding: 10px 10px;
        font-size: 14px;
        color: #333;
        resize: none;
        margin-top: 15px;
    }

    /* 失败提示 */
    .modal-backresult {
        width: 100%;
        padding: 15px;
    }

    .modal-backresult-infobox {
        display: flex;
        flex-direction: row;
        padding: 0 3px;
    }

    .modal-backresult-infobox div {
        flex: 1;
        background-color: #efefef;
        margin-right: 10px;
        padding: 4px;
        text-align: center;
        font-size: 14px;
        border-radius: 5px;
    }

    .modal-backresult-infobox div:last-child {
        margin-right: 0;
    }

    .modal-backresult-infobox b {
        color: #fe4343;
    }

    .modal-backresult-table {
        width: 100%;
        margin-top: 10px;
    }

    /* 筛选按钮 */
    .select-right {
        margin-left: 6px;
        width: 32px;
        height: 32px;
        line-height: 32px;
        border-radius: 16px;
        font-size: 14px;
        background-color: #e9e9eb;
        color: #666;
        outline: none;
        cursor: pointer;
        text-align: center;
        position: relative;
    }

    .select-right.active {
        background-color: #fd4343;
        color: #fff;

    }

    /* 筛选框 */
    .common-modalbox {
        padding-left: 20px;
    }

    .modal-add-checkbox {
        margin-bottom: 15px;
    }

    .modal-add-checkbox-title {
        margin-bottom: 10px;
        font-size: 14px;
        color: #666;
    }

    .modal-add-checkbox-option {
        margin-bottom: 6px;
        font-size: 14px;
    }

    /* 筛选 */
    .select-tips {
        position: absolute;
        bottom: 35px;
        right: 0px;
        background-color: rgba(0, 0, 0, .5);
        padding: 0 15px;
        font-size: 12px;
        height: 30px;
        line-height: 30px;
        border-radius: 15px;
        white-space: nowrap;
        word-break: keep-all;
        animation: myanime 0.6s ease;
    }

    @keyframes myanime {
        0% {
            transform: scale(0, 0)rotate(10deg)translateX(50%)translateY(50%)
                /* transform: scale(0,0) rotate(30deg) translate(50px -10px) */
        }

        60% {
            transform: scale(1.2, 1.2)rotate(-2deg)translateX(-10%)translateY(-10%)
        }

        100% {
            transform: scale(1, 1)rotate(0)translateX(0)translateY(0)
                /* transform: scale(1,1)  rotate(0deg) translate(0 0) */
        }
    }

    .select-tips-box {
        display: inline-block;
        color: #fff;
    }

    .select-tips-box:last-child .select-tips-point {
        display: none;
        color: #fff;
    }
</style>