<template>
    <div class="common-midbox">
        <!-- 快递物流 -->
        <ExpressTableMobile :tableData="tableData" :showExpDetail="showExpDetail" :returnStatusCode="returnStatusCode"
            :flags="flags" v-if="device == 'mobile' || showMobile" @edit="edit" @mark="mark" @stop="stop"
            @addReturn="addReturn" @cancelReturn="cancelReturn" @stopReturn="stopReturn" @openPhoto="openPhoto"
            @reflash="reflash" />
        <ExpressTablePc :tableData="tableData" :showExpDetail="showExpDetail" :returnStatusCode="returnStatusCode"
            :flags="flags" v-else @edit="edit" @mark="mark" @stop="stop" @addReturn="addReturn"
            @cancelReturn="cancelReturn" @stopReturn="stopReturn" @openPhoto="openPhoto" @reflash="reflash" />
        <!-- 弹窗 -->
        <!-- 编辑快递 -->
        <van-dialog v-model:show="showEditModal" @confirm="submitEdit()" theme="round" v-if="showEditModal"
            :title="'编辑 ' + currentExpressInfo.number" show-cancel-button confirm-button-text="提交"
            confirm-button-color="#6667ab" cancel-button-text="取消" cancel-button-color="#666">
            <div class="common-modalbox">
                <select class="modal-add-select" v-model="currentExpressInfo.com" placeholeditder="快递公司">
                    <option :value="null">未填写快递公司</option>
                    <option :value="item.code" v-for="item in companyList">{{ item.name }}</option>
                    <!-- <option>顺丰快递</option> -->
                </select>
                <select class="modal-add-select" v-model="currentExpressInfo.brand.id" placeholder="所属品牌">
                    <option :value="null">未填写品牌</option>
                    <option :value="item.id" v-for="item in brandList">{{ item.name }}</option>
                </select>
                <select class="modal-add-select" v-model="currentExpressInfo.platform.id" placeholder="所属平台">
                    <option :value="null">未填写平台</option>
                    <option :value="item.id" v-for="item in platformList">{{ item.name }}</option>
                </select>
                <input class="modal-add-input" v-model="currentExpressInfo.phone" type="phone" placeholder="发货人手机号" />
                <input class="modal-add-input" v-model="currentExpressInfo.orderNumber" type="text" placeholder="订单号" />
                <select class="modal-add-select" v-model="currentExpressInfo.flag" placeholder="Flag">
                    <option :value="null">无Flag</option>
                    <option :value="item.key" v-for="item in flags">{{ item.icon + item.value }}</option>
                </select>
                <textarea class="modal-add-textarea" v-model="currentExpressInfo.remarks" placeholder="备注"></textarea>
            </div>
        </van-dialog>
        <!-- 编辑备注 -->
        <van-dialog v-model:show="showRemarkModal" :width="modelWidth" @confirm="submitEdit()" theme="round"
            v-if="showRemarkModal" :title="currentExpressInfo.number + ' 的备注'" show-cancel-button
            confirm-button-text="提交" confirm-button-color="#6667ab" cancel-button-text="取消" cancel-button-color="#666">
            <div class="common-modalbox common-modalbox-remark">
                <div class="modal-add-checkbox">
                    <div class="modal-add-checkbox-title">
                        Flag
                    </div>
                    <van-radio-group v-model="currentExpressInfo.flag"
                        :direction="device == 'mobile' ? 'vertical' : 'horizontal'">
                        <van-radio class="radio-block" :name="null">🏳️ 无Flag</van-radio>

                        <van-radio class="radio-block" :name="item.key" v-for="(item, index) in flags" :key="item.key">
                            <span v-if="item.key == 'straw'">🍓 重点关注问题件</span>
                            <span v-if="item.key == 'orang'">🍊 发货仅退款</span>
                            <span v-if="item.key == 'grape'">🍇 客服已回复处理结果</span>
                        </van-radio>
                    </van-radio-group>
                </div>
                <div class="modal-add-checkbox">
                    <div class="modal-add-checkbox-title">
                        备注
                    </div>
                    <textarea class="modal-add-textarea" v-model="currentExpressInfo.remarks"
                        placeholder="备注"></textarea>
                </div>
                <!--  -->
                <div class="modal-add-checkbox">
                    <div class="modal-add-checkbox-title">快捷备注</div>
                    <div class="modal-quick-box">
                        <div class="modal-quickword" @click="quickMark(item)" v-for="item in quickwords">{{ item }}
                        </div>
                    </div>
                </div>
            </div>
        </van-dialog>
        <!-- 查看照片 -->
        <van-dialog v-model:show="showPhotoModal" :width="modelWidth" theme="round" v-if="showPhotoModal"
            :title="currentExpressInfo.number + ' 发货照片'" confirmButtonText="关闭">
            <div class="common-modalbox common-modalbox-photo">
                <!-- 搜索框 -->
                <div class="modal-add-checkbox">
                    <div class="modal-photo-search">
                        <input v-model="photoTimeSearchText" class="modal-photo-input" type="text"
                            placeholder="请输入 或 选择发货时间" />
                        <!-- 搜索按钮 -->
                        <button class="modal-photo-search-btn" @click="searchPhotoBtn()">
                            <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
                        </button>
                    </div>
                    <div class="modal-add-checkbox-title">发货时间</div>
                    <div class="modal-photo-time-box" v-if="photoTimes && photoTimes.length > 0">
                        <div class="modal-photo-time" v-for="item in photoTimes" :key="item"
                            @click="clickPhotoTime(item)">
                            <div>{{ item }}</div>
                        </div>
                    </div>
                    <div class="modal-photo-time-tip" v-else-if="photoTimes && photoTimes.length <= 0">
                        无发货时间
                    </div>
                    <van-loading style="padding: 10px 10px;" color="#1989fa" v-else />
                </div>
                <!-- Loading -->
                <div class="modal-add-checkbox" v-if="photos && photos.length > 0">
                    <div class="modal-add-checkbox-title">照片</div>
                    <viewer class="modal-photo-box" ref="viewer" :images="photos" :options="photoOptions">
                        <img class="modal-photo-one" :src="item.src" :data-source="item.dataSource"
                            v-for="item in photos" alt="" />
                        <div class="modal-photo-loadmore" @click="loadMorePhoto()" v-if="this.photoLoadPage < 6">
                            加载更多
                        </div>
                        <div class="modal-photo-loadmore-end" v-else>
                            到底了
                        </div>
                    </viewer>
                </div>
            </div>
        </van-dialog>
    </div>
</template>

<script>

    import 'viewerjs/dist/viewer.css'
    import VueViewer from 'v-viewer'
    import Vue from 'vue'
    Vue.use(VueViewer)
    import ExpressTableMobile from './expressTableMobile';
    import ExpressTablePc from './expressTablePC';
    import { Toast, Dialog, Loading } from 'vant';
    import download from "downloadjs"
    import { apiEditExpress, apiReflashExpress, apiStopExpress, apiGetPackList, apiGetExpressPhoto } from '@/request/api'

    export default {
        name: 'expressTable',
        components: {
            ExpressTableMobile,
            ExpressTablePc,
        },
        props: {
            tableData: Array,
            showExpDetail: Boolean,
            showMobile: Boolean,
        },
        data() {
            return {
                device: this.GLOBAL.device,
                // 弹窗
                showEditModal: false,
                showRemarkModal: false,
                showPhotoModal: false,
                photoTimes: null,
                modelWidth: '340',
                quickwords: [
                    "拒收退款",
                    "买家已退款",
                    "物流停滞",
                    "客服记遗失",
                    "已催件",
                ],
                // INFO
                currentExpressInfo: {
                    "id": null,
                    "com": null,
                    "brand": {
                        "id": null,
                    },
                    "platform": {
                        "id": null,
                    },
                    "phone": null,
                    "number": null,
                    "flag": null,
                    "remarks": null,
                    "returnStatus": null,
                },
                photoOptions: {
                    toolbar: {
                        zoomIn: 2,
                        zoomOut: 2,
                        oneToOne: 2,
                        reset: 2,
                        rotateLeft: 2,
                        rotateRight: 2,
                        prev: 1,
                        next: 1,
                        flipHorizontal: 0,
                        flipVertical: 0,
                        play: 0,
                        download: {
                            show: 1,
                            title: '下载图片',
                            icon: 'custom-icon-download', // 自定义图标类名，需要自己定义CSS样式
                            click: () => {
                                const viewer = this.$refs.viewer;
                                const index = viewer.$viewer.index;
                                const img = viewer.images[index];
                                console.log('点击的图片:', img);
                                if (img) {
                                    let url = img.dataSource
                                    let filename = img.filename
                                    this.downloadItem(url, filename)
                                } else {
                                    console.log('未找到图片元素');
                                }
                            }
                        }
                    },
                    title: 2,
                    url: 'data-source',
                    zIndex: 10000,
                },
                // 基础信息
                companyList: null,
                brandList: null,
                platformList: null,
                returnStatusCode: null,
                flags: [],
                photoTimeSearchText: null,
                photoLoadPage: 1,
                photos: [],

            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {
                // this.initClipboard()
                this.getCompanyList()
                this.getBrandList()
                this.getPlatformList()
                this.getFlagList()
                this.getReturnStatusCode()
                this.getModelWidth()
            },
            downloadItem(dataURL, filename) {
                let x = new XMLHttpRequest();
                x.open("GET", dataURL, true);
                x.responseType = "blob";
                x.onload = function (e) {
                    download(e.target.response, filename, "image/jpeg");
                };
                x.send();
            },
            getModelWidth() {
                if (this.device != 'mobile') {
                    this.modelWidth = '1000'
                }
            },
            // 获取基础信息
            getCompanyList() {
                this.companyList = JSON.parse(localStorage.getItem('companyList'))
            },
            getBrandList() {
                this.brandList = JSON.parse(localStorage.getItem('brandList'))
            },
            getPlatformList() {
                this.platformList = JSON.parse(localStorage.getItem('platformList'))
            },
            getReturnStatusCode() {
                this.returnStatusCode = JSON.parse(localStorage.getItem('constantReturnStatusCode'))
            },
            getFlagList() {
                let constantFlag = JSON.parse(localStorage.getItem('constantFlag'))
                this.flags = constantFlag
            },
            // 编辑快递
            edit(row) {
                this.currentExpressInfo = JSON.parse(JSON.stringify(row))
                if (!this.currentExpressInfo.brand) {
                    this.currentExpressInfo.brand = {
                        id: null
                    }
                }
                if (!this.currentExpressInfo.platform) {
                    this.currentExpressInfo.platform = {
                        id: null
                    }
                }
                if (!this.currentExpressInfo.flag) {
                    this.currentExpressInfo.flag = null
                }

                this.showEditModal = true
            },
            submitEdit() {
                Toast.loading('提交中')
                let self = this
                let params = {}
                params.id = this.currentExpressInfo.id
                params.com = this.currentExpressInfo.com
                params.brandId = this.currentExpressInfo.brand.id
                params.platformId = this.currentExpressInfo.platform.id
                params.phone = this.currentExpressInfo.phone
                params.orderNumber = this.currentExpressInfo.orderNumber
                params.flag = this.currentExpressInfo.flag
                params.remarks = this.currentExpressInfo.remarks
                // let params = this.currentExpressInfo
                // return
                if (!params.com) {
                    delete params.com
                }
                if (!params.phone) {
                    delete params.phone
                }
                if (!params.brandId) {
                    delete params.brandId
                }
                if (!params.platformId) {
                    delete params.platformId
                }
                if (!params.orderNumber) {
                    delete params.orderNumber
                }
                if (!params.remarks) {
                    params.remarks = null
                }
                if (!params.orderNumber) {
                    delete params.orderNumber
                }
                apiEditExpress(params).then(res => {
                    Toast.clear()
                    Toast.success('修改成功');
                    self.$parent.getList()
                }).catch(err => {
                    console.warn(err)
                    Toast.clear()
                })
            },
            // 编辑备注
            mark(row) {
                this.currentExpressInfo = JSON.parse(JSON.stringify(row))
                if (!this.currentExpressInfo.brand) {
                    this.currentExpressInfo.brand = {
                        id: null
                    }
                }
                if (!this.currentExpressInfo.platform) {
                    this.currentExpressInfo.platform = {
                        id: null
                    }
                }
                if (!this.currentExpressInfo.flag) {
                    this.currentExpressInfo.flag = null
                }
                this.showRemarkModal = true
            },
            // 备注
            quickMark(word) {
                if (this.currentExpressInfo.remarks) {
                    this.currentExpressInfo.remarks = this.currentExpressInfo.remarks + '，' + word;
                } else {
                    this.currentExpressInfo.remarks = word;
                }
            },
            // 复制
            initClipboard() {
                const clipboard = new Clipboard(".copy");
                clipboard.on("success", function (e) {
                    Toast.success('已复制到剪贴板');
                    e.clearSelection();
                });
                clipboard.on("error", function (e) {
                    Toast.fail('复制失败');
                });
            },
            // 状态修改
            stop(row) {
                Toast.loading('处理中')
                let self = this
                let params = JSON.parse(JSON.stringify(row))
                let currentRemark = row.remarks
                if (currentRemark) {
                    params.remarks = currentRemark + '，手动中止跟踪'
                } else {
                    params.remarks = '，手动中止跟踪'
                }
                apiStopExpress(params).then(res => {
                    Toast.clear()
                    Toast.success('已中止跟踪');
                    self.$parent.getList()
                }).catch(err => {
                    console.warn(err)
                })
            },
            stopReturn(row) {
                Toast.loading('提交中')
                let self = this
                let params = {}
                params.id = row.id
                params.returnStatus = 705
                let currentRemark = row.remarks
                if (currentRemark) {
                    params.remarks = currentRemark + '，手动标记为退件接单'
                } else {
                    params.remarks = '手动标记为退件接单'
                }
                apiEditExpress(params).then(res => {
                    Toast.clear()
                    Toast.success('已标记为退件接单');
                    self.$parent.getList()
                }).catch(err => {
                    console.warn(err)
                    Toast.clear()
                })
            },
            cancelReturn(row) {
                Toast.loading('提交中')
                let self = this
                let params = {}
                params.id = row.id
                params.returnStatus = null
                params.flag = null
                let currentRemark = row.remarks
                if (currentRemark) {
                    params.remarks = currentRemark + '，已取消退件'
                } else {
                    params.remarks = '已取消退件'
                }
                apiEditExpress(params).then(res => {
                    Toast.clear()
                    Toast.success('已取消退件');
                    self.$parent.getList()
                }).catch(err => {
                    console.warn(err)
                    Toast.clear()
                })
            },
            addReturn(row) {
                Toast.loading('提交中')
                let self = this
                let params = {}
                params.id = row.id
                params.returnStatus = 701
                params.flag = 'orang'
                let currentRemark = row.remarks
                if (currentRemark) {
                    params.remarks = currentRemark + '，登记需退回'
                } else {
                    params.remarks = '登记需退回'
                }
                apiEditExpress(params).then(res => {
                    Toast.clear()
                    Toast.success('已登记为需退回');
                    self.$parent.getList()
                }).catch(err => {
                    console.warn(err)
                    Toast.clear()
                })
            },
            // 刷新
            async reflash(row) {
                try {
                    Toast.loading('刷新中')
                    let params = JSON.parse(JSON.stringify(row))
                    await apiReflashExpress(params)
                    Toast.clear()
                    Toast.success('刷新成功')
                    this.$parent.getList()
                } catch (err) {
                    console.warn(err)
                }
            },
            // 查询发货时间和照片
            openPhoto(row) {
                this.showPhotoModal = true
                this.currentExpressInfo = JSON.parse(JSON.stringify(row))
                this.photoTimes = null
                this.photoTimeSearchText = null
                this.photos = []
                this.photoLoadPage = 1
                this.getPhotoTime(row)
            },
            getPhotoTime(row) {
                let self = this
                let number = row.number
                let params = {
                    number: number
                }
                apiGetPackList(params).then(res => {
                    let data = res.data.items
                    let times = []
                    for (let i = 0; i < data.length; i++) {
                        let time = data[i].createTime
                        time = self.formatTimePhoto(time)
                        times.push(time)
                    }
                    // 去重
                    times = Array.from(new Set(times))
                    self.photoTimes = times
                    if (times.length > 0) {
                        self.photoTimeSearchText = times[0]
                        self.searchPhoto()
                    }
                }).catch(err => {
                    Toast.clear()
                    // self.submitFailer()
                })

            },
            // 
            clickPhotoTime(time) {
                this.photoTimeSearchText = time
                this.photos = []
                this.photoLoadPage = 1
                this.searchPhoto()
            },
            searchPhotoBtn() {
                this.photos = []
                this.photoLoadPage = 1
                this.searchPhoto()
            },
            searchPhoto(time) {
                let self = this
                let params
                if (time) {
                    params = {
                        filename: time
                    }
                } else {
                    params = {
                        filename: this.photoTimeSearchText
                    }
                }
                Toast.loading({
                    message: '加载中',
                    forbidClick: true,
                    duration: 0
                })
                apiGetExpressPhoto(params).then(res => {
                    let data = res.data
                    let photos = data
                    // 没有照片
                    if (photos.length <= 0) {
                        Toast.fail('此时间没有照片')
                        return
                    }
                    let newPhotos = []
                    //  加上网址 https://mrp-express-photo.moemee.com/
                    for (let i = 0; i < photos.length; i++) {
                        let item = {
                            src: 'https://mrp-express-photo.moemee.com/' + photos[i] + '?x-oss-process=style/sq',
                            dataSource: 'https://mrp-express-photo.moemee.com/' + photos[i] + '?x-oss-process=style/hd',
                            filename: photos[i].split('/').pop()
                        }
                        newPhotos.push(item)
                    }
                    self.photos.push(...newPhotos)
                    if (self.photoLoadPage == 1) {
                        self.loadMorePhoto()
                    }
                    Toast.clear()
                }).catch(err => {
                    console.warn(err);
                    // self.submitFailer()
                    Toast.clear()
                })
            },
            loadMorePhoto() {
                let newTime = this.timeMore(this.photoLoadPage)
                this.photoLoadPage++
                this.searchPhoto(newTime)
            },
            timeMore(value) {
                // 点击加载更多，吧搜索的photoTimeSearchText时间减1分钟
                // 例如：20240519_1421 变为20240519_1420
                if (!this.photoTimeSearchText) {
                    return
                }
                let timeArr = this.photoTimeSearchText.split('_')
                let data = timeArr[0] // 这个是年月日
                let time = timeArr[1] // 这个是时+分
                let hour = time.slice(0, 2)
                let minute = time.slice(2, 4)
                // 减 value 分钟
                minute = parseInt(minute) - value
                if (minute < 0) {
                    minute = 59
                    hour = parseInt(hour) - 1
                    if (hour < 0) {
                        hour = 23
                        let date = data.slice(6, 8)
                        date = parseInt(date) - 1
                        if (date < 10) {
                            date = '0' + date
                        }
                        data = data.slice(0, 6) + date
                    }
                }
                if (minute < 10) {
                    minute = '0' + minute
                }
                if (hour < 10) {
                    hour = '0' + hour
                }
                let newTime = data + '_' + hour + minute
                return newTime
            },
            downloadPhoto() {
                const viewer = this.$refs.viewer.viewer;
                if (viewer) {
                    const img = viewer.image;
                    if (img) {
                        // 创建并触发下载
                        const a = document.createElement('a');
                        a.href = img.src;
                        a.download = img.alt || 'download';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }
                }
            },
            formatTimePhoto(value) {
                let time = new Date(value)
                let year = time.getFullYear()
                let month = time.getMonth() + 1
                let date = time.getDate()
                let hours = time.getHours()
                let minute = time.getMinutes()
                let second = time.getSeconds()
                if (month < 10) { month = '0' + month }
                if (date < 10) { date = '0' + date }
                if (minute < 10) { minute = '0' + minute }
                if (second < 10) { second = '0' + second }
                return year + month + date + '_' + hours + minute
            },
            // END
        }
    }
</script>

<style scoped>

    /* 弹窗 手动录入快递 */
    .modal-add-select {
        width: 100%;
        height: 30px;
        border-radius: 15px;
        border: 1px solid #e6e6ef;
        outline: none;
        margin-bottom: 15px;
        padding: 0 10px;
        font-size: 14px;
        color: #333;
    }

    .modal-add-textarea {
        width: 100%;
        height: 80px;
        border-radius: 15px;
        border: 1px solid #e6e6ef;
        outline: none;
        padding: 10px 10px;
        font-size: 14px;
        color: #333;
        resize: none;
    }

    .modal-add-input {
        width: 100%;
        height: 30px;
        border-radius: 15px;
        border: 1px solid #e6e6ef;
        outline: none;
        margin-bottom: 15px;
        padding: 0 10px;
        font-size: 14px;
        color: #333;
    }

    .modal-add-checkbox {
        margin-bottom: 15px;
    }

    .modal-add-checkbox-title {
        margin-bottom: 10px;
        font-size: 14px;
        color: #666;
    }

    /*  */


    .modal-add-checkbox {
        margin-bottom: 15px;
    }

    .modal-add-checkbox-title {
        margin-bottom: 10px;
        font-size: 14px;
        color: #666;
    }

    .modal-quick-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
    }

    .modal-quickword {
        font-size: 13px;
        padding: 4px 10px;
        margin-bottom: 10px;
        margin-right: 10px;
        background-color: #e9e9eb;
        color: #666;
        border-radius: 6px;
        cursor: pointer;
    }

    /*  */

    .radio-block {
        width: auto;
        margin-right: 20px;
        margin-bottom: 10px;
    }



    .common-modalbox-remark {
        padding-bottom: 0;
    }

    /*  */
    .common-modalbox-photo {
        padding-bottom: 0;
    }

    .modal-photo-time-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
    }

    .modal-photo-time {
        font-size: 14px;
        padding: 4px 10px;
        margin-bottom: 10px;
        margin-right: 10px;
        background-color: #e9e9eb;
        color: #666;
        border-radius: 6px;
        cursor: pointer;
    }

    .modal-photo-time-tip {
        font-size: 14px;
        color: #666;
        margin-bottom: 10px;
    }

    .modal-photo-search {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
    }

    .modal-photo-input {
        flex: 1;
        border: 1px solid #e9e9eb;
        outline: none;
        height: 32px;
        border-radius: 16px 0 0 16px;
        padding: 0 15px;
        font-size: 14px;
    }

    .modal-photo-search-btn {
        width: 80px;
        border: none;
        outline: none;
        height: 32px;
        background-color: #e9e9eb;
        color: #333;
        border-radius: 0 16px 16px 0;
        cursor: pointer;
    }

    .modal-photo-box {
        display: flex;
        flex-wrap: wrap;
        max-height: 350px;
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 5px;
    }

    .modal-photo-one {
        display: block;
        width: 19%;
        height: auto;
        margin-right: 1%;
        margin-bottom: 1%;
        cursor: pointer;
        border-radius: 2%;
    }

    @media screen and (max-width:768px) {
        .modal-photo-box {
            max-height: 280px;
        }

        .modal-photo-one {
            width: 49%;
            margin-right: 1%;
        }
    }

    .modal-photo-loadmore {
        width: 100%;
        text-align: center;
        height: 30px;
        line-height: 30px;
        color: #fff;
        cursor: pointer;
        margin-top: 10px;
        border-radius: 15px;
        background-color: #b8a3ed;
    }

    .modal-photo-loadmore:hover {
        background-color: #a18de0;
    }

    .modal-photo-loadmore-end {
        width: 100%;
        text-align: center;
        height: 30px;
        line-height: 30px;
        color: #666;
        cursor: pointer;
        margin-top: 10px;
        border-radius: 15px;
    }

</style>
<style>
    .viewer-download {
        color: #fff;
        font-size: 0.45rem;
        line-height: 1.5rem;
        text-align: center;
    }

    .viewer-download::before {
        content: "下载";

    }
</style>