<template>
    <div class="content-exptable-mobile">
        <!-- height="300" :loading="loading" -->
        <div class="table-mobile-box">
            <div class="table-mobile-single" v-for="item in tableData">
                <div class="table-number" :data-clipboard-text="item.number">
                    <div class="table-number-logo">
                        <img :src="'/oss-assets/com/icon-com-' + item.com + '.jpg'" alt="">
                    </div>
                    <div class="table-number-text">
                        {{ item.number }}
                    </div>
                </div>
                <div class="table-mobile-status">
                    <div class="table-status-return" v-if="item.returnStatus">
                        <div class="table-status-mini">
                            <div class="table-status-mini-img">
                                <img :src="'/oss-assets/status/icon-status-' + item.status + '.png'" alt="">
                            </div>
                            <div class="table-status-mini-text">
                                {{ item.statusName }}
                            </div>
                        </div>
                        <div :class="'table-status-return-min back-' + item.returnStatus">
                            <span v-if="item.returnStatus == item2.key" v-for="item2 in returnStatusCode">
                                {{ item2.value }}
                            </span>
                        </div>
                    </div>
                    <div class="table-status" v-else>
                        <div class="table-status-img">
                            <img :src="'/oss-assets/status/icon-status-' + item.status + '.png'" alt="">
                        </div>
                        <div class="table-status-text">
                            {{ item.statusName }}
                        </div>
                    </div>
                </div>
                <div class="table-mobile-shop">
                    <span v-if="item.platform">{{ item.platform.name }}</span>
                    <span v-if="item.brand && item.platform"> / </span>
                    <span v-if="item.brand">{{ item.brand.name }}</span>
                </div>
                <div class="table-mobile-order copy" :data-clipboard-text="item.orderNumber">
                    <span>订单号：{{ item.orderNumber }}</span>
                    <img src="/oss-assets/com/icon-com-copy.png" alt="">
                </div>
                <div class="table-mobile-time">
                    <div :class="'table-time-line ' + checkColor(item)" v-if="item.kdLastTime"
                        v-show="item.status == 802 || item.status == 803 || item.status == 804 || item.status == 805 || item.status == 806 || item.status == 808 || item.status == 810">
                        <div class="table-time-icon">
                            <img src="/oss-assets/time/icon-time-last.jpg" alt="">
                        </div>
                        <div class="table-time-icon red">
                            <img src="/oss-assets/time/icon-time-last-red.jpg" alt="">
                        </div>
                        <div class="table-time-text">
                            {{ item.kdLastTime | formatKdLastTime }}
                        </div>
                    </div>
                    <div class="table-time-line" v-if="item.totalTime"
                        v-show="item.status == 807 || item.status == 809">
                        <div class="table-time-icon">
                            <img src="/oss-assets/time/icon-time-all.jpg" alt="">
                        </div>
                        <div class="table-time-text" style="color: #83888D">
                            {{ item.totalTime | formatTotalTime }}
                        </div>
                    </div>
                    <div class="table-time-line" v-if="item.kdLastPushTime">
                        <div class="table-time-icon">
                            <img src="/oss-assets/time/icon-time-update.jpg" alt="">
                        </div>
                        <div class="table-time-text" style="color: #B6BFC8">
                            {{ item.kdLastPushTime | formatDate }}
                        </div>
                    </div>
                    <div class="table-time-line" v-if="!item.kdLastPushTime">
                        <div class="table-time-icon">
                            <img src="/oss-assets/time/icon-time-last.jpg" alt="">
                        </div>
                        <div class="table-time-text" style="color: #3F4347">
                            等待物流更新...
                        </div>
                    </div>
                </div>
                <!-- 备注 -->
                <div class="table-mobile-remark" @click="mark(item)">
                    <div class="table-flag" :content="item.remarks">
                        <span class="flag" v-for="item2 in flags" v-if="item2.key == item.flag">
                            {{ item2.icon }}
                        </span>
                        <span :class="'remark ' + item.flag">{{ item.remarks || '无备注' }}</span>
                    </div>
                </div>
                <!-- 短 -->
                <div class="table-mobile-exp-short" v-if="!showExpDetailIn" @click="showExpDetailIn = true">
                    <div class="table-info" v-if="item.kdData && item.kdData.length > 0">
                        <div class="table-info-time">
                            {{ item.kdData[0].time }}
                            <!-- &nbsp;<font-awesome-icon class="table-info-icon" icon="fa-solid fa-eye" /> -->
                        </div>
                        <div class="table-info-text">{{ item.kdData[0].context }}</div>
                    </div>
                </div>
                <!-- 长 -->
                <div class="table-mobile-exp-long" v-if="showExpDetailIn" @click="showExpDetailIn = false">
                    <div class="openline-exp" v-if="item.kdData && item.kdData.length > 0">
                        <div class="openline-exp-detail" v-for="(item2, index) in item.kdData">
                            <div class="openline-exp-detail-time">{{ item2.time }}</div>
                            <div class="openline-exp-detail-text">{{ item2.context }}</div>
                        </div>
                    </div>
                </div>

                <div class="openline-btnbox">
                    <vxe-button class="openline-btn-circle" @click="openPhoto(item)" circle>
                        <font-awesome-icon icon="fa-solid fa-image" />
                    </vxe-button>
                    <vxe-button class="openline-btn-circle" @click="mark(item)" circle>
                        <font-awesome-icon icon="fa-solid fa-note-sticky" />
                    </vxe-button>
                    <vxe-button class="openline-btn-circle" @click="reflash(item)" circle>
                        <font-awesome-icon icon="fa-solid fa-arrow-rotate-right" />
                    </vxe-button>
                    <!-- <vxe-button class="openline-btn" @click="addReturn(item)" round
                        v-if="!item.returnStatus || item.returnStatus == 702">
                        <font-awesome-icon icon="fa-solid fa-xmark" /><span>登记需退回</span>
                    </vxe-button>
                    <vxe-button class="openline-btn" @click="cancelReturn(item)" v-if="item.returnStatus" round>
                        <font-awesome-icon icon="fa-solid fa-reply" /><span>取消退件</span>
                    </vxe-button> -->

                    <vxe-button class="openline-btn-circle" @click="openMore(item)" circle>
                        <font-awesome-icon icon="fa-solid fa-ellipsis" />
                    </vxe-button>
                    <van-action-sheet v-model:show="showAction" :actions="actions" @select="onSelect" />
                </div>
            </div>
        </div>
        <div class="no-result" v-if="tableData && tableData.length < 1">
            - 没有结果 -
        </div>
    </div>
</template>

<script>
import Clipboard from 'clipboard'
import { Toast, Dialog } from 'vant';
import { apiEditExpress, apiReflashExpress, apiStopExpress, apiGetPackList } from '@/request/api'
import axios from 'axios'

export default {
    components: {

    },
    name: 'expressTableMobile',
    props: {
        tableData: Array,
        showExpDetail: Boolean,
        flags: Array,
        returnStatusCode: Array,
    },
    data() {
        return {
            loading: false,
            showAction: false,
            selectItem: null,
            showExpDetailIn: this.showExpDetail,
            actions: [
                { name: '编辑' },
                { name: '中止物流跟踪' },
                { name: '登记为需退回' },
                { name: '取消退件' },
                { name: '标记为退件结单' },
            ]
        }
    },
    filters: {
        formatTotalTime(value) {
            let duration = value * 1000
            let days = '',
                hours = '',
                minutes = '',
                seconds = ''
            let day = 24 * 60 * 60 * 1000,
                hour = 60 * 60 * 1000,
                minute = 60 * 1000,
                second = 1000
            if (duration >= day) {
                days = Math.floor(duration / day) + '天'
                hours = Math.floor(duration % day / hour) + '小时'
            } else if (duration >= hour && duration < day) {
                hours = Math.floor(duration / hour) + '小时'
                minutes = Math.floor(duration % hour / minute) + '分钟'
            } else if (duration > minute && duration < hour) {
                minutes = Math.floor(duration / minute) + '分钟'
                seconds = Math.floor(duration % minute / second) + '秒'
            } else if (duration <= minute) {
                seconds = Math.floor(duration / second) + '秒'
            }
            return days + hours + minutes + seconds
        },
        formatKdLastTime(value) {
            let duration = new Date() - value
            let days = '',
                hours = '',
                minutes = '',
                seconds = ''
            let day = 24 * 60 * 60 * 1000,
                hour = 60 * 60 * 1000,
                minute = 60 * 1000,
                second = 1000
            if (duration >= day) {
                days = Math.floor(duration / day) + '天'
                hours = Math.floor(duration % day / hour) + '小时'
            } else if (duration >= hour && duration < day) {
                hours = Math.floor(duration / hour) + '小时'
                minutes = Math.floor(duration % hour / minute) + '分钟'
            } else if (duration > minute && duration < hour) {
                minutes = Math.floor(duration / minute) + '分钟'
                seconds = Math.floor(duration % minute / second) + '秒'
            } else if (duration <= minute) {
                seconds = Math.floor(duration / second) + '秒'
            }
            return days + hours + minutes + seconds

        },
        formatDate(value) {
            let date = new Date(value);
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            month = month < 10 ? "0" + month : month;
            day = day < 10 ? "0" + day : day;
            let result = year + '-' + month + '-' + day;
            return result
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.initClipboard()
            // 判断路由
            console.log(this.$route)
            if (this.$route.path == '/express/search') {
                this.showExpDetailIn = true
            }
        },
        edit(item) {
            this.$parent.edit(item)
        },
        openPhoto(item) {
            this.$parent.openPhoto(item)
        },
        reflash(item) {
            this.$parent.reflash(item)
        },
        mark(item) {
            this.$parent.mark(item)
        },
        stop(item) {
            this.$parent.stop(item)
        },
        cancelReturn(item) {
            this.$parent.cancelReturn(item)
        },
        addReturn(item) {
            this.$parent.addReturn(item)
        },
        stopReturn(item) {
            this.$parent.stopReturn(item)
        },
        openMore(item) {
            this.selectItem = item
            this.showAction = true
        },
        onSelect(value) {
            console.log(value)
            this.showAction = false
            if (value.name == '编辑') {
                this.edit(this.selectItem)
            }
            if (value.name == '中止物流跟踪') {
                this.stop(this.selectItem)
            }
            if (value.name == '登记为需退回') {
                this.addReturn(this.selectItem)
            }
            if (value.name == '取消退件') {
                this.cancelReturn(this.selectItem)
            }
            if (value.name == '标记为退件结单') {
                this.stopReturn(this.selectItem)
            }
        },
        checkColor(item) {
            let result = ''
            let duration = new Date() - item.kdLastTime
            let hours = ''
            let hour = 60 * 60 * 1000
            hours = Math.floor(duration / hour)
            // 已揽收
            if (item.status == 802) {
                if (hours > 12) {
                    result = 'red'
                }
            }
            // 省内运输中
            if (item.status == 803) {
                if (hours > 24) {
                    result = 'red'
                }
            }
            // 运输中
            if (item.status == 804) {
                if (hours > 36) {
                    result = 'red'
                }
            }
            // 派件中
            if (item.status == 805) {
                if (hours > 36) {
                    result = 'red'
                }
            }
            // 到代收点
            if (item.status == 806) {
                if (hours > 48) {
                    result = 'red'
                }
            }
            // 退回中
            if (item.status == 808) {
                if (hours > 48) {
                    result = 'red'
                }
            }
            // 疑难件
            if (item.status == 810) {
                if (hours > 48) {
                    result = 'red'
                }
            }
            return result
        },
        initClipboard() {
            const clipboard = new Clipboard(".copy");
            clipboard.on("success", function (e) {
                Toast.success('已复制到剪贴板');
                e.clearSelection();
            });
            clipboard.on("error", function (e) {
                Toast.fail('复制失败');
            });
        },
    }
}
</script>

<style scoped>
.content-exptable-mobile {
    padding: 15px 0;
}

.container-title {
    width: 100%;
    margin-bottom: 16px;
}

.title-main {
    font-size: 24px;
    font-weight: bold;
    color: #489D80;
}

.title-intro {
    font-size: 14px;
    color: #666;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;
    white-space: nowrap;
    /* margin-top: 4px; */
}

/* 表格 */
.table-id {
    font-size: 12px;
    color: #bdbdbd;
}

.table-info {
    width: 100%;
    /* cursor: pointer; */
}

.table-info-time {
    color: #83888D;
    font-size: 12px;
    line-height: 1.6;
}

.table-info-text {
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 1.6;
    /* text-indent: -0.5em */
}

.table-info-icon {
    display: none;
}

.table-info:hover .table-info-icon {
    display: inline-block;
}


.table-number {
    width: 200px;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.table-number-logo {
    width: 24px;
    height: 24px;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 6px;
}

.table-number-logo img {
    width: 24px;
    height: 24px;
    display: block;
    opacity: 0.5;
}


.table-number-text {
    font-weight: bold;
    position: relative;
    width: 1px;
    flex: 1;
    font-size: 16px;
    line-height: 1.2;

}

.table-status {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.table-status-img {
    width: 16px;
    height: 16px;
    margin-bottom: 2px;
}

.table-status-img img {
    width: 16px;
    height: 16px;
    display: block;

}

.table-status-text {
    font-size: 12px;
    line-height: 1;
    word-break: keep-all;
    white-space: nowrap;
    margin-top: 4px;
}

.table-status-mini {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.table-status-mini-img {
    width: 14px;
    height: 14px;
    margin-right: 4px;
}

.table-status-mini-img img {
    width: 14px;
    height: 14px;
    display: block;
}

.table-status-mini-text {
    font-size: 12px;
    line-height: 1;
    word-break: keep-all;
    white-space: nowrap;
}

.table-status-return {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.table-status-return-min {
    width: 100%;
    /* padding: 0 6px; */
    height: 18px;
    line-height: 18px;
    border-radius: 9px;
    margin-top: 4px;
    font-size: 12px;
    padding: 0 6px;
}

.table-status-return-min span {
    color: #fff;

}

.table-status-return-min.back-701 {
    background-color: #fd4343;
}

.table-status-return-min.back-702 {
    background-color: #fdd843;
}

.table-status-return-min.back-704 {
    background-color: #4A8AEF;
}

.table-status-return-min.back-705 {
    background-color: #ccc;
}

.table-time-line {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 15px;
    margin-top: 4px;
}

.table-time-icon {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    overflow: hidden;
    margin-right: 4px;
}

.table-time-icon.red {
    display: none;
}

.table-time-icon img {
    width: 12px;
    height: 12px;
    display: block;
}

.table-time-text {
    font-size: 12px;
    line-height: 1.6;
    color: #3F4347;
}

.table-time-line.red .table-time-text {
    color: #fd4343;
}

.table-time-line.red .table-time-icon {
    display: none;

}

.table-time-line.red .table-time-icon.red {
    display: block;
}


.table-shop {
    font-size: 14px;
    line-height: 1.4;
}

.table-shop-platform {
    color: #83888D;
}

.table-shop-name {
    color: #B6BFC8;
}

.table-mobile-remark {
    background: #EFEEEE;
    border-radius: 10px;
    padding: 10px;
    margin-top: 8px;
    margin-bottom: 8px;
    cursor: pointer;
}

.table-flag {
    padding-right: 4px;
    font-size: 12px;
    line-height: 1.4;
    word-break: break-all;
    white-space: normal;
}

.table-flag .remark {
    color: #333;
}

.table-flag .remark.straw {
    color: #FF9FAA;
}

.table-flag .remark.orang {
    color: #FE7A0D;
}

.table-flag .remark.grape {
    color: #9E008E;
}


/* 手机 */
.table-mobile-box {
    width: 100%;
}

.table-mobile-single {
    background: #FBFBFB;
    border: 1px solid #EFEEEE;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, .1);
    border-radius: 20px;
    padding: 15px;
    margin-bottom: 15px;
    position: relative;
    overflow: hidden;
}


.table-mobile-status {
    background-color: #FBFBFB;
    /* border-left: 2px solid #eee;
    border-bottom: 2px solid #eee; */
    padding: 15px;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0 0 0 10px;
}

.table-mobile-shop {
    font-size: 13px;
    color: #292929;
    letter-spacing: 0;
    margin-top: 10px;
}

.table-mobile-order {
    font-size: 13px;
    color: #999999;
    letter-spacing: 0;
    margin-top: 4px;
}

.table-mobile-order img {
    width: 13px;
    height: 13px;
    display: inline-block;
    margin-left: 6px;
    position: relative;
    opacity: .5;
    top: 1px;

}

.table-mobile-time {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.no-result {
    width: 100%;
    height: 100px;
    line-height: 100px;
    text-align: center;
    font-size: 16px;
    color: #999999;
}


.radio-block {
    width: 100%;
    margin-bottom: 10px;
}

.radio-block:last-child {
    margin-bottom: 0;
}

/* 展开行 */
.openLineBox {
    width: 100%;
    padding: 10px 10px 10px 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #F8F8FA;
}

.openline {
    width: 32%;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    line-height: 1.6;
    background-color: #fff;
    border: 1px solid #efefef;
    margin-right: 1%;
    margin-bottom: 10px;
    padding: 4px 1%;
    border-radius: 8px;

}

.openline-key {
    /* width: 130px; */
    color: #999;
    margin-right: 10px;

}

.openline-value {
    flex: 1;
    color: #333;
}

.openline-btnbox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    margin-top: 15px;
}


.openline-btn:hover {
    opacity: 0.8;
}

.openline-btn span {
    margin-left: 6px;
}

.openline-exp {
    width: 100%;
}

.openline-exp-detail {
    font-size: 12px;
    line-height: 1.6;
    display: flex;
    flex-direction: row;
    padding: 6px 0;
    border-top: 1px solid #eee
}

.openline-exp-detail:first-child {
    border: none;
}

.openline-exp-detail-time {
    color: #83888D;
    font-size: 12px;
    line-height: 1.4;
    width: 75px;
    word-break: break-all;
    white-space: normal;
}

.openline-exp-detail-text {
    word-break: break-all;
    white-space: normal;
    font-size: 12px;
    line-height: 1.4;
    width: 1px;
    flex: 1;
}

.table-mobile-exp-short,
.table-mobile-exp-long {
    cursor: pointer;
}

.openline-number-copy {
    display: inline-block;
    width: 14px;
    height: 14px;
    position: relative;
    top: 2px;
    padding-left: 5px;
    opacity: 0.5;
}

.openline-number-copy img {
    display: inline-block;
    width: 14px;
    height: 14px;
}

.openline-copy:hover {
    background-color: #efefef;
}
</style>