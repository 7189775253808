<template>
    <div class="container">
        <div class="common-topbox">

            <!-- 标题区域 -->
            <Title title="上传图片" intro="">
                <template v-slot:right>
                    <!-- 右侧按钮区域插槽 -->
                </template>
            </Title>
        </div>
        <div>

        </div>
        <!-- MD5 -->
        <!-- 识别条码 -->
    </div>
</template>

<script>
// import { apiName } from '@/request/api'
import Title from '@/components/common/title'
import Quagga from 'quagga'

export default {
    components: {
        Title,
    },
    name: 'Index',
    // props: {
    //   msg: String
    // },
    data() {
        return {
            device: this.GLOBAL.device,
            loading: false,
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
        },
        upload(event) {
            console.log("event.target.files[0]")
            console.log(event.target.files[0])
            let url = URL.createObjectURL(event.target.files[0])
            console.log(URL.createObjectURL(event.target.files[0]))
            this.decode(url)
        },
        decode(src) {
            const config = {
                locator: {
                    patchSize: "x-small",
                    halfSample: true
                },
                inputStream: {
                    size: 3000  // restrict input-size to be 800px in width (long-side)
                },
                numOfWorkers: 4,
                decoder: {
                    readers: ["code_128_reader"], // List of active readers
                },
                locate: true,
                src: src
            }
            Quagga.decodeSingle(config, function (result) {
                if (result && result.codeResult) {
                    console.log("result")
                    console.log(result)
                    console.log(result.codeResult)
                } else {
                    console.log("识别错误")
                    console.log(result)
                }
            })
        },

        api() {
            let self = this
            apiName({
                page: 1,
                size: 10,
            }).then(res => {

            }).catch(err => {
                console.warn(err)
            })
        },
    }
}
</script>

<style scoped>
/* 上传按钮 */

.upload-text {
    display: inline-block;
    cursor: pointer;
}

.upload-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 2;
}
</style>