<template>
    <div class="content-exptable-pc">
        <!-- height="300" :loading="loading" -->
        <vxe-table round border highlight-hover-row resizable ref="xTable" :data="tableData" height="100%"
            show-overflow>
            <!-- <vxe-table-column type="seq" width="50" align="center"></vxe-table-column> -->
            <vxe-column type="expand" width="38">
                <template #content="{ row, rowIndex }">
                    <div class="openline-exp" v-if="row.kdData && row.kdData.length > 0">
                        <div class="openline-exp-detail" v-for="(item, index) in row.kdData">
                            <div class="openline-exp-detail-time">{{ item.time }}</div>
                            <div class="openline-exp-detail-text">{{ item.context }}</div>
                        </div>
                    </div>
                    <div class="openline-btnbox">
                        <div class="openline-btn" @click="edit(row)">
                            <font-awesome-icon icon="fa-solid fa-pen-to-square" /><span>编辑</span>
                        </div>
                        <!-- <div class="openline-btn" @click="reflash(row)">
                            <font-awesome-icon icon="fa-solid fa-arrow-rotate-right" /><span>更新物流</span>
                        </div> -->
                        <div class="openline-btn" @click="stop(row)" v-if="row.status && row.status != 807">
                            <font-awesome-icon icon="fa-solid fa-circle-stop" /><span>中止物流跟踪</span>
                        </div>
                        <div class="openline-btn" @click="addReturn(row)"
                            v-if="!row.returnStatus || row.returnStatus == 702">
                            <font-awesome-icon icon="fa-solid fa-xmark" /><span>登记为需退回</span>
                        </div>
                        <div class="openline-btn" @click="cancelReturn(row)" v-if="row.returnStatus">
                            <font-awesome-icon icon="fa-solid fa-reply" /><span>取消退件</span>
                        </div>
                        <div class="openline-btn" @click="stopReturn(row)"
                            v-if="row.returnStatus && row.returnStatus != 705">
                            <font-awesome-icon icon="fa-solid fa-circle-stop" /><span>标记为退件结单</span>
                        </div>
                    </div>
                    <div class="openLineBox">
                        <div class="openline openline-copy copy" :data-clipboard-text="row.orderNumber"
                            style="cursor: pointer;">
                            <div class="openline-key">订单号</div>
                            <div class="openline-value">
                                <span>{{ row.orderNumber || '无' }}</span>
                                <span class="openline-number-copy" v-if="row.orderNumber">
                                    <img src="/oss-assets/com/icon-com-copy.png" alt="">
                                </span>
                            </div>
                        </div>
                        <div class="openline">
                            <div class="openline-key">录入人</div>
                            <div class="openline-value">
                                {{ row.creator.nickname }}
                            </div>
                        </div>
                        <div class="openline">
                            <div class="openline-key">修改人</div>
                            <div class="openline-value">
                                {{ row.modifier.nickname }}
                            </div>
                        </div>
                        <div class="openline" v-if="row.kdLastTime">
                            <div class="openline-key">最新物流等待时间</div>
                            <div class="openline-value">
                                {{ row.kdLastTime | formatKdLastTime }}
                            </div>
                        </div>
                        <div class="openline" v-if="row.totalTime">
                            <div class="openline-key">总耗时</div>
                            <div class="openline-value">
                                {{ row.totalTime | formatTotalTime }}
                            </div>
                        </div>
                        <div class="openline" v-if="row.kdLastPushTime">
                            <div class="openline-key">物流刷新时间</div>
                            <div class="openline-value">
                                {{ row.kdLastPushTime | formatDate }}
                            </div>
                        </div>
                        <div class="openline" v-if="row.inStockTime">
                            <div class="openline-key">退件入库时间</div>
                            <div class="openline-value">
                                {{ row.inStockTime | formatDate }}
                            </div>
                        </div>
                    </div>
                </template>
            </vxe-column>
            <!-- <vxe-table-column field="id" title="ID" width="60"> -->
            <vxe-table-column field="id" title="ID" width="60">
                <template #default="{ row }">
                    <div class="table-id">
                        {{ row.id }}
                    </div>
                </template>
            </vxe-table-column>
            <vxe-table-column title="店铺" width="80">
                <template #default="{ row }">
                    <div class="table-shop">
                        <div class="table-shop-platform" v-if="row.platform">{{ row.platform.name }}</div>
                        <div class="table-shop-name" v-if="row.brand">{{ row.brand.name }}</div>
                    </div>
                </template>
            </vxe-table-column>
            <vxe-table-column title="快递单号" width="185">
                <template #default="{ row }">
                    <div class="table-number copy" :data-clipboard-text="row.number">
                        <div class="table-number-logo">
                            <img :src="'/oss-assets/com/icon-com-' + row.com + '.jpg'" alt="">
                        </div>
                        <div class="table-number-copy">
                            <img src="/oss-assets/com/icon-com-copy.png" alt="">
                        </div>
                        <div class="table-number-text">
                            {{ row.number }}
                        </div>
                    </div>
                </template>
            </vxe-table-column>
            <vxe-table-column title="状态" width="90" align="center">
                <template #default="{ row }">
                    <div class="table-status-return" v-if="row.returnStatus">
                        <div class="table-status-mini">
                            <div class="table-status-mini-img">
                                <img :src="'/oss-assets/status/icon-status-' + row.status + '.png'" alt="">
                            </div>
                            <div class="table-status-mini-text">
                                {{ row.statusName }}
                            </div>
                        </div>
                        <div :class="'table-status-return-min back-' + row.returnStatus">
                            <span v-if="row.returnStatus == item.key" v-for="item in returnStatusCode">
                                {{ item.value }}
                            </span>
                        </div>
                    </div>
                    <div class="table-status" v-else>
                        <div class="table-status-img">
                            <img :src="'/oss-assets/status/icon-status-' + row.status + '.png'" alt="">
                        </div>
                        <div class="table-status-text">
                            {{ row.statusName }}
                        </div>
                    </div>
                </template>
            </vxe-table-column>
            <vxe-table-column title="时间" width="120">
                <template #default="{ row }">
                    <div class="table-time">
                        <div :class="'table-time-line ' + checkColor(row)" v-if="row.kdLastTime"
                            v-show="row.status == 802 || row.status == 803 || row.status == 804 || row.status == 805 || row.status == 806 || row.status == 808 || row.status == 810">
                            <div class="table-time-icon">
                                <img src="/oss-assets/time/icon-time-last.jpg" alt="">
                            </div>
                            <div class="table-time-icon red">
                                <img src="/oss-assets/time/icon-time-last-red.jpg" alt="">
                            </div>
                            <div class="table-time-text">
                                {{ row.kdLastTime | formatKdLastTime }}
                            </div>
                        </div>
                        <div class="table-time-line" v-if="row.totalTime"
                            v-show="row.status == 807 || row.status == 809">
                            <div class="table-time-icon">
                                <img src="/oss-assets/time/icon-time-all.jpg" alt="">
                            </div>
                            <div class="table-time-text" style="color: #83888D">
                                {{ row.totalTime | formatTotalTime }}
                            </div>
                        </div>
                        <div class="table-time-line" v-if="row.kdLastPushTime">
                            <div class="table-time-icon">
                                <img src="/oss-assets/time/icon-time-update.jpg" alt="">
                            </div>
                            <div class="table-time-text" style="color: #B6BFC8">
                                {{ row.kdLastPushTime | formatDate }}
                            </div>
                        </div>
                        <div class="table-time-line" v-if="!row.kdLastPushTime">
                            <div class="table-time-icon">
                                <img src="/oss-assets/time/icon-time-last.jpg" alt="">
                            </div>
                            <div class="table-time-text" style="color: #3F4347">
                                等待物流更新...
                            </div>
                        </div>
                    </div>

                </template>
            </vxe-table-column>
            <vxe-table-column title="物流信息">
                <template #default="{ row }">
                    <div class="table-info" v-if="row.kdData && row.kdData.length > 0">
                        <div class="table-info-time">
                            {{ row.kdData[0].time }}
                            <!-- &nbsp;<font-awesome-icon class="table-info-icon" icon="fa-solid fa-eye" /> -->
                        </div>
                        <div class="table-info-text">{{ row.kdData[0].context }}</div>
                    </div>
                </template>
            </vxe-table-column>
            <vxe-table-column title="备注" width="300">
                <template #default="{ row }">
                    <div class="table-flag" v-tippy :content="row.remarks" @click="mark(row)">
                        <span class="flag" v-for="item in flags" v-if="item.key == row.flag">
                            {{ item.icon }}
                        </span>
                        <span :class="'remark ' + row.flag" v-if="row.remarks">{{ row.remarks }}</span>
                    </div>
                </template>
            </vxe-table-column>
            <!-- <vxe-table-column title="操作" width="148"> -->
            <vxe-table-column title="操作" width="150" align="center">
                <template #default="{ row }">
                    <!-- <vxe-button @click="openDetail(row)" circle>
                                                                                                        <font-awesome-icon icon="fa-solid fa-book-open" />
                                                                                                    </vxe-button> -->
                    <!-- <vxe-button @click="edit(row)" circle>
                                                                                                        <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                                                                                                    </vxe-button> -->

                    <vxe-button @click="openPhoto(row)" circle>
                        <font-awesome-icon icon="fa-solid fa-image" />
                    </vxe-button>
                    <vxe-button @click="mark(row)" circle>
                        <font-awesome-icon icon="fa-solid fa-note-sticky" />
                    </vxe-button>
                    <vxe-button @click="reflash(row)" circle>
                        <font-awesome-icon icon="fa-solid fa-arrow-rotate-right" />
                    </vxe-button>
                </template>
            </vxe-table-column>
        </vxe-table>
        <!-- 弹窗 -->
        <!-- 弹窗 -->
    </div>
</template>

<script>
import Clipboard from 'clipboard'
import { Toast, Dialog } from 'vant';
import { apiReflashExpress, apiGetPackList } from '@/request/api'
import axios from 'axios'

export default {
    components: {

    },
    name: 'Index',
    props: {
        tableData: Array,
        flags: Array,
        returnStatusCode: Array,
    },
    data() {
        return {
            loading: false,
        }
    },
    filters: {
        formatTotalTime(value) {
            let duration = value * 1000
            let days = '',
                hours = '',
                minutes = '',
                seconds = ''
            let day = 24 * 60 * 60 * 1000,
                hour = 60 * 60 * 1000,
                minute = 60 * 1000,
                second = 1000
            if (duration >= day) {
                days = Math.floor(duration / day) + '天'
                hours = Math.floor(duration % day / hour) + '小时'
            } else if (duration >= hour && duration < day) {
                hours = Math.floor(duration / hour) + '小时'
                minutes = Math.floor(duration % hour / minute) + '分钟'
            } else if (duration > minute && duration < hour) {
                minutes = Math.floor(duration / minute) + '分钟'
                seconds = Math.floor(duration % minute / second) + '秒'
            } else if (duration <= minute) {
                seconds = Math.floor(duration / second) + '秒'
            }
            return days + hours + minutes + seconds
        },
        formatKdLastTime(value) {
            let duration = new Date() - value
            let days = '',
                hours = '',
                minutes = '',
                seconds = ''
            let day = 24 * 60 * 60 * 1000,
                hour = 60 * 60 * 1000,
                minute = 60 * 1000,
                second = 1000
            if (duration >= day) {
                days = Math.floor(duration / day) + '天'
                hours = Math.floor(duration % day / hour) + '小时'
            } else if (duration >= hour && duration < day) {
                hours = Math.floor(duration / hour) + '小时'
                minutes = Math.floor(duration % hour / minute) + '分钟'
            } else if (duration > minute && duration < hour) {
                minutes = Math.floor(duration / minute) + '分钟'
                seconds = Math.floor(duration % minute / second) + '秒'
            } else if (duration <= minute) {
                seconds = Math.floor(duration / second) + '秒'
            }
            return days + hours + minutes + seconds

        },
        formatDate(value) {
            let date = new Date(value);
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let day = date.getDate();
            month = month < 10 ? "0" + month : month;
            day = day < 10 ? "0" + day : day;
            let result = year + '-' + month + '-' + day;
            return result
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.initClipboard()
        },
        edit(item) {
            this.$parent.edit(item)
        },
        openPhoto(item) {
            this.$parent.openPhoto(item)
        },
        reflash(item) {
            this.$parent.reflash(item)
        },
        mark(item) {
            this.$parent.mark(item)
        },
        stop(item) {
            this.$parent.stop(item)
        },
        cancelReturn(item) {
            this.$parent.cancelReturn(item)
        },
        addReturn(item) {
            this.$parent.addReturn(item)
        },
        stopReturn(item) {
            this.$parent.stopReturn(item)
        },
        checkColor(row) {
            let result = ''
            let duration = new Date() - row.kdLastTime
            let hours = ''
            let hour = 60 * 60 * 1000
            hours = Math.floor(duration / hour)
            // 已揽收
            if (row.status == 802) {
                if (hours > 12) {
                    result = 'red'
                }
            }
            // 省内运输中
            if (row.status == 803) {
                if (hours > 24) {
                    result = 'red'
                }
            }
            // 运输中
            if (row.status == 804) {
                if (hours > 36) {
                    result = 'red'
                }
            }
            // 派件中
            if (row.status == 805) {
                if (hours > 36) {
                    result = 'red'
                }
            }
            // 到代收点
            if (row.status == 806) {
                if (hours > 48) {
                    result = 'red'
                }
            }
            // 退回中
            if (row.status == 808) {
                if (hours > 48) {
                    result = 'red'
                }
            }
            // 疑难件
            if (row.status == 810) {
                if (hours > 48) {
                    result = 'red'
                }
            }
            return result
        },
        initClipboard() {
            const clipboard = new Clipboard(".copy");
            clipboard.on("success", function (e) {
                Toast.success('已复制到剪贴板');
                e.clearSelection();
            });
            clipboard.on("error", function (e) {
                Toast.fail('复制失败');
            });
        },
    }
}
</script>

<style scoped>
.content-exptable-pc {
    width: 100%;
    height: 100%;
}

.container-title {
    width: 100%;
    margin-bottom: 16px;
}

.title-main {
    font-size: 24px;
    font-weight: bold;
    color: #489D80;
}

.title-intro {
    font-size: 14px;
    color: #666;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: keep-all;
    white-space: nowrap;
    /* margin-top: 4px; */
}

/* 表格 */
.table-id {
    font-size: 12px;
    color: #bdbdbd;
}

.table-info {
    width: 100%;
    /* cursor: pointer; */
}

.table-info-time {
    color: #83888D;
    font-size: 12px;
    line-height: 1.6;
}

.table-info-text {
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 1.6;
    /* text-indent: -0.5em */
}

.table-info-icon {
    display: none;
}

.table-info:hover .table-info-icon {
    display: inline-block;
}


.table-number {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding: 10px 0;
}

.table-number-logo {
    width: 18px;
    height: 18px;
    border-radius: 6px;
    overflow: hidden;
    margin-right: 4px;
    opacity: 0.5;
}

.table-number-logo img {
    width: 18px;
    height: 18px;
    display: block;
}


.table-number-copy {
    width: 18px;
    height: 18px;
    overflow: hidden;
    margin-right: 4px;
    opacity: 0.5;
}

.table-number-copy img {
    width: 18px;
    height: 18px;
    display: block;
}

.table-number .table-number-logo {
    display: block;
}

.table-number .table-number-copy {
    display: none;
}

.table-number:hover .table-number-logo {
    display: none;
}

.table-number:hover .table-number-copy {
    display: block;
}


.table-number-text {
    font-weight: bold;
    position: relative;
    width: 1px;
    flex: 1;
    font-size: 14px;
    line-height: 1.2;

}

.table-status {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.table-status-img {
    width: 16px;
    height: 16px;
    margin-bottom: 2px;
}

.table-status-img img {
    width: 16px;
    height: 16px;
    display: block;

}

.table-status-text {
    font-size: 12px;
    line-height: 1;
    word-break: keep-all;
    white-space: nowrap;
    margin-top: 4px;
}

.table-status-mini {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.table-status-mini-img {
    width: 14px;
    height: 14px;
    margin-right: 4px;
}

.table-status-mini-img img {
    width: 14px;
    height: 14px;
    display: block;
}

.table-status-mini-text {
    font-size: 12px;
    line-height: 1;
    word-break: keep-all;
    white-space: nowrap;
}

.table-status-return {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.table-status-return-min {
    width: 100%;
    /* padding: 0 6px; */
    height: 18px;
    line-height: 18px;
    border-radius: 9px;
    margin-top: 4px;
    font-size: 12px;
}

.table-status-return-min span {
    color: #fff;

}

.table-status-return-min.back-701 {
    background-color: #fd4343;
}

.table-status-return-min.back-702 {
    background-color: #fdd843;
}

.table-status-return-min.back-704 {
    background-color: #4A8AEF;
}

.table-status-return-min.back-705 {
    background-color: #ccc;
}

.table-time-line {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.table-time-icon {
    width: 12px;
    height: 12px;
    border-radius: 2px;
    overflow: hidden;
    margin-right: 4px;
}

.table-time-icon.red {
    display: none;
}

.table-time-icon img {
    width: 12px;
    height: 12px;
    display: block;
}

.table-time-text {
    font-size: 12px;
    line-height: 1.6;
    color: #3F4347;
}

.table-time-line.red .table-time-text {
    color: #fd4343;
}

.table-time-line.red .table-time-icon {
    display: none;

}

.table-time-line.red .table-time-icon.red {
    display: block;
}

/* 物流信息弹窗 */
.common-modalbox-exp {
    padding-bottom: 0;
    padding-top: 0;
}

.expmodal-number {
    font-size: 14px;
    padding: 0 20px;
    /* min-width: 280px; */
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    text-align: center;
    margin: 0 auto 10px;
    background-color: #eee;
}

.expmodal-detail-box {
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
    padding-bottom: 15px;
    padding-top: 15px;

}

.expmodal-detail {
    font-size: 12px;
    line-height: 1.6;
    display: flex;
    flex-direction: row;
    padding: 6px 0;
    border-top: 1px solid #eee
}

.expmodal-detail:first-child {
    border: none;
}

.expmodal-detail-time {
    color: #83888D;
    font-size: 12px;
    line-height: 1.4;
    width: 140px;
}

.expmodal-detail-text {
    word-break: break-all;
    font-size: 12px;
    line-height: 1.4;
    width: 1px;
    flex: 1;
}

.table-shop {
    font-size: 14px;
    line-height: 1.4;
}

.table-shop-platform {
    color: #83888D;
}

.table-shop-name {
    color: #B6BFC8;
}

.table-flag {
    padding-right: 4px;
    font-size: 14px;
    line-height: 1.3;
    word-break: break-all;
    white-space: normal;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-flag .remark {
    color: #333;
}

.table-flag .remark.straw {
    color: #FF9FAA;
}

.table-flag .remark.orang {
    color: #FE7A0D;
}

.table-flag .remark.grape {
    color: #9E008E;
}


/* 展开行 */
.openLineBox {
    width: 100%;
    padding: 10px 10px 10px 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #F8F8FA;
}

.openline {
    width: 32%;
    display: flex;
    flex-direction: row;
    font-size: 14px;
    line-height: 1.6;
    background-color: #fff;
    border: 1px solid #efefef;
    margin-right: 1%;
    margin-bottom: 10px;
    padding: 4px 1%;
    border-radius: 8px;

}

.openline-fillWidth {
    width: 98%;
}

.openline-key {
    /* width: 130px; */
    color: #999;
    margin-right: 10px;

}

.openline-value {
    flex: 1;
    color: #333;
}

.openline-btnbox {
    width: 100%;
    padding: 6px 40px 0;
    background-color: #F8F8FA;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.openline-btn {
    padding: 0 15px;
    margin-right: 10px;
    height: 34px;
    line-height: 34px;
    border-radius: 17px;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    color: #606266;
}

.openline-btn:hover {
    opacity: 0.8;
}

.openline-btn span {
    margin-left: 6px;
}

.openline-exp {
    width: 100%;
    overflow-y: auto;
    padding: 10px 50px 0;
    background-color: #F8F8FA;

}

.openline-exp-detail {
    font-size: 12px;
    line-height: 1.6;
    display: flex;
    flex-direction: row;
    padding: 6px 0;
    border-top: 1px solid #eee
}

.openline-exp-detail:first-child {
    border: none;
}

.openline-exp-detail-time {
    color: #83888D;
    font-size: 12px;
    line-height: 1.4;
    width: 140px;
}

.openline-exp-detail-text {
    word-break: break-all;
    white-space: normal;
    font-size: 12px;
    line-height: 1.4;
    width: 1px;
    flex: 1;
}

.openline-number-copy {
    display: inline-block;
    width: 14px;
    height: 14px;
    position: relative;
    top: 2px;
    padding-left: 5px;
    opacity: 0.5;
}

.openline-number-copy img {
    display: inline-block;
    width: 14px;
    height: 14px;
}

.openline-copy:hover {
    background-color: #efefef;
}

.openline-copy:hover .openline-number-copy {
    opacity: 1;
}


.photoTimeBtn {
    cursor: pointer;
}
</style>