<template>
    <div class="container">
        <div class="common-topbox">
            <!-- 标题区域 -->
            <Title title="录入新快递" intro="本页只展示24小时内录入的快递">
                <template v-slot:right>
                    <div class="common-title-btn-secondary common-title-btn-icon" @click="reflashAll()"
                        v-if="selectedCode != 807 && selectedCode != 808 && selectedCode != 809 && selectedCode != 810"
                        v-tippy content="更新本页物流">
                        <font-awesome-icon icon="fa-solid fa-arrow-rotate-right" />
                    </div>
                    <div class="common-title-btn-secondary common-title-btn-icon" @click="openAddModal()" v-tippy
                        content="手动添加单号">
                        <font-awesome-icon icon="fa-solid fa-plus" />
                    </div>
                    <div class="common-title-btn-main">
                        <div class="upload-text">从Excel导入</div>
                        <input class="upload-input" type="file" @change="upload($event)" style="width: 320px;"
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                    </div>
                </template>
            </Title>
            <!-- 筛选功能 -->
            <!-- <div class="selectbox">
                <div class="select-left">
                    <div :class="selectedCode == null ? 'select-single active' : 'select-single'"
                        @click="changeStatus(null)">
                        <div class="select-name">
                            全部
                        </div>
                    </div>
                    <div :class="selectedCode == item.key ? 'select-single active' : 'select-single'"
                        v-for="(item, index) in statusCode" @click="changeStatus(item.key)">
                        <div class="select-name">
                            {{ item.value }}
                        </div>
                        <div :class="item.important ? 'select-number active' : 'select-number'"
                            v-if="item.number && item.number > 0">
                            {{ item.number }}
                        </div>
                    </div>
                </div>
            </div> -->

            <!-- 筛选 -->
            <FilterExpress :expNumber="expNumber" :statusCode="statusCode" :selectedCode="selectedCode"
                :showFilterBtn="false" @changeStatus="changeStatus" />

        </div>
        <!-- 表格 -->
        <ExpressTable :tableData="tableData" v-if="tableData" />
        <div class="common-bottombox">
            <Pager :pageSizes="[16, 50, 100, 500]" :tablePage="tablePage" @handlePageChange="handlePageChange" />
        </div>
        <!-- 弹窗 -->
        <van-dialog v-model:show="showAddModal" @confirm="submit()" theme="round" title="手动录入快递" show-cancel-button
            confirm-button-text="提交" confirm-button-color="#6667ab" cancel-button-text="取消" cancel-button-color="#666">
            <div class="common-modalbox">
                <select class="modal-add-select" v-model="info.com" placeholder="快递公司">
                    <option :value="null">请选择快递公司（必填）</option>
                    <option :value="item.code" v-for="item in companyList">{{ item.name }}</option>
                    <!-- <option>顺丰快递</option> -->
                </select>
                <select class="modal-add-select" v-model="info.brandId" placeholder="所属品牌">
                    <option :value="null">店铺</option>
                    <option :value="item.id" v-for="item in brandList">{{ item.name }}</option>
                </select>
                <select class="modal-add-select" v-model="info.platformId" placeholder="所属平台">
                    <option :value="null">平台</option>
                    <option :value="item.id" v-for="item in platformList">{{ item.name }}</option>
                </select>
                <input class="modal-add-input" type="text" v-model="info.phone" placeholder="发货手机号" />
                <textarea class="modal-add-numbers" v-model="info.numbers" placeholder="快递单号(使用空格或换行分隔多个单号)"></textarea>
            </div>
        </van-dialog>
    </div>
</template>

<script>
import Navbar from '@/components/common/navbar'
import ExpressTable from '@/components/express/expressTable'
import Title from '@/components/common/title'
import FilterExpress from '@/components/express/filterExpress'
import Pager from '@/components/common/pager'
import { Toast, Dialog } from 'vant';
import { apiGetExpressList, apiGetNumber, apiAddExpress, apiReflashExpress } from '@/request/api'
import axios from 'axios'; // 引入axios

export default {
    components: {
        Navbar,
        ExpressTable,
        Title,
        FilterExpress,
        Pager,
    },
    name: 'Index',
    // props: {
    //   msg: String
    // },
    data() {
        return {
            loading: false,
            showAddModal: false,
            tableData: null,
            tablePage: {
                currentPage: 1,
                pageSize: 100,
                totalResult: 0
            },
            info: {
                numbers: null, // 快递单号
                com: null, // 快递公司，为空则需要判断快递公司
                phone: 18021537551,
                brandId: null,
                platformId: null,
            },
            companyList: null,
            brandList: null,
            platformList: null,
            statusCode: [],
            selectedCode: null,
            device: this.GLOBAL.device,
            expNumber: {
                all: null,
                unComplete: null,
                complete: null,
                pai: null,
            },
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.getCompanyList()
            this.getBrandList()
            this.getPlatformList()
            this.getStatusCode()
            this.getList()
        },
        getList() {
            let self = this
            let params = {}
            params.page = this.tablePage.currentPage
            params.size = this.tablePage.pageSize
            let timestamp = Math.round(new Date().getTime() / 1000)
            params.createTimeFrom = timestamp - 24 * 60 * 60
            if (this.selectedCode) {
                params.status = this.selectedCode
            }
            apiGetExpressList(params).then(res => {
                self.tableData = res.data.items
                self.tablePage.totalResult = res.data.count
            }).catch(err => {
                console.warn(err)
            })
        },
        getCompanyList() {
            this.companyList = JSON.parse(localStorage.getItem('companyList'))
        },
        getBrandList() {
            this.brandList = JSON.parse(localStorage.getItem('brandList'))
        },
        getPlatformList() {
            this.platformList = JSON.parse(localStorage.getItem('platformList'))
        },
        getStatusCode() {
            this.statusCode = JSON.parse(localStorage.getItem('constantStatusCode'))
            this.getNumber()
        },
        getNumber() {
            let self = this
            let params = {}
            let timestamp = Math.round(new Date().getTime() / 1000)
            params.createTimeFrom = timestamp - 24 * 60 * 60
            apiGetNumber(params).then(res => {
                self.updateStatusCode(res.data)
                self.getAllNumber(res.data)
            }).catch(err => {
                console.warn(err)
            })
        },
        updateStatusCode(data) {
            let statusCodeNew = JSON.parse(JSON.stringify(this.statusCode))
            for (let index = 0; index < statusCodeNew.length; index++) {
                statusCodeNew[index].number = data[statusCodeNew[index].key]
                let importantStatus = "801,802,803,808,810,811"
                if (importantStatus.indexOf(statusCodeNew[index].key) > -1) {
                    statusCodeNew[index].important = true
                }
            }
            this.statusCode = statusCodeNew
        },
        handlePageChange({ currentPage, pageSize }) {
            this.tablePage.currentPage = currentPage
            this.tablePage.pageSize = pageSize
            this.getList()
        },
        changeStatus(id) {
            this.selectedCode = id
            this.tablePage.currentPage = 1
            this.getList()
            this.getNumber()
        },
        getAllNumber(data) {
            this.expNumber.all = data[801] + data[802] + data[803] + data[804] + data[805] + data[806] + data[807] + data[808] + data[809] + data[810] + data[811]
            this.expNumber.unComplete = data[801] + data[802] + data[803] + data[804] + data[805] + data[806] + data[808] + data[809] + data[810] + data[811]
            this.expNumber.complete = data[807]
            this.expNumber.pai = data[805] + data[806]
        },
        openAddModal() {
            this.showAddModal = true
        },
        submit() {
            if (!this.info.com) {
                Toast.fail('请选择快递公司')
                return
            }
            let numbers = this.info.numbers.split(/[\s\n]/) //转化为数组
            numbers = this.unique(numbers) // 去重
            let params = []
            for (let index = 0; index < numbers.length; index++) {
                if (numbers[index]) {
                    let obj = {
                        number: numbers[index],
                        com: this.info.com,
                        phone: this.info.phone,
                        brandId: this.info.brandId,
                        platformId: this.info.platformId,
                    }
                    params.push(obj)
                }
            }
            this.addExpress(params)

        },
        addExpress(params) {
            Toast.loading('提交中')
            let self = this
            for (let index = 0; index < params.length; index++) {
                if (!params[index].com) {
                    delete params[index].com
                }
                if (!params[index].phone) {
                    delete params[index].phone
                }
                if (!params[index].brandId) {
                    delete params[index].brandId
                }
                if (!params[index].platformId) {
                    delete params[index].platformId
                }
                if (!params[index].orderNumber) {
                    delete params[index].orderNumber
                }
            }
            apiAddExpress(params).then(res => {
                let data = res.data
                Toast.clear()
                Dialog.alert({
                    title: '提交结果',
                    message: '共提交' + data.total + '单，成功' + data.success + '单，重复' + (data.total - data.success) + '单',
                    confirmButtonText: '好的'
                })
                    .then(() => {
                        // on confirm
                    })
                self.getList()
                self.getNumber()
                self.webhook(data)
            }).catch(err => {
                console.warn(err)
                Toast.clear()
            })
        },
        webhook(data) {
            if (data.success == 0) {
                return
            }
            let url = 'https://fc.moemee.com/mrp-webhook'
            let params = {
                msgtype: 'text',
                message: '新快递单已登记：' + data.success + '/' + data.total
            }
            let self = this
            axios({
                method: "post",
                url: url,
                params: params,
                headers: {
                    'content-type': 'application/json', // 默认值
                }
            }).then(res => {
                console.log("res", res)
            }).catch(err => {
                console.log("err", err)
            })
        },
        upload(event) {
            let self = this
            var XLSX = require("xlsx");
            // let inputDOM = this.$refs.inputer;
            if (!event.target.value) {
                return;
            }
            let file = event.target.files[0]

            let reader = new FileReader();
            reader.onload = function (e) {
                let data = e.target.result;
                let excelData = XLSX.read(data, {
                    type: 'binary'
                });
                //excelData.SheetNames[0]是获取Sheets中第一个Sheet的名字
                //excelData.Sheets[Sheet名]获取第一个Sheet的数据
                let json = XLSX.utils.sheet_to_json(excelData.Sheets[excelData.SheetNames[0]]);
                self.excelWoda(json)
            };
            reader.readAsBinaryString(file);
        },
        excelWoda(json) {
            // 发货人: "小舞酱"
            // 发货人手机号: "180****7551"
            // 发货人详细地址: "江苏省镇江市句容市宝华镇京东紫晶"
            // 商品数量: 1
            // 商品简称: "APH环球美食"
            // 平台: "淘宝/天猫"
            // 序号: "1"
            // 店铺名称: "ngised"
            // 快递公司: "中通"
            // 状态: ""
            // 生成时间: "2022-03-11 10:08:17"
            // 订单编号: "1512589838925695996"
            // 运单号: "75862043872047"
            // 运单号ID: "ZUMok5pijBPisVzZlNz"
            Toast.loading('解析.xlxs中')
            let params = []
            for (let index = 0; index < json.length; index++) {
                if (json[index].运单号 && json[index].状态 != "已回收") {
                    // 手机号匹配
                    let phone = "18021537551"
                    if (json[index].发货人手机号.indexOf("7551")) {
                        phone = "18021537551"
                    } else if (json[index].发货人手机号.indexOf("3170")) {
                        phone = "13770533170"
                    }
                    // 快递公司
                    let com = null
                    this.companyList.forEach(company => {
                        if (company.name.indexOf(json[index].快递公司) > -1) {
                            com = company.code
                        }
                    });
                    // if (json[index].快递公司 == "中通") {
                    //     com = "zhongtong"
                    // } else if (json[index].快递公司 == "韵达") {
                    //     com = "yunda"
                    // } else if (json[index].快递公司 == "顺丰") {
                    //     com = "shunfeng"
                    // }
                    // 品牌
                    // let brandId = null
                    // let moemeeBrandId
                    // let yuewugeBrandId
                    // let yhkBrandId
                    // let brandList = this.brandList
                    // for (let index2 = 0; index2 < brandList.length; index2++) {
                    //     if (brandList[index2].name == "萌米") {
                    //         moemeeBrandId = brandList[index2].id
                    //     }
                    //     if (brandList[index2].name == "月舞阁") {
                    //         yuewugeBrandId = brandList[index2].id
                    //     }
                    //     if (brandList[index2].name == "鱼好睏") {
                    //         yhkBrandId = brandList[index2].id
                    //     }
                    // }
                    // if (json[index].店铺名称 == '萌米社') {
                    //     brandId = moemeeBrandId
                    // }
                    // if (json[index].店铺名称 == 'ngised' || json[index].店铺名称 == '月舞阁') {
                    //     brandId = yuewugeBrandId
                    // }
                    // if (json[index].店铺名称 == '鱼好睏') {
                    //     brandId = yhkBrandId
                    // }
                    const brandIds = {
                        '萌米社': null,
                        'ngised': null,
                        '月舞阁': null,
                        '鱼好睏': null
                    };

                    this.brandList.forEach(brand => {
                        if (brand.name in brandIds) {
                            brandIds[brand.name] = brand.id;
                        }
                        if (brand.name == '月舞阁') {
                            brandIds['ngised'] = brand.id;
                        }
                    });

                    let brandId = brandIds[json[index].店铺名称];
                    // 平台
                    // let platformId = null
                    // let tbPlatformId
                    // let pddPlatformId
                    // let handmakePlatformId
                    // let fileimportPlatformId
                    // let dyPlatformId
                    // let weidianPlatformId
                    // let platformList = this.platformList
                    // for (let index3 = 0; index3 < platformList.length; index3++) {
                    //     if (platformList[index3].name == "淘宝") {
                    //         tbPlatformId = platformList[index3].id
                    //     }
                    //     if (platformList[index3].name == "拼多多") {
                    //         pddPlatformId = platformList[index3].id
                    //     }
                    //     if (platformList[index3].name == "手工录入") {
                    //         handmakePlatformId = platformList[index3].id
                    //     }
                    //     if (platformList[index3].name == "文件导入") {
                    //         fileimportPlatformId = platformList[index3].id
                    //     }
                    //     if (platformList[index3].name == "抖店") {
                    //         dyPlatformId = platformList[index3].id
                    //     }
                    //     if (platformList[index3].name == "微店") {
                    //         weidianPlatformId = platformList[index3].id
                    //     }

                    // }
                    // if (json[index].平台 == '淘宝/天猫') {
                    //     platformId = tbPlatformId
                    // }
                    // if (json[index].平台 == '拼多多') {
                    //     platformId = pddPlatformId
                    // }
                    // if (json[index].平台 == '手工录入') {
                    //     platformId = handmakePlatformId
                    // }
                    // if (json[index].平台 == '文件导入') {
                    //     platformId = fileimportPlatformId
                    // }
                    // if (json[index].平台 == '抖店') {
                    //     platformId = dyPlatformId
                    // }
                    // if (json[index].平台 == '微店') {
                    //     platformId = weidianPlatformId
                    // }
                    const platformIds = {
                        '淘宝/天猫': null,
                        '拼多多': null,
                        '手工录入': null,
                        '文件导入': null,
                        '抖店': null,
                        // '微店': null
                    };

                    this.platformList.forEach(platform => {
                        if (platform.name in platformIds) {
                            platformIds[platform.name] = platform.id;
                        }
                        if (platform.name == '淘宝') {
                            platformIds['淘宝/天猫'] = platform.id;
                        }
                    });

                    let platformId = platformIds[json[index].平台];
                    // 汇总
                    let obj = {
                        number: json[index].运单号, // 运单号
                        com: com, // 快递公司
                        phone: phone, // 电话
                        brandId: brandId, // 品牌
                        platformId: platformId, // 平台
                        orderNumber: json[index].订单编号, // 订单号
                    }
                    params.push(obj)
                }
            }
            this.addExpress(params)
        },
        unique(arr) {
            // 去重功能
            if (!Array.isArray(arr)) {
                console.warn('type error!')
                return
            }
            let array = [];
            for (let i = 0; i < arr.length; i++) {
                if (array.indexOf(arr[i]) === -1) {
                    array.push(arr[i])
                }
            }
            return array;
        },
        reflashAll() {
            Toast.loading('刷新中')
            let data = JSON.parse(JSON.stringify(this.tableData))
            // for (let index = 0; index < data.length; index++) {
            //     this.reflashOne(data[index])
            // }
            let promiseArr = []
            data.forEach((item, index) => {
                promiseArr.push((async (item) => {
                    await this.reflashOne(item, index)
                })(item))
            })
            Promise.all(promiseArr).then(itemList => {
                Toast.clear()
                Toast.success('全部刷新完成');
                this.getList()
            })
        },
        reflashOne(oneData, index) {
            let self = this
            return new Promise((resolve, reject) => {
                apiReflashExpress(oneData).then(res => {
                    // 
                    Toast.loading('正在刷新 ' + index);
                    resolve('成功')
                }).catch(err => {
                    console.warn(err)
                    reject('失败')
                })
            });
        },
        formatTime({ cellValue, row, column }) {
            let time = new Date(cellValue)
            let year = time.getFullYear()
            let month = time.getMonth() + 1
            let date = time.getDate()
            let hours = time.getHours()
            let minute = time.getMinutes()
            let second = time.getSeconds()

            if (month < 10) { month = '0' + month }
            if (date < 10) { date = '0' + date }
            if (hours < 10) { hours = '0' + hours }
            if (minute < 10) { minute = '0' + minute }
            if (second < 10) { second = '0' + second }
            return year + '.' + month + '.' + date + ' ' + hours + ':' + minute
        }
    }
}
</script>

<style scoped>
/* 筛选功能 */
.selectbox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
}

.select-left {
    width: 1px;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.select-single {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #e9e9eb;
    color: #666;
    border-right: 1px solid #fff;
    height: 32px;
    cursor: pointer;
    padding: 0 6px;
}

.select-single:first-child {
    border-radius: 16px 0 0 16px;
    padding-left: 4px;
}

.select-single:last-child {
    border-radius: 0 16px 16px 0;
    border-right: none;
    padding-right: 4px;
}

.select-single:hover {
    opacity: 0.7;
}

.select-single.active {
    background-color: #333;
    color: #fff;
}

.select-name {
    font-size: 14px;
    word-break: keep-all;
    white-space: nowrap;
}

.select-number {
    margin-left: 3px;
    font-size: 12px;
    background-color: #e9e9eb;
    font-weight: bold;
    color: #666;
    padding: 0 4px;
    height: 18px;
    min-width: 18px;
    text-align: center;
    line-height: 18px;
    border-radius: 9px;
    word-break: keep-all;
    white-space: nowrap;
}

.select-number.active {
    background-color: #fd4343;
    color: #fff;
}

/* 选择时间 */

.select-more {
    width: 120px;
    margin-left: 10px;
    height: 32px;
    border-radius: 16px;
    padding: 0 8px;
    border: none;
    background-color: #e9e9eb;
    color: #666;
    outline: none;
    cursor: pointer;
    /* appearance:none; */
}

/* 弹窗 手动录入快递 */
.modal-add-select {
    width: 100%;
    height: 30px;
    border-radius: 15px;
    border: 1px solid #e6e6ef;
    outline: none;
    margin-bottom: 15px;
    padding: 0 10px;
    font-size: 14px;
    color: #333;
}

.modal-add-numbers {
    width: 100%;
    height: 200px;
    border-radius: 15px;
    border: 1px solid #e6e6ef;
    outline: none;
    padding: 10px 10px;
    font-size: 14px;
    color: #333;
    resize: none;
}

/* 上传按钮 */

.upload-text {
    display: inline-block;
    cursor: pointer;
}

.upload-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 2;
}

.modal-add-input {
    width: 100%;
    height: 30px;
    border-radius: 15px;
    border: 1px solid #e6e6ef;
    outline: none;
    margin-bottom: 15px;
    padding: 0 10px;
    font-size: 14px;
    color: #333;
}
</style>