<template>
    <div class="container">
        <div class="common-topbox">
            <!-- 标题区域 -->
            <Title title="快递设置">
            </Title>
        </div>
        <div class="common-midbox">
            <!-- 输入项目 -->
            <div class="inputbox">
                <div class="left">
                    默认查询手机号
                </div>
                <div class="right">
                    <input class="input" type="number" placeholder="请输入11位手机号" />
                </div>
            </div>
            <!-- 输入项目 -->
            <!-- <div class="inputbox">
                <div class="left">
                    常用快递
                </div>
                <div class="right">
                    <van-checkbox-group v-model="expressResult" direction="horizontal" :max="10" >
                        <van-checkbox :name="item.type" v-for="(item,index) in expressTypes">{{item.name}}</van-checkbox>
                    </van-checkbox-group>
                </div>
            </div> -->
            <!-- 输入项目 -->
            <!-- <div class="inputbox">
                <div class="left">
                    发货所在省份
                </div>
                <div class="right">
                    <select class="input" type="number" placeholder="请选择（单选）">
                        <option :value="item.id" v-for="(item,index) in province.result">
                            {{item.name}}
                        </option>
                    </select>
                </div>
            </div> -->
            <!-- 保存按钮 -->
            <div class="btnbox">
                <button class="main">保存</button>
                <button class="secondry">取消</button>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/common/navbar'
import Title from '@/components/common/title'
import { Toast, Dialog } from 'vant';
// import { apiName } from '@/request/api'
import axios from 'axios';

export default {
    components: {
        Navbar,
        Title,
    },
    name: 'Index',
    // props: {
    //   msg: String
    // },
    data() {
        return {
            device: this.GLOBAL.device,
            loading: false,
            expressTypes: [],
            expressResult: [],
            province: {
                "status": 0,
                "msg": "ok",
                "result": [
                    {
                        "id": "1",
                        "name": "北京",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "010",
                        "zipcode": "100000",
                        "depth": "1"
                    },
                    {
                        "id": "2",
                        "name": "安徽",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "3",
                        "name": "福建",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "4",
                        "name": "甘肃",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "5",
                        "name": "广东",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "6",
                        "name": "广西",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "7",
                        "name": "贵州",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "8",
                        "name": "海南",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "9",
                        "name": "河北",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "10",
                        "name": "河南",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "11",
                        "name": "黑龙江",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "12",
                        "name": "湖北",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "13",
                        "name": "湖南",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "14",
                        "name": "吉林",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "15",
                        "name": "江苏",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "16",
                        "name": "江西",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "17",
                        "name": "辽宁",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "18",
                        "name": "内蒙古",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "19",
                        "name": "宁夏",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "20",
                        "name": "青海",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "21",
                        "name": "山东",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "22",
                        "name": "山西",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "23",
                        "name": "陕西",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "24",
                        "name": "上海",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "021",
                        "zipcode": "200000",
                        "depth": "1"
                    },
                    {
                        "id": "25",
                        "name": "四川",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "26",
                        "name": "天津",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "022",
                        "zipcode": "300000",
                        "depth": "1"
                    },
                    {
                        "id": "27",
                        "name": "西藏",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "28",
                        "name": "新疆",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "29",
                        "name": "云南",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "30",
                        "name": "浙江",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "",
                        "zipcode": "",
                        "depth": "1"
                    },
                    {
                        "id": "31",
                        "name": "重庆",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "023",
                        "zipcode": "404100",
                        "depth": "1"
                    },
                    {
                        "id": "32",
                        "name": "香港",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "00852",
                        "zipcode": "999077",
                        "depth": "1"
                    },
                    {
                        "id": "33",
                        "name": "澳门",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "00853",
                        "zipcode": "999078",
                        "depth": "1"
                    },
                    {
                        "id": "34",
                        "name": "台湾",
                        "parentid": "0",
                        "parentname": "",
                        "areacode": "00886",
                        "zipcode": "",
                        "depth": "1"
                    }]
            }
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.getExpressType()
        },

        getExpressType() {
            let self = this
            axios.get('https://fc.moemee.com/express-type').then(function (response) {
                let res = response.data
                if (res && res.code == '200') {
                    self.expressTypes = res.data.result
                } else {
                    console.warn('出错了');
                }
            }).catch(function (error) {
                // handle error
                console.warn(error);
            })
        },
        api() {
            let self = this
            apiName({
                page: 1,
                size: 10,
            }).then(res => {

            }).catch(err => {
                console.warn(err)
            })
        },
    }
}
</script>

<style scoped>
.inputbox {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
}

.inputbox .left {
    width: 150px;
    line-height: 50px;
    font-size: 16px;
}

.inputbox .right {
    flex: 1;
    min-height: 50px;
}

.inputbox .input {
    width: 300px;
    height: 50px;
    border-radius: 25px;
    border: 1px solid #e9e9eb;
    padding: 0 15px;
}

.btnbox {
    width: 100%;
    margin-top: 50px;
}

.btnbox button {
    width: 200px;
    height: 50px;
    margin-right: 15px;
    border: none;
    outline: none;
    border-radius: 25px;
    font-size: 16px;
    cursor: pointer;
}

.btnbox button:hover {
    opacity: 0.7;
}

.btnbox .main {
    background-color: #6667ab;
    color: #fff;
}

.btnbox .secondry {
    background-color: #e9e9eb;
    color: #666;
}

.van-checkbox--horizontal {
    margin-bottom: 10px;
}
</style>