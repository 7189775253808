import Vue from 'vue'
import App from './App.vue'
import router from "./router/index";

// iconfont
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPenToSquare, faTrashCan, faPause, faPlay, faPowerOff, faBookOpen, faEye, faFilter, faArrowRotateRight, faCircleStop, faReply, faNoteSticky, faXmark, faCircleXmark, faArrowRight, faAngleLeft, faPlus, faCopy, faMagnifyingGlass, faClock, faImage, faDownload , faEllipsis} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faPenToSquare, faTrashCan, faPause, faPlay, faPowerOff, faBookOpen, faEye, faFilter, faArrowRotateRight, faCircleStop, faReply, faNoteSticky, faXmark, faCircleXmark, faArrowRight, faAngleLeft, faPlus, faCopy, faMagnifyingGlass, faClock, faImage, faDownload, faEllipsis)
Vue.component('font-awesome-icon', FontAwesomeIcon)


// 表格
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx'
Vue.use(VXETable)
VXETable.use(VXETablePluginExportXLSX)

// vant
import { Dialog, Checkbox, CheckboxGroup, RadioGroup, Radio, Toast } from 'vant';
// Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Checkbox, CheckboxGroup, RadioGroup, Radio);

// import Vue from 'vue';
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

//  vue-tippy
import VueTippy, { TippyComponent } from "vue-tippy";

Vue.use(VueTippy);
Vue.component("tippy", TippyComponent);


// 无限滚动
import InfiniteLoading from 'vue-infinite-loading';

Vue.use(InfiniteLoading, {
    props: {
        spinner: 'waveDots',
        distance: 500,
        /* other props need to configure */
    },
});


//vue select
// import vSelect from 'vue-select'

// Vue.component('v-select', vSelect)
// import 'vue-select/dist/vue-select.css';

// 全局变量
import global_ from './components/common/global'//引用文件
Vue.prototype.GLOBAL = global_//挂载到Vue实例上面
Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
