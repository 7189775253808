<template>
    <div class="container">
        <div class="common-topbox">
            <!-- 标题区域 -->
            <Title title="成员管理">
                <template v-slot:right>
                    <!-- 右侧按钮区域插槽 -->
                    <div class="common-title-btn-main common-title-btn-icon" @click="openModal('add')" v-tippy
                        content="添加成员">
                        <font-awesome-icon icon="fa-solid fa-plus" />
                    </div>
                </template>
            </Title>
        </div>
        <div class="common-midbox" v-if="tableData">
            <!-- height="300" :loading="loading" -->
            <vxe-table round border highlight-hover-row resizable ref="xTable" :data="tableData.items">
                <vxe-table-column type="seq" width="60"></vxe-table-column>
                <vxe-table-column field="nickname" title="昵称"></vxe-table-column>
                <vxe-table-column field="phone" title="手机号" :formatter="formatPhone"></vxe-table-column>
                <!-- <vxe-table-column field="field2" title="密码"></vxe-table-column> -->
                <vxe-table-column field="role" title="菜单权限" :formatter="formatRole"></vxe-table-column>
                <vxe-table-column field="status" :formatter="formatStatus" title="状态"></vxe-table-column>
                <vxe-table-column field="remarks" title="备注"></vxe-table-column>
                <vxe-table-column title="操作" width="102">
                    <template #default="{ row }">
                        <vxe-button @click="edit(row)" circle>
                            <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                        </vxe-button>
                        <vxe-button @click="pause(row)" circle v-if="row.status == 1">
                            <font-awesome-icon icon="fa-solid fa-pause" />
                        </vxe-button>
                        <vxe-button @click="play(row)" circle v-if="row.status == 0">
                            <font-awesome-icon icon="fa-solid fa-play" />
                        </vxe-button>
                    </template>
                </vxe-table-column>
            </vxe-table>
        </div>
        <div class="common-bottombox">
            <Pager :pageSizes="[10, 100]" :tablePage="tablePage" @handlePageChange="handlePageChange" />
        </div>
        <!-- 弹窗 -->
        <van-dialog v-model:show="modal.show" @confirm="submit()" theme="round"
            :title="modal.type == 'add' ? '添加新成员' : '修改成员'" show-cancel-button confirm-button-text="提交"
            confirm-button-color="#6667ab" cancel-button-text="取消" cancel-button-color="#666">
            <div class="common-modalbox">
                <input class="modal-add-input" type="text" v-model="info.nickname" placeholder="昵称" />
                <input class="modal-add-input" type="phone" v-model="info.phone" placeholder="手机号" />
                <input class="modal-add-input" type="password" v-model="info.password"
                    :placeholder="modal.type == 'add' ? '密码' : '密码（不填代表不修改）'" />
                <select class="modal-add-select" v-model="info.status" placeholder="状态">
                    <option value="1">启用</option>
                    <option value="0">禁用</option>
                </select>
                <div class="modal-add-checkbox">
                    <div class="modal-add-checkbox-title">
                        用户权限：
                    </div>
                    <van-checkbox-group v-model="info.role" direction="horizontal">
                        <van-checkbox :name="item.key" v-for="(item, index) in roles" :key="item.key">{{ item.value
                        }}</van-checkbox>
                    </van-checkbox-group>
                </div>
                <textarea class="modal-add-textarea" v-model="info.remarks" placeholder="备注"></textarea>
            </div>
        </van-dialog>
    </div>
</template>

<script>
import Navbar from '@/components/common/navbar'
import Title from '@/components/common/title'
import Pager from '@/components/common/pager'
import { Toast, Dialog } from 'vant';
import { apiAddUser, apiGetUserList, apiEditUser, apiEditUserRoles, apiEnableUserRoles, apiDisableUserRoles } from '@/request/api'

export default {
    components: {
        Navbar,
        Title,
        Pager,
    },
    name: 'Index',
    // props: {
    //   msg: String
    // },
    data() {
        return {
            device: this.GLOBAL.device,
            loading: false,
            tableData: null,
            modal: {
                show: false,
                type: "add"
            },
            info: {
                phone: null,
                nickname: null,
                password: null,
                remark: null,
                status: 1,
                role: ["desktop"],
            },
            roles: null,
            tablePage: {
                currentPage: 1,
                pageSize: 10,
                totalResult: 0
            },
            tableData: null,
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.getList()
            this.getRoles()
        },
        openModal(type) {
            if (type == 'add') {
                this.info = {
                    phone: null,
                    nickname: null,
                    password: null,
                    remark: null,
                    status: 1,
                    role: ["desktop"],
                }
            }
            this.modal = {
                show: true,
                type: type
            }
        },
        getList() {
            let self = this
            apiGetUserList({
                page: this.tablePage.currentPage,
                size: this.tablePage.pageSize,
            }).then(res => {

                self.tableData = res.data
            }).catch(err => {
                console.warn(err)
            })
        },
        handlePageChange({ currentPage, pageSize }) {
            this.tablePage.currentPage = currentPage
            this.tablePage.pageSize = pageSize
            this.getList()
        },
        getRoles() {
            this.roles = JSON.parse(localStorage.getItem('constantRoles'))
        },
        submit() {
            Toast.loading('提交中')
            let md5 = require('md5-js');
            let self = this
            if (this.modal.type == 'add') {
                let params
                params = this.info
                params.password = md5(params.password)
                apiAddUser(params).then(res => {

                    Toast.clear()
                    Toast.success('添加成功');
                    self.getList()
                }).catch(err => {
                    console.warn(err)
                    Toast.clear()
                })
            } else {
                let params;
                params = this.info
                if (params.password) {
                    params.password = md5(params.password)
                } else {
                    delete params.password
                }
                apiEditUser(params).then(res => {

                    Toast.clear()
                    Toast.success('修改成功');
                    self.getList()
                }).catch(err => {
                    console.warn(err)
                    Toast.clear()
                })

            }

        },
        edit(row) {
            this.info = JSON.parse(JSON.stringify(row))
            this.openModal('edit')
        },
        formatRole(table) {
            let result = ""
            let roles = this.roles
            for (let index = 0; index < table.cellValue.length; index++) {
                let value = table.cellValue[index]
                for (let index = 0; index < roles.length; index++) {
                    let role = roles[index].key
                    if (value == role) {
                        result = result + roles[index].value + "、"
                    }
                }
            }
            if (result) {
                result = result.substr(0, result.length - 1);
            } else {
                result = table.cellValueroles[index]
            }
            return result
        },
        formatPhone(table) {
            const reg = /^(\d{3})\d{4}(\d{4})$/;
            let result = table.cellValue.replace(reg, "$1****$2");
            return result
        },
        formatStatus(table) {
            let id = table.cellValue
            let result
            if (id == 1) {
                result = "启用"
            } else {
                result = "禁用"
            }
            return result
        },
        pause(row) {
            Toast.loading('修改中')
            let self = this
            let params = JSON.parse(JSON.stringify(row))
            apiDisableUserRoles(params).then(res => {
                Toast.clear()
                Toast.success('已禁用');
                self.getList()
            }).catch(err => {
                console.warn(err)
                Toast.clear()
            })
        },
        play(row) {
            Toast.loading('修改中')
            let self = this
            let params = JSON.parse(JSON.stringify(row))
            apiEnableUserRoles(params).then(res => {
                Toast.clear()
                Toast.success('已启用');
                self.getList()
            }).catch(err => {
                console.warn(err)
                Toast.clear()
            })
        },
        api() {
            let self = this
            apiName({
                page: 1,
                size: 10,
            }).then(res => {

            }).catch(err => {
                console.warn(err)
            })
        },
    }
}
</script>

<style scoped>
/* 弹窗 手动录入快递 */
.modal-add-select {
    width: 100%;
    height: 30px;
    border-radius: 15px;
    border: 1px solid #e6e6ef;
    outline: none;
    margin-bottom: 15px;
    padding: 0 10px;
    font-size: 14px;
    color: #333;
}

.modal-add-textarea {
    width: 100%;
    height: 80px;
    border-radius: 15px;
    border: 1px solid #e6e6ef;
    outline: none;
    padding: 10px 10px;
    font-size: 14px;
    color: #333;
    resize: none;
}

.modal-add-input {
    width: 100%;
    height: 30px;
    border-radius: 15px;
    border: 1px solid #e6e6ef;
    outline: none;
    margin-bottom: 15px;
    padding: 0 10px;
    font-size: 14px;
    color: #333;
}

.modal-add-checkbox {
    margin-bottom: 15px;
}

.modal-add-checkbox-title {
    margin-bottom: 10px;
    font-size: 14px;
    color: #666;
}
</style>