<template>
    <div class="container">
        <div class="common-topbox">
            <!-- 标题区域 -->
            <Title title="省内运输规则">
            </Title>
        </div>
        <!-- <div class="content-table" v-show="tableData"> -->
        <div class="common-midbox">
            <!-- height="300" :loading="loading" -->
            <vxe-table round border highlight-hover-row resizable ref="xTable" :data="tableData"
                :tooltip-config="tableTooltipConfig">
                <vxe-table-column type="seq" width="60"></vxe-table-column>
                <!-- <vxe-table-column field="id" title="ID" width="60"></vxe-table-column> -->
                <!-- <vxe-table-column field="field1" :formatter="formatTime" title="反馈时间"></vxe-table-column> -->
                <vxe-table-column field="name" title="快递公司" width="120"></vxe-table-column>
                <vxe-table-column field="provRule" title="出省规则"></vxe-table-column>
                <vxe-table-column field="provRuleRemarks" title="备注" show-overflow="ellipsis"></vxe-table-column>
                <vxe-table-column title="操作" width="54">
                    <template #default="{ row }">
                        <vxe-button @click="edit(row)" circle>
                            <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                        </vxe-button>
                        <!-- <vxe-button @click="del(row)" circle>
                            <font-awesome-icon icon="fa-solid fa-trash-can" />
                        </vxe-button> -->
                    </template>

                </vxe-table-column>
            </vxe-table>
        </div>
        <div class="common-bottombox">

            <vxe-pager background :loading="loading" :current-page="tablePage.currentPage" :page-size="tablePage.pageSize"
                :total="tablePage.totalResult" :page-sizes="[16, 50, 100, 500]"
                :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'FullJump', 'Sizes', 'Total']"
                @page-change="handlePageChange">
            </vxe-pager>
        </div>
        <!-- 弹窗 -->
        <van-dialog v-model:show="showModal" @confirm="submit()" theme="round" title="添加规则" show-cancel-button
            confirm-button-text="提交" confirm-button-color="#6667ab" cancel-button-text="取消" cancel-button-color="#666">
            <div class="common-modalbox">
                <select class="modal-add-select" v-model="info.code" name="info" placeholder="选择快递公司" disabled>
                    <option>选择快递公司</option>
                    <option :value="item.code" v-for="item in companyList">{{ item.name }}</option>
                </select>
                <textarea class="modal-add-input" v-model="info.provRule" placeholder="规则（正则表达式）"></textarea>
                <textarea class="modal-add-input" v-model="info.provRuleRemarks" placeholder="备注（选填）"></textarea>
            </div>
        </van-dialog>
    </div>
</template>

<script>
import Navbar from '@/components/common/navbar'
import Title from '@/components/common/title'
import { Toast, Dialog } from 'vant';
import { apiProvList, apiEditProv } from '@/request/api'

export default {
    components: {
        Navbar,
        Title,
    },
    name: 'Index',
    // props: {
    //   msg: String
    // },
    data() {
        return {
            loading: false,
            tableData: null,
            info: {
                code: null,
                provRule: null,
                provRuleRemarks: null,
            },
            showModal: false,
            tableData: null,
            tablePage: {
                currentPage: 1,
                pageSize: 100,
                totalResult: 0
            },
            companyList: null,
            numberDate: [
                {
                    name: "全部",
                    number: null,
                    important: false
                },
                {
                    name: "常用",
                    number: null,
                    important: true
                },
                {
                    name: "非常用",
                    number: 50,
                    important: false
                }],
            tableTooltipConfig: {
                showAll: true,
                enterable: true,
            },
            device: this.GLOBAL.device,
        }
    },
    mounted() {
        this.init()
    },

    methods: {
        init() {
            this.getList()
        },
        getCompanyList() {
            this.companyList = JSON.parse(localStorage.getItem('companyList'))
        },
        getList() {
            let self = this
            apiProvList({
                page: this.tablePage.currentPage,
                size: this.tablePage.pageSize,
            }).then(res => {

                self.tableData = res.data.items
                self.tablePage.totalResult = res.data.count
            }).catch(err => {
                console.warn(err)
            })
        },
        handlePageChange({ currentPage, pageSize }) {
            this.tablePage.currentPage = currentPage
            this.tablePage.pageSize = pageSize
            this.getList()
        },
        edit(row) {
            this.info = JSON.parse(JSON.stringify(row))
            this.getCompanyList()
            this.openModal('edit')
        },
        submit() {
            Toast.loading('提交中')
            let self = this
            // 编辑
            let params = {};
            params = this.info
            apiEditProv(params).then(res => {

                Toast.clear()
                Toast.success('修改成功');
                self.getList()
            }).catch(err => {
                console.warn(err)
                Toast.clear()
            })
        },
        openModal() {
            this.showModal = true
        },
        api() {
            let self = this
            apiName({
                page: 1,
                size: 10,
            }).then(res => {

            }).catch(err => {
                console.warn(err)
            })
        },
    }
}
</script>

<style scoped>
/* 弹窗 手动录入快递 */
.modal-add-select {
    width: 100%;
    height: 30px;
    border-radius: 15px;
    border: 1px solid #e6e6ef;
    outline: none;
    margin-bottom: 15px;
    padding: 0 10px;
    font-size: 14px;
    color: #333;
}

.modal-add-numbers {
    width: 100%;
    height: 200px;
    border-radius: 15px;
    border: 1px solid #e6e6ef;
    outline: none;
    padding: 10px 10px;
    font-size: 14px;
    color: #333;
    resize: none;
}

.modal-add-input {
    width: 100%;
    height: 80px;
    border-radius: 15px;
    border: 1px solid #e6e6ef;
    outline: none;
    padding: 10px 10px;
    font-size: 14px;
    color: #333;
    resize: none;
    margin-bottom: 15px;
}

.modal-add-input:last-child {
    margin-bottom: 0;
}

/* 筛选功能 */
.selectbox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
}

.select-left {
    width: 1px;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.select-single {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #e9e9eb;
    border-right: 1px solid #fff;
    height: 32px;
    cursor: pointer;
}

.select-single:first-child {
    border-radius: 16px 0 0 16px;
    padding-left: 4px;
}

.select-single:last-child {
    border-radius: 0 16px 16px 0;
    border-right: none;
    padding-right: 4px;
}

.select-single:hover {
    opacity: 0.7;
}

.select-name {
    font-size: 14px;
    color: #666;
}

.select-number {
    margin-left: 3px;
    font-size: 12px;
    background-color: #e9e9eb;
    font-weight: bold;
    color: #666;
    padding: 0 4px;
    height: 18px;
    min-width: 18px;
    text-align: center;
    line-height: 18px;
    border-radius: 9px;
    word-break: keep-all;
    white-space: nowrap;
}

.select-number.active {
    background-color: #fd4343;
    color: #fff;
}
</style>