// router.js
// https://router.vuejs.org/zh/

import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
// 主页
import Index from "../pages/index.vue";
import Login from "../pages/login.vue";
import Desktop from "../pages/desktop/index.vue";
// 物流
import ExpressData from "../pages/express/data.vue";
import ExpressAdd from "../pages/express/add.vue";
import ExpressExpress from "../pages/express/express.vue";
import ExpressFlag from "../pages/express/flag.vue";
import ExpressReturn from "../pages/express/return.vue";
import ExpressSearch from "../pages/express/search.vue";
import ExpressRule from "../pages/express/rule.vue";
import ExpressSetting from "../pages/express/setting.vue";
import ExpressCny2024 from "../pages/express/cny2024.vue";
import ExpressImportant from "../pages/express/important.vue";
import ExpressUploadImg from "../pages/express/uploadImg.vue";
import PackagingTime from "../pages/express/packagingTime2.vue";
import CheckDay from "../pages/express/checkDay.vue";

// 设置
import SettingPerson from "../pages/setting/person.vue";
import SettingBrand from "../pages/setting/brand.vue";
import SettingCompany from "../pages/setting/company.vue";
import SettingPlatform from "../pages/setting/platform.vue";

// 小工具
import Darkroom from "../pages/tools/darkroom.vue";
import ToolsBadgeImage from "../pages/tools/badgeImage.vue";
import Consumables from "../pages/tools/consumables.vue";

export default new VueRouter({
  mode: "hash", //vue-router路由模式的默认方式
  routes: [
    // { path: '/user/:id', component: User }
    // 基础
    { name: "Home", path: "/", component: Index },
    { name: "Login", path: "/login", component: Login },
    { name: "Desktop", path: "/desktop", component: Desktop },
    // 快递
    { name: "ExpressData", path: "/express/data", component: ExpressData },
    { name: "ExpressAdd", path: "/express/add", component: ExpressAdd },
    {
      name: "ExpressExpress",
      path: "/express/express",
      component: ExpressExpress,
    },
    { name: "ExpressFlag", path: "/express/flag", component: ExpressFlag },
    {
      name: "ExpressReturn",
      path: "/express/return",
      component: ExpressReturn,
    },
    {
      name: "ExpressSearch",
      path: "/express/search",
      component: ExpressSearch,
    },
    { name: "ExpressRule", path: "/express/rule", component: ExpressRule },
    {
      name: "PackagingTime",
      path: "/express/packaging",
      component: PackagingTime,
    },
    {
      name: "ExpressSetting",
      path: "/express/setting",
      component: ExpressSetting,
    },
    {
      name: "ExpressCny2024",
      path: "/express/cny2024",
      component: ExpressCny2024,
    },
    {
      name: "ExpressImportant",
      path: "/express/important",
      component: ExpressImportant,
    },
    { name: "CheckDay", path: "/express/checkDay", component: CheckDay },
    // 小工具
    {
      name: "ExpressUploadImg",
      path: "/express/uploadImg",
      component: ExpressUploadImg,
    },
    {
      name: "ToolsBadgeImage",
      path: "/tools/badgeImage",
      component: ToolsBadgeImage,
    },
    { name: "Darkroom", path: "/tools/darkroom", component: Darkroom },
    { name: "Consumables", path: "/tools/consumables", component: Consumables },
    // 设置
    {
      name: "SettingPerson",
      path: "/setting/person",
      component: SettingPerson,
    },
    { name: "SettingBrand", path: "/setting/brand", component: SettingBrand },
    {
      name: "SettingCompany",
      path: "/setting/company",
      component: SettingCompany,
    },
    {
      name: "SettingPlatform",
      path: "/setting/platform",
      component: SettingPlatform,
    },
  ],
});
