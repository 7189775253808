<template>
    <!-- 标题区域 -->
    <div class="toptitle-titlebox">
        <div class="toptitle-title-top">
            <div class="toptitle-title-back" v-if="showBack" @click="goBack()">
                <font-awesome-icon icon="fa-solid fa-angle-left" />
            </div>
            <div class="toptitle-title-back-blank" v-else>
            </div>
            <div class="toptitle-title-left">
                {{ title || '无题页' }}<span v-if="intro && device != 'mobile'">{{ intro }}</span>
            </div>
            <div class="toptitle-title-right">
                <!-- 右侧区域插槽 -->
                <slot name="right"></slot>
            </div>
        </div>
        <!-- 分割线 -->
        <div class="toptitle-title-line" v-if="device != 'mobile'"></div>
    </div>
</template>

<script>
// import { apiName } from '@/request/api'

export default {
    components: {

    },
    name: 'Index',
    props: {
        title: String,
        intro: String,
    },
    data() {
        return {
            loading: false,
            device: this.GLOBAL.device,
            nav: this.GLOBAL.nav,
            showBack: false,
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.showBackBtn()
        },
        showBackBtn() {
            for (let index = 0; index < this.nav.length; index++) {
                if ('/' + this.nav[index].path == this.$route.path) {
                    this.showBack = false
                    return
                }
            }
            if (this.device == 'mobile') {
                this.showBack = true
            }
        },
        goBack() {
            this.$router.go(-1)
        }
    }
}
</script>

<style scoped>
.toptitle-titlebox {
    width: 100%;
}

/* 通用标题 */

.toptitle-title-top {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.toptitle-title-back {
    text-align: center;
    height: 50px;
    line-height: 50px;
    width: 50px;
    font-size: 20px;
    padding: 0;
}

.toptitle-title-back-blank {
    width: 25px;
    height: 50px;
}

.toptitle-title-left {
    font-size: 28px;
    color: #333333;
    line-height: 40px;
    font-weight: bold;
    flex: 1;
}

.toptitle-title-left span {
    font-size: 16px;
    color: #999;
    display: inline-block;
    padding-left: 16px;
}

.toptitle-title-right {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.toptitle-title-line {
    width: 100%;
    height: 4px;
    background-color: #e9e9eb;
    margin-top: 15px
}


/* mobile */
@media screen and (max-width: 480px) {
    .toptitle-title-left {
        font-size: 17px;
    }

    .toptitle-titlebox {
        padding: 0;
        border-bottom: 1px solid #e9e9eb;
    }

    .toptitle-title-right {
        padding-right: 15px;
    }
}
</style>