<template>
    <div class="container">
        <div class="common-topbox">
            <!-- 标题区域 -->
            <Title title="快递公司设置">
                <template v-slot:right>
                    <!-- 右侧按钮区域插槽 -->
                    <div class="common-title-btn-main common-title-btn-icon" @click="openModal('add')" v-tippy
                        content="添加快递公司">
                        <font-awesome-icon icon="fa-solid fa-plus" />
                    </div>
                </template>
            </Title>
            <!-- 筛选功能 -->
            <div class="selectbox">
                <div class="select-left">
                    <div class="select-single" v-for="(item, index) in numberDate">
                        <div class="select-name">
                            {{ item.name }}
                        </div>
                        <div :class="item.important ? 'select-number active' : 'select-number'"
                            v-if="item.number && item.number > 0">
                            {{ item.number }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="common-midbox" v-if="tableData">
            <vxe-table round border highlight-hover-row resizable ref="xTable" :data="tableData.items">
                <vxe-table-column type="seq" width="60"></vxe-table-column>
                <!-- <vxe-table-column field="id" title="ID" width="60"></vxe-table-column> -->
                <vxe-table-column field="name" title="快递公司名称"></vxe-table-column>
                <vxe-table-column field="code" title="快递公司编号"></vxe-table-column>
                <vxe-table-column field="isCommon" :formatter="formatIsCommon" title="是否为常用" width="100"></vxe-table-column>
                <vxe-table-column field="remarks" title="备注"></vxe-table-column>
                <vxe-table-column title="操作" width="102">
                    <template #default="{ row }">
                        <vxe-button @click="edit(row)" circle>
                            <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                        </vxe-button>
                        <vxe-button @click="del(row)" circle>
                            <font-awesome-icon icon="fa-solid fa-trash-can" />
                        </vxe-button>
                    </template>
                </vxe-table-column>
            </vxe-table>
        </div>
        <div class="common-bottombox">
            <Pager :pageSizes="[10, 100]" :tablePage="tablePage" @handlePageChange="handlePageChange" />
        </div>
        <!-- 弹窗 -->
        <van-dialog v-model:show="modal.show" @confirm="submit()" theme="round"
            :title="modal.type == 'add' ? '添加快递公司' : '修改快递公司'" show-cancel-button confirm-button-text="提交"
            confirm-button-color="#6667ab" cancel-button-text="取消" cancel-button-color="#666">
            <div class="common-modalbox">
                <textarea class="modal-add-input" v-model="info.name" placeholder="快递公司名称"></textarea>
                <textarea class="modal-add-input" v-model="info.code" placeholder="快递公司编号"></textarea>
                <select class="modal-add-select" v-model="info.isCommon" placeholder="是否为常用">
                    <!-- Todo -->
                    <option value="1">常用</option>
                    <option value="0">不常用</option>
                </select>
                <textarea class="modal-add-textarea" v-model="info.remarks" placeholder="备注"></textarea>
            </div>
        </van-dialog>
    </div>
</template>

<script>
import Navbar from '@/components/common/navbar'
import Title from '@/components/common/title'
import Pager from '@/components/common/pager'
import { Toast, Dialog } from 'vant';
import { apiAddCompany, apiGetCompanyList, apiEditCompany, apiDelCompany } from '@/request/api'

export default {
    components: {
        Navbar,
        Title,
        Pager,
    },
    name: 'Index',
    // props: {
    //   msg: String
    // },
    data() {
        return {
            device: this.GLOBAL.device,
            loading: false,
            tableData: null,
            tablePage: {
                currentPage: 1,
                pageSize: 10,
                totalResult: 0
            },
            modal: {
                show: false,
                type: "add"
            },
            info: {
                name: null,
                code: null,
                isCommon: 1,
                remarks: null
            },
            numberDate: [
                {
                    name: "全部",
                    number: null,
                    important: false
                },
                {
                    name: "常用",
                    number: null,
                    important: true
                },
                {
                    name: "非常用",
                    number: 50,
                    important: false
                }]
        }
    },
    mounted() {
        this.init()
    },

    methods: {
        init() {
            this.getList()
        },
        getList() {
            let self = this
            apiGetCompanyList({
                page: this.tablePage.currentPage,
                size: this.tablePage.pageSize,
            }).then(res => {

                self.tableData = res.data
                self.tablePage.totalResult = res.data.count
            }).catch(err => {
                console.warn(err)
            })
        },
        handlePageChange({ currentPage, pageSize }) {
            this.tablePage.currentPage = currentPage
            this.tablePage.pageSize = pageSize
            this.getList()
        },
        openModal(type) {
            if (type == 'add') {
                this.info = {
                    name: null,
                    code: null,
                    isCommon: 1,
                    remarks: null
                }
            }
            this.modal = {
                show: true,
                type: type
            }
        },
        submit() {
            Toast.loading('提交中')
            let self = this
            if (this.modal.type == 'add') {
                // 新增
                let params;
                params = this.info
                apiAddCompany(params).then(res => {

                    Toast.clear()
                    Toast.success('添加成功');
                    self.getList()
                }).catch(err => {
                    console.warn(err)
                    Toast.clear()
                })
            } else {
                // 编辑
                let params = {};
                params = this.info
                apiEditCompany(params).then(res => {

                    Toast.clear()
                    Toast.success('修改成功');
                    self.getList()
                }).catch(err => {
                    console.warn(err)
                    Toast.clear()
                })
            }
        },
        edit(row) {
            this.info = JSON.parse(JSON.stringify(row))
            this.openModal('edit')
        },
        del(row) {
            let self = this
            Dialog.confirm({
                message: '删除' + row.name + '？',
                confirmButtonText: '删除'
            })
                .then(() => {
                    // on confirm
                    let params = JSON.parse(JSON.stringify(row))
                    apiDelCompany(params).then(res => {

                        Toast.success('删除成功');
                        self.getList()
                    }).catch(err => {
                        console.warn(err)
                    })
                })
                .catch(() => {
                    // on cancel
                });
            let params;
        },
        formatIsCommon(table) {
            let id = table.cellValue
            let result
            if (id == 1) {
                result = "常用"
            } else {
                result = "不常用"
            }
            return result
        }
    }
}
</script>

<style scoped>
/* 弹窗 手动录入快递 */
.modal-add-select {
    width: 100%;
    height: 30px;
    border-radius: 15px;
    border: 1px solid #e6e6ef;
    outline: none;
    margin-bottom: 15px;
    padding: 0 10px;
    font-size: 14px;
    color: #333;
}

.modal-add-numbers {
    width: 100%;
    height: 200px;
    border-radius: 15px;
    border: 1px solid #e6e6ef;
    outline: none;
    padding: 10px 10px;
    font-size: 14px;
    color: #333;
    resize: none;
    margin-bottom: 15px;
}

.modal-add-input {
    width: 100%;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    border: 1px solid #e6e6ef;
    outline: none;
    padding: 0 10px;
    font-size: 14px;
    color: #333;
    resize: none;
    margin-bottom: 15px;
}

.modal-add-textarea {
    width: 100%;
    height: 80px;
    border-radius: 15px;
    border: 1px solid #e6e6ef;
    outline: none;
    padding: 10px 10px;
    font-size: 14px;
    color: #333;
    resize: none;
}

/* 筛选功能 */
.selectbox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
}

.select-left {
    width: 1px;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.select-single {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #e9e9eb;
    border-right: 1px solid #fff;
    height: 32px;
    cursor: pointer;
}

.select-single:first-child {
    border-radius: 16px 0 0 16px;
    padding-left: 4px;
}

.select-single:last-child {
    border-radius: 0 16px 16px 0;
    border-right: none;
    padding-right: 4px;
}

.select-single:hover {
    opacity: 0.7;
}

.select-name {
    font-size: 14px;
    color: #666;
}

.select-number {
    font-size: 12px;
    color: #666;
    padding: 0 6px;
    height: 18px;
    line-height: 18px;
    border-radius: 9px;
}

.select-number.active {
    margin-left: 4px;
    background-color: #fd4343;
    color: #fff;
}
</style>