<template>
    <div class="content-pager">
        <vxe-pager background :current-page="tablePage.currentPage" :page-size="tablePage.pageSize"
            :total="tablePage.totalResult" :page-sizes="pageSizes" :layouts="['PrevPage', 'Number', 'NextPage', 'Sizes']"
            @page-change="handlePageChange" v-if="device != 'mobile'">
        </vxe-pager>
        <vxe-pager background :current-page="tablePage.currentPage" :page-size="tablePage.pageSize" :align="'center'"
            :total="tablePage.totalResult" :page-sizes="pageSizes" :pager-count="5"
            :layouts="['PrevPage', 'Number', 'NextPage']" @page-change="handlePageChange" v-else>
        </vxe-pager>
    </div>
</template>
  
<script>

export default {
    name: 'Index',
    props: {
        pageSizes: {
            type: Array,
            default: [10, 20, 100]
        },
        tablePage: {
            type: Object,
            default: {
                currentPage: null,
                pageSize: null,
                totalResult: null
            }
        }
    },
    data() {
        return {
            loading: false,
            device: this.GLOBAL.device,
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() { },
        handlePageChange(e) {
            console.log('handlePageChange', e)
            this.$emit('handlePageChange', e)
        }
    }
}
</script>
  
<style scoped>
/* mobile */
@media screen and (max-width: 480px) {}
</style>