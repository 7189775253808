<template>
    <div class="container-navbar" @mouseleave="resetMenu()">
        <div class="navbar-main">
            <div :class="item.id == selectMain ? 'navbar-main-link active' : 'navbar-main-link'"
                v-for="(item, index) in finalMenu" @click="changeMain(item, index)">
                <img class="icon-deafult" :src="'/oss-assets/menu/' + item.iconName" alt="">
                <img class="icon-active" :src="'/oss-assets/menu/' + item.iconNameActive" alt="">
            </div>
            <div class="navbar-main-link navbar-main-logout" @click="logout()">
                <img class="icon-deafult" src="/oss-assets/menu/icon-logout.png" alt="">
                <img class="icon-active" src="/oss-assets/menu/icon-logout-active.png" alt="">
            </div>
        </div>
        <div class="navbar-secondary" v-if="selectMainObj && selectMainObj.subMenu.length > 0">
            <div :style="'padding-top:' + selectMainIndex * 60 + 'px'">
                &nbsp;
            </div>
            <div :class="item.subId == selectSecondary ? 'navbar-secondary-link active' : 'navbar-secondary-link'"
                v-for="(item, index) in selectMainObj.subMenu" @click="changeSecondary(item)">
                <img v-if="item.icon" :src="'/oss-assets/submenu/' + item.icon + '.png'" class="navbar-secondary-icon" />{{
                    item.name }}
            </div>
        </div>
    </div>
</template>

<script>
// import { apiName } from '@/request/api'

export default {
    components: {

    },
    name: 'Index',
    props: {
        active: String
    },
    data() {
        return {
            loading: false,
            selectMain: 1,
            selectMainIndex: 0,
            selectMainObj: null,
            selectSecondary: null,
            finalMenu: [],
            device: this.GLOBAL.device,
            menu: this.GLOBAL.menu,
        }
    },
    watch: {
        $route(to, from) {
            this.resetMenu()
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.getRole()
            this.resetMenu()
        },
        resetMenu() {
            // 刷新或第一次进入时候，自动菜单恢复位置
            let nowPath = this.$route.path
            let menu = this.menu
            for (let index = 0; index < menu.length; index++) {
                if ('/' + menu[index].path == nowPath) {
                    this.selectMain = menu[index].id
                    this.selectMainIndex = index
                    this.selectMainObj = menu[index]
                } else if (menu[index].subMenu.length > 0) {
                    for (let index2 = 0; index2 < menu[index].subMenu.length; index2++) {
                        if ('/' + menu[index].subMenu[index2].path == nowPath) {
                            this.selectMain = menu[index].id
                            this.selectMainIndex = index
                            this.selectMainObj = menu[index]
                            this.selectSecondary = menu[index].subMenu[index2].subId
                        }
                    }
                }

            }
        },
        getRole() {
            let role = JSON.parse(localStorage.getItem('role'))
            let menu = this.menu
            let finalMenu = []
            for (let index2 = 0; index2 < menu.length; index2++) {
                for (let index = 0; index < role.length; index++) {
                    if (role[index] == menu[index2].path) {
                        finalMenu.push(menu[index2])
                        break
                    }
                }

            }
            this.finalMenu = finalMenu
        },
        changeMain(item, index) {
            if (item && item.subMenu.length == 0) {
                if ('/' + item.path == this.$route.path) {
                    return
                }
                this.go(item.path)
            } else {
                this.selectMain = item.id
                this.selectMainIndex = index
                this.selectMainObj = item
                this.changeSecondary(item.subMenu[0])
            }
        },
        changeSecondary(item) {
            if ('/' + item.path == this.$route.path) {
                return
            }
            this.go(item.path)
            this.selectSecondary = item.subId
        },
        logout() {
            localStorage.removeItem('token')
            this.go('')
        },
        go(where) {
            this.$router.push('/' + where)
        },
    }
}
</script>

<style scoped>
.container-navbar {
    width: auto;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    z-index: 10;
}

.navbar-main {
    width: 60px;
    height: 100%;
    /* background-image: linear-gradient(180deg, #FDB3E2 0%, #9A79CC 99%); */
    background-color: #6667ab;
    position: relative;
}

.navbar-main-link {
    width: 60px;
    height: 60px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    cursor: pointer;
    overflow: hidden;
}

.navbar-main-logout {
    position: absolute;
    bottom: 0;
    border-bottom: none;
    border-top: 1px solid rgba(255, 255, 255, 0.5);

}

/* hover */

.navbar-main-link:hover {
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
}

.navbar-main-link:hover .icon-deafult {
    display: none;
}

.navbar-main-link:hover .icon-active {
    display: block;
}

/* ative */

.navbar-main-link.active {
    background-color: rgba(255, 255, 255, 1);
    border: none;
}

.navbar-main-link.active .icon-deafult {
    display: none;
}

.navbar-main-link.active .icon-active {
    display: block;
}

.icon-deafult {
    display: block;
    width: 60px;
    height: 60px;
}

.icon-active {
    display: none;
    width: 60px;
    height: 60px;
}

.navbar-secondary {
    width: 120px;
    height: 100%;
    background-color: #fff;
}

.navbar-secondary-link {
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-size: 14px;
    text-align: center;
    color: #999;
    cursor: pointer;
}

.navbar-secondary-link:hover {
    background-color: #FBFBFB;
    color: #666;
}

.navbar-secondary-link.active {
    color: #333;
    font-weight: bold;
}

.navbar-secondary-icon {
    display: inline-block;
    width: 16px;
    margin-right: 4px;
    position: relative;
    top: 2px
}
</style>