
<template>
    <div class="container-consumables">
        <div class="common-topbox">
            <!-- 标题区域 -->
            <Title title="耗材">

                <template v-slot:right>
                    <div class="common-title-btn-main"
                        @click="openUrl('https://eweime.notion.site/a1bf6a02e3b84e6dafa9de39a32c8415')">
                        打开网页
                    </div>
                </template>
            </Title>
        </div>

        <div class="common-midbox">
            <!-- 点击打开网页 -->
            点击右上角打开
        </div>
    </div>
</template>
  
<script>
import Clipboard from 'clipboard'
import Title from '@/components/common/title'
import Pager from '@/components/common/pager'
import { Toast, Dialog } from 'vant';
import { apiAddDarkroom, apiDarkroomList, apiDelDarkroom, apiEditDarkroom } from '@/request/api'

export default {
    components: {
        Title,
        Pager,
    },
    name: 'Index',
    // props: {
    //   msg: String
    // },
    data() {
        return {
            device: this.GLOBAL.device,
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {

        },
        openUrl(url) {
            window.open(url)
        },
    }
}
</script>
  
<style scoped>
.container-consumables {
    width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
}
</style>