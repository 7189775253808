import { get, post, put, del } from "./http";

// ****** 登录 ****** //
// 登录
export const apiLogin = (params) => post("/v1/user/login", params);

// ****** 基础 ****** //
//字典
export const apiGetConstant = (params) => get("/v1/constant", params);
// export const apiGetConstantRoles = params => get('/v1/constant/roles', params);
// export const apiGetConstantStatus = params => get('/v1/constant/status', params);
// export const apiGetConstantIsCommon = params => get('/v1/constant/isCommon', params);
// export const apiGetConstantLevel = params => get('/v1/constant/level', params);

// ****** 快递 ****** //
// List筛选：
// status=801 状态
// flag=all 标记
export const apiGetExpressList = (params) => get("/v1/express/ticket", params);
export const apiGetNumber = (params) => get("/v1/express/ticket/sum", params);
export const apiGetFlagNumber = (params) => get("/v1/express/flag/sum", params);
export const apiGetReturnNumber = (params) =>
  get("/v1/express/return/sum", params);
export const apiAddExpress = (params) => post("/v1/express/ticket", params);
export const apiEditExpress = (params) =>
  put("/v1/express/ticket/" + params.id, params);
export const apiStopExpress = (params) =>
  put("/v1/express/ticket/complete/" + params.id, params);

// 打包 pack
export const apiGetPackList = (params) => get("/v1/pack", params);
export const apiAddPack = (params) => post("/v1/pack", params);

// 刷新快递
export const apiReflashExpress = (params) =>
  put("/v1/express/ticket/refresh/" + params.id);

// 退件入库
export const apiAddNeedReturn = (params) => post("/v1/express/return", params);
export const apiAddReturnPacket = (params) =>
  post("/v1/express/inStock", params);

// 单号查询
export const apiProvList = (params) => get("/v1/express/prov", params);
export const apiEditProv = (params) =>
  put("/v1/express/prov/" + params.code, params);

// 查询发货照片
export const apiGetExpressPhoto = (params) => get("/v1/express/images", params);

// ****** 设置 ****** //
// 成员管理
export const apiAddUser = (params) => post("/v1/manage/users/add", params);
export const apiGetMyInfo = (params) => get("/v1/user/my", params);
export const apiGetUserList = (params) => get("/v1/manage/users", params);
export const apiEditUser = (params) =>
  put("/v1/manage/users/" + params.id, params);
export const apiEnableUserRoles = (params) =>
  put("/v1/manage/users/" + params.id + "/enable", params);
export const apiDisableUserRoles = (params) =>
  put("/v1/manage/users/" + params.id + "/disable", params);

// 快递公司
export const apiGetCompanyList = (params) => get("/v1/manage/company", params);
export const apiAddCompany = (params) => post("/v1/manage/company", params);
export const apiEditCompany = (params) =>
  put("/v1/manage/company/" + params.id, params);
export const apiDelCompany = (params) => del("/v1/manage/company/" + params.id);

// 品牌
export const apiGetBrandList = (params) => get("/v1/manage/brand", params);
export const apiAddBrand = (params) => post("/v1/manage/brand", params);
export const apiEditBrand = (params) =>
  put("/v1/manage/brand/" + params.id, params);
export const apiDelBrand = (params) => del("/v1/manage/brand/" + params.id);

// 平台
export const apiGetPlatformList = (params) =>
  get("/v1/manage/platform", params);
export const apiAddPlatform = (params) => post("/v1/manage/platform", params);
export const apiEditPlatform = (params) =>
  put("/v1/manage/platform/" + params.id, params);
export const apiDelPlatform = (params) =>
  del("/v1/manage/platform/" + params.id);

// 工具
export const apiDarkroomList = (params) => get("/v1/darkroom", params);
export const apiAddDarkroom = (params) => post("/v1/darkroom", params);
export const apiEditDarkroom = (params) =>
  put("/v1/darkroom/" + params.id, params);
export const apiDelDarkroom = (params) => del("/v1/darkroom/" + params.id);

// 例子
// export const apiAddress = params => post('api/v1/users/my_address/address_edit_before', params);
// import { apiAddress } from '@/request/api';// 导入我们的api接口
// export default {
//     name: 'Address',
//     created () {
//         this.onLoad();
//     },
//     methods: {
//         // 获取数据
//         onLoad() {
//             // 调用api接口，并且提供了两个参数
//             apiAddress({
//                 type: 0,
//                 sort: 1
//             }).then(res => {
//                 // 获取数据成功后的其他操作
//                 ………………
//             })
//         }
//     }
// }
