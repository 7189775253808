<template>
    <div class="container">
        <div class="common-topbox">
            <!-- 标题区域 -->
            <Title title="物流数据">
                <template v-slot:right>
                    <!-- 右侧按钮区域插槽 -->
                    <div class="common-title-btn-secondary common-title-btn-icon" @click="reflash()" v-tippy
                        content="刷新数据">
                        <font-awesome-icon icon="fa-solid fa-arrow-rotate-right" />
                    </div>
                </template>
            </Title>
        </div>
        <!-- <div class="content-table" v-show="tableData"> -->
        <div class="common-midbox">
            <!-- 概况 -->
            <div class="index-title">
                运送中的快递
            </div>
            <div class="number-wrap">
                <div class="number-box" @click="go('express/express?status=801')">
                    <div class="number-title">
                        待揽收
                    </div>
                    <div class="number-value">
                        <span v-if="expNumber.status801 == 0" class="number-value-0">0</span>
                        <span v-else-if="expNumber.status801 > 0" class="number-value-normal">{{
                        expNumber.status801
                    }}</span>
                        <span v-else class="number-value-loading">...</span>
                    </div>
                    <!-- 占比线 -->
                    <div class="number-water"
                        :style="'height:' + (expNumber.status801 / expNumber.expressAll * 100) + '%'" />
                </div>
                <div class="number-box" @click="go('express/express?status=802')">
                    <div class="number-title">
                        已揽收
                    </div>
                    <div class="number-value">
                        <span v-if="expNumber.status802 == 0" class="number-value-0">0</span>
                        <span v-else-if="expNumber.status802 > 0" class="number-value-normal">{{
                        expNumber.status802
                    }}</span>
                        <span v-else class="number-value-loading">...</span>
                    </div>
                    <!-- 占比线 -->
                    <div class="number-water"
                        :style="'height:' + (expNumber.status802 / expNumber.expressAll * 100) + '%'" />
                </div>
                <div class="number-box" @click="go('express/express?status=803')">
                    <div class="number-title">
                        省内运输中
                    </div>
                    <div class="number-value">
                        <span v-if="expNumber.status803 == 0" class="number-value-0">0</span>
                        <span v-else-if="expNumber.status803 > 0" class="number-value-normal">{{
                        expNumber.status803
                    }}</span>
                        <span v-else class="number-value-loading">...</span>
                    </div>
                    <div class="number-water"
                        :style="'height:' + (expNumber.status803 / expNumber.expressAll * 100) + '%'" />
                </div>
                <div class="number-box" @click="go('express/express?status=804')">
                    <div class="number-title">
                        运输中
                    </div>
                    <div class="number-value">
                        <span v-if="expNumber.status804 == 0" class="number-value-0">0</span>
                        <span v-else-if="expNumber.status804 > 0" class="number-value-normal">{{
                        expNumber.status804
                    }}</span>
                        <span v-else class="number-value-loading">...</span>
                    </div>
                    <!-- 占比线 -->
                    <div class="number-water"
                        :style="'height:' + (expNumber.status804 / expNumber.expressAll * 100) + '%'" />
                </div>
                <div class="number-box" @click="go('express/express?status=805')">
                    <div class="number-title">
                        派件中
                    </div>
                    <div class="number-value">
                        <span v-if="expNumber.status805 == 0" class="number-value-0">0</span>
                        <span v-else-if="expNumber.status805 > 0" class="number-value-normal">{{
                        expNumber.status805
                    }}</span>
                        <span v-else class="number-value-loading">...</span>
                    </div>
                    <div class="number-water"
                        :style="'height:' + (expNumber.status805 / expNumber.expressAll * 100) + '%'" />
                </div>
                <div class="number-box" @click="go('express/express?status=806')">
                    <div class="number-title">
                        到代收点
                    </div>
                    <div class="number-value">
                        <span v-if="expNumber.status806 == 0" class="number-value-0">0</span>
                        <span v-else-if="expNumber.status806 > 0" class="number-value-normal">{{
                        expNumber.status806
                    }}</span>
                        <span v-else class="number-value-loading">...</span>
                    </div>
                    <div class="number-water"
                        :style="'height:' + (expNumber.status806 / expNumber.expressAll * 100) + '%'" />
                </div>
            </div>
            <div class="index-title">
                需关注的快递
            </div>
            <div class="number-wrap">
                <div class="number-box" @click="go('express/express?status=808')">
                    <div class="number-title">
                        退回中
                    </div>
                    <div class="number-value">
                        <span v-if="expNumber.status808 == 0" class="number-value-0">0</span>
                        <span v-else-if="expNumber.status808 > 0" class="number-value-normal">{{
                        expNumber.status808
                    }}</span>
                        <span v-else class="number-value-loading">...</span>
                    </div>
                </div>
                <div class="number-box" @click="go('express/express?status=810')">
                    <div class="number-title">
                        疑难件
                    </div>
                    <div class="number-value">
                        <span v-if="expNumber.status810 == 0" class="number-value-0">0</span>
                        <span v-else-if="expNumber.status810 > 0" class="number-value-important">{{
                        expNumber.status810
                    }}</span>
                        <span v-else class="number-value-loading">...</span>
                    </div>
                </div>
                <div class="number-box" @click="go('express/express?status=811')">
                    <div class="number-title">
                        查询异常
                    </div>
                    <div class="number-value">
                        <span v-if="expNumber.status811 == 0" class="number-value-0">0</span>
                        <span v-else-if="expNumber.status811 > 0" class="number-value-important">{{
                        expNumber.status811
                    }}</span>
                        <span v-else class="number-value-loading">...</span>
                    </div>
                </div>
                <div class="number-box" @click="go('express/important')">
                    <div class="number-title">
                        运输超48小时
                    </div>
                    <div class="number-value">
                        <span v-if="expNumber.over48 == 0" class="number-value-0">0</span>
                        <span v-else-if="expNumber.over48 > 0" class="number-value-important">{{
                        expNumber.over48
                    }}</span>
                        <span v-else class="number-value-loading">...</span>
                    </div>
                </div>
                <div class="number-box" @click="go('express/flag')">
                    <div class="number-title">
                        Flag
                    </div>
                    <div class="number-value">
                        <span v-if="expNumber.flag == 0" class="number-value-0">0</span>
                        <span v-else-if="expNumber.flag > 0" class="number-value-normal">{{
                        expNumber.flag
                    }}</span>
                        <span v-else class="number-value-loading">...</span>
                    </div>
                </div>
                <div class="number-box" @click="go('express/return?status=701')">
                    <div class="number-title">
                        需退回的快递
                    </div>
                    <div class="number-value">
                        <span v-if="expNumber.needReturn == 0" class="number-value-0">0</span>
                        <span v-else-if="expNumber.needReturn > 0" class="number-value-important">{{
                        expNumber.needReturn
                    }}</span>
                        <span v-else class="number-value-loading">...</span>
                    </div>
                </div>
                <div class="number-box" @click="go('express/return?status=704')">
                    <div class="number-title">
                        退件已入库
                    </div>
                    <div class="number-value">
                        <span v-if="expNumber.return == 0" class="number-value-0">0</span>
                        <span v-else-if="expNumber.return > 0" class="number-value-normal">{{
                        expNumber.return
                    }}</span>
                        <span v-else class="number-value-loading">...</span>
                    </div>
                </div>
            </div>
            <!-- 近4个月快递签收量 -->
            <div class="index-title" v-if="showTotalNumber">
                近6个月快递签收量
            </div>
            <div class="number-wrap" v-if="showTotalNumber">
                <!-- 0 -->
                <div class="number-box" @click="go('express/express?status=808')">
                    <div class="number-title">
                        {{ NearExpNumber.month0name || '...' }}
                    </div>
                    <div class="number-value">
                        <span v-if="NearExpNumber.month0value == 0" class="number-value-0">0</span>
                        <span v-else-if="NearExpNumber.month0value > 0" class="number-value-normal">
                            {{ NearExpNumber.month0value }}
                        </span>
                        <span v-else class="number-value-loading">...</span>
                    </div>
                    <div class="number-water number-water-yellow"
                        :style="'height:' + (NearExpNumber.month0value / NearExpNumber.theBig * 100) + '%'" />
                </div>
                <!-- 1 -->
                <div class="number-box" @click="go('express/express?status=808')">
                    <div class="number-title">
                        {{ NearExpNumber.month1name || '...' }}
                    </div>
                    <div class="number-value">
                        <span v-if="NearExpNumber.month1value == 0" class="number-value-0">0</span>
                        <span v-else-if="NearExpNumber.month1value > 0" class="number-value-normal">
                            {{ NearExpNumber.month1value }}
                        </span>
                        <span v-else class="number-value-loading">...</span>
                    </div>
                    <div class="number-water"
                        :style="'height:' + (NearExpNumber.month1value / NearExpNumber.theBig * 100) + '%'" />
                </div>
                <!-- 2 -->
                <div class="number-box" @click="go('express/express?status=808')">
                    <div class="number-title">
                        {{ NearExpNumber.month2name || '...' }}
                    </div>
                    <div class="number-value">
                        <span v-if="NearExpNumber.month2value == 0" class="number-value-0">0</span>
                        <span v-else-if="NearExpNumber.month2value > 0" class="number-value-normal">
                            {{ NearExpNumber.month2value }}
                        </span>
                        <span v-else class="number-value-loading">...</span>
                    </div>
                    <div class="number-water"
                        :style="'height:' + (NearExpNumber.month2value / NearExpNumber.theBig * 100) + '%'" />
                </div>
                <!-- 3 -->
                <div class="number-box" @click="go('express/express?status=808')">
                    <div class="number-title">
                        {{ NearExpNumber.month3name || '...' }}
                    </div>
                    <div class="number-value">
                        <span v-if="NearExpNumber.month3value == 0" class="number-value-0">0</span>
                        <span v-else-if="NearExpNumber.month3value > 0" class="number-value-normal">
                            {{ NearExpNumber.month3value }}
                        </span>
                        <span v-else class="number-value-loading">...</span>
                    </div>
                    <div class="number-water"
                        :style="'height:' + (NearExpNumber.month3value / NearExpNumber.theBig * 100) + '%'" />
                </div>
                <!-- 4 -->
                <div class="number-box" @click="go('express/express?status=808')">
                    <div class="number-title">
                        {{ NearExpNumber.month4name || '...' }}
                    </div>
                    <div class="number-value">
                        <span v-if="NearExpNumber.month4value == 0" class="number-value-0">0</span>
                        <span v-else-if="NearExpNumber.month4value > 0" class="number-value-normal">
                            {{ NearExpNumber.month4value }}
                        </span>
                        <span v-else class="number-value-loading">...</span>
                    </div>
                    <div class="number-water"
                        :style="'height:' + (NearExpNumber.month4value / NearExpNumber.theBig * 100) + '%'" />
                </div>
                <!-- 5 -->
                <div class="number-box" @click="go('express/express?status=808')">
                    <div class="number-title">
                        {{ NearExpNumber.month5name || '...' }}
                    </div>
                    <div class="number-value">
                        <span v-if="NearExpNumber.month5value == 0" class="number-value-0">0</span>
                        <span v-else-if="NearExpNumber.month5value > 0" class="number-value-normal">
                            {{ NearExpNumber.month5value }}
                        </span>
                        <span v-else class="number-value-loading">...</span>
                    </div>
                    <div class="number-water"
                        :style="'height:' + (NearExpNumber.month5value / NearExpNumber.theBig * 100) + '%'" />
                </div>

            </div>
            <!-- 春节 -->
            <!-- <div class="index-title">
                2023春节的快递概况 1.22-2.1 <span v-if="CNYExpNumber.expressAll">（共 {{ CNYExpNumber.expressAll }} 件快递）</span>
            </div>
            <div class="number-wrap number-wrap-CNY">
                <div class="number-box" @click="go('express/cny2023?status=801')">
                    <div class="number-title">
                        待揽收
                    </div>
                    <div class="number-value">
                        <span v-if="CNYExpNumber.status801 == 0" class="number-value-0">0</span>
                        <span v-else-if="CNYExpNumber.status801 > 0" class="number-value-normal">{{
                            CNYExpNumber.status801
                        }}</span>
                        <span v-else class="number-value-loading">...</span>
                    </div>
            <div class="number-water" :style="'height:' + (CNYExpNumber.status801 / CNYExpNumber.expressAll * 100) + '%'" />
        </div>
        <div class="number-box" @click="go('express/cny2023?status=802')">
            <div class="number-title">
                已揽收
            </div>
            <div class="number-value">
                <span v-if="CNYExpNumber.status802 == 0" class="number-value-0">0</span>
                <span v-else-if="CNYExpNumber.status802 > 0" class="number-value-normal">{{
                    CNYExpNumber.status802
                }}</span>
                <span v-else class="number-value-loading">...</span>
            </div>
            <div class="number-water" :style="'height:' + (CNYExpNumber.status802 / CNYExpNumber.expressAll * 100) + '%'" />
        </div>
        <div class="number-box" @click="go('express/cny2023?status=803')">
            <div class="number-title">
                省内运输中
            </div>
            <div class="number-value">
                <span v-if="CNYExpNumber.status803 == 0" class="number-value-0">0</span>
                <span v-else-if="CNYExpNumber.status803 > 0" class="number-value-normal">{{
                    CNYExpNumber.status803
                }}</span>
                <span v-else class="number-value-loading">...</span>
            </div>
            <div class="number-water" :style="'height:' + (CNYExpNumber.status803 / CNYExpNumber.expressAll * 100) + '%'" />
        </div>
        <div class="number-box" @click="go('express/cny2023?status=804')">
            <div class="number-title">
                运输中
            </div>
            <div class="number-value">
                <span v-if="CNYExpNumber.status804 == 0" class="number-value-0">0</span>
                <span v-else-if="CNYExpNumber.status804 > 0" class="number-value-normal">{{
                    CNYExpNumber.status804
                }}</span>
                <span v-else class="number-value-loading">...</span>
            </div>
            <div class="number-water" :style="'height:' + (CNYExpNumber.status804 / CNYExpNumber.expressAll * 100) + '%'" />
        </div>
        <div class="number-box" @click="go('express/cny2023?status=805')">
            <div class="number-title">
                派件中
            </div>
            <div class="number-value">
                <span v-if="CNYExpNumber.status805 == 0" class="number-value-0">0</span>
                <span v-else-if="CNYExpNumber.status805 > 0" class="number-value-normal">{{
                    CNYExpNumber.status805
                }}</span>
                <span v-else class="number-value-loading">...</span>
            </div>
            <div class="number-water" :style="'height:' + (CNYExpNumber.status805 / CNYExpNumber.expressAll * 100) + '%'" />
        </div>
        <div class="number-box" @click="go('express/cny2023?status=806')">
            <div class="number-title">
                到代收点
            </div>
            <div class="number-value">
                <span v-if="CNYExpNumber.status806 == 0" class="number-value-0">0</span>
                <span v-else-if="CNYExpNumber.status806 > 0" class="number-value-normal">{{
                    CNYExpNumber.status806
                }}</span>
                <span v-else class="number-value-loading">...</span>
            </div>
            <div class="number-water" :style="'height:' + (CNYExpNumber.status806 / CNYExpNumber.expressAll * 100) + '%'" />
        </div>
        <div class="number-box" @click="go('express/cny2023?status=807')">
            <div class="number-title">
                已签收
            </div>
            <div class="number-value">
                <span v-if="CNYExpNumber.status807 == 0" class="number-value-0">0</span>
                <span v-else-if="CNYExpNumber.status807 > 0" class="number-value-success">{{
                    CNYExpNumber.status807
                }}</span>
                <span v-else class="number-value-loading">...</span>
            </div>
            <div class="number-water" :style="'height:' + (CNYExpNumber.status807 / CNYExpNumber.expressAll * 100) + '%'" />
        </div>
        <div class="number-box" @click="go('express/cny2023?status=808')">
            <div class="number-title">
                退回中
            </div>
            <div class="number-value">
                <span v-if="CNYExpNumber.status808 == 0" class="number-value-0">0</span>
                <span v-else-if="CNYExpNumber.status808 > 0" class="number-value-important">{{
                    CNYExpNumber.status808
                }}</span>
                <span v-else class="number-value-loading">...</span>
            </div>
            <div class="number-water" :style="'height:' + (CNYExpNumber.status808 / CNYExpNumber.expressAll * 100) + '%'" />
        </div>
        <div class="number-box" @click="go('express/cny2023?status=809')">
            <div class="number-title">
                已退回
            </div>
            <div class="number-value">
                <span v-if="CNYExpNumber.status809 == 0" class="number-value-0">0</span>
                <span v-else-if="CNYExpNumber.status809 > 0" class="number-value-important">{{
                    CNYExpNumber.status809
                }}</span>
                <span v-else class="number-value-loading">...</span>
            </div>
            <div class="number-water" :style="'height:' + (CNYExpNumber.status809 / CNYExpNumber.expressAll * 100) + '%'" />
        </div>
        <div class="number-box" @click="go('express/cny2023?status=810')">
            <div class="number-title">
                疑难件
            </div>
            <div class="number-value">
                <span v-if="CNYExpNumber.status810 == 0" class="number-value-0">0</span>
                <span v-else-if="CNYExpNumber.status810 > 0" class="number-value-important">{{
                    CNYExpNumber.status810
                }}</span>
                <span v-else class="number-value-loading">...</span>
            </div>
            <div class="number-water" :style="'height:' + (CNYExpNumber.status810 / CNYExpNumber.expressAll * 100) + '%'" />
        </div>
        <div class="number-box" @click="go('express/cny2023?status=811')">
            <div class="number-title">
                查询异常
            </div>
            <div class="number-value">
                <span v-if="CNYExpNumber.status811 == 0" class="number-value-0">0</span>
                <span v-else-if="CNYExpNumber.status811 > 0" class="number-value-important">{{
                    CNYExpNumber.status811
                }}</span>
                <span v-else class="number-value-loading">...</span>
            </div>
            <div class="number-water" :style="'height:' + (CNYExpNumber.status811 / CNYExpNumber.expressAll * 100) + '%'" />
        </div>
    </div> -->
            <!-- 快递送达率 -->
            <div class="index-title">
                两周快递签收率（21天前-7天前）
            </div>
            <div class="number-wrap">
                <div class="number-box">
                    <div class="number-title">
                        中通
                    </div>
                    <div class="number-value">
                        <span v-if="exPressNumber.zt30daySuccess == 0" class="number-value-0">0</span>
                        <span v-else-if="exPressNumber.zt30daySuccess > 0" class="number-value-normal">{{
                        (exPressNumber.zt30daySuccess / exPressNumber.zt30dayTotal * 100).toFixed(1)
                    }}<span class="number-value-pre">%</span></span>
                        <span v-else class="number-value-loading">...</span>
                    </div>
                </div>
                <div class="number-box">
                    <div class="number-title">
                        韵达
                    </div>
                    <div class="number-value">
                        <span v-if="exPressNumber.yunda30daySuccess == 0" class="number-value-0">0</span>
                        <span v-else-if="exPressNumber.yunda30daySuccess > 0" class="number-value-normal">{{
                        (exPressNumber.yunda30daySuccess / exPressNumber.yunda30dayTotal * 100).toFixed(1)
                    }}<span class="number-value-pre">%</span></span>
                        <span v-else class="number-value-loading">...</span>
                    </div>
                </div>
                <div class="number-box">
                    <div class="number-title">
                        圆通
                    </div>
                    <div class="number-value">
                        <span v-if="exPressNumber.yt30daySuccess == 0" class="number-value-0">0</span>
                        <span v-else-if="exPressNumber.yt30daySuccess > 0" class="number-value-normal">{{
                        (exPressNumber.yt30daySuccess / exPressNumber.yt30dayTotal * 100).toFixed(1)
                    }}<span class="number-value-pre">%</span></span>
                        <span v-else class="number-value-loading">...</span>
                    </div>
                </div>
                <div class="number-box">
                    <div class="number-title">
                        顺丰
                    </div>
                    <div class="number-value">
                        <span v-if="exPressNumber.sf30daySuccess == 0" class="number-value-0">0</span>
                        <span v-else-if="exPressNumber.sf30daySuccess > 0" class="number-value-normal">{{
                            (exPressNumber.sf30daySuccess / exPressNumber.sf30dayTotal * 100).toFixed(1)
                            }}<span class="number-value-pre">%</span></span>
                        <span v-else class="number-value-loading">...</span>
                    </div>
                </div>
            </div>

            <!-- end -->
        </div>
    </div>
</template>

<script>
    import Navbar from '@/components/common/navbar'
    import Title from '@/components/common/title'
    import { Toast, Dialog } from 'vant';
    import { apiGetNumber, apiGetReturnNumber } from '@/request/api'

    export default {
        components: {
            Navbar,
            Title,
        },
        name: 'Index',
        // props: {
        //   msg: String
        // },
        data() {
            return {
                loading: false,
                companyList: null,
                statusCode: [],
                showTotalNumber: false,
                expNumber: {
                    all: null, // 全部
                    expressAll: null, // 全部运输中
                    status801: null, // 待揽收
                    status802: null, // 已揽收
                    status803: null, // 省内运输中
                    status804: null, // 运输中
                    status805: null, // 派件中
                    status806: null, // 到代收点
                    status807: null, // 已签收
                    status808: null, // 退回中
                    status809: null, // 已退回
                    status810: null, // 疑难件
                    status811: null, // 查询异常
                    over48: null, // 超过48小的运输中的快递
                    flag: null, // 有Flag
                    needReturn: null, // 需退回的快递
                    return: null, // 退件已入库
                },
                NearExpNumber: {
                    month0name: null,
                    month0value: null,
                    month1name: null,
                    month1value: null,
                    month2name: null,
                    month2value: null,
                    month3name: null,
                    month3value: null,
                    month4name: null,
                    month4value: null,
                    month5name: null,
                    month5value: null,
                    theBig: 20000,
                },
                CNYExpNumber: {
                    all: null, // 全部
                    expressAll: null, // 全部运输中
                    status801: null, // 待揽收
                    status802: null, // 已揽收
                    status803: null, // 省内运输中
                    status804: null, // 运输中
                    status805: null, // 派件中
                    status806: null, // 到代收点
                    status807: null, // 已签收
                    status808: null, // 退回中
                    status809: null, // 已退回
                    status810: null, // 疑难件
                    status811: null, // 查询异常
                },
                exPressNumber: {
                    yunda30daySuccess: null,
                    yunda30dayTotal: null,
                    zt30daySuccess: null,
                    zt30dayTotal: null,
                    yt30daySuccess: null,
                    yt30dayTotal: null,
                    sf30daySuccess: null,
                    sf30dayTotal: null,
                },
                tableTooltipConfig: {
                    showAll: true,
                    enterable: true,
                },
                device: this.GLOBAL.device,
            }
        },
        mounted() {
            this.init()
        },

        methods: {
            init() {
                this.getCompanyList()
                this.getStatusCode()
                this.getRule()
            },
            getRule() {
                let nickname = localStorage.getItem('nickname')
                let allowNick = ["eWei", "小舞"]
                if (allowNick.indexOf(nickname) > -1) {
                    this.showTotalNumber = true
                }
            },
            go(where) {
                this.$router.push('/' + where)
            },
            reflash() {
                this.expNumber = {
                    all: null, // 全部
                    expressAll: null, // 全部运输中
                    status801: null, // 待揽收
                    status802: null, // 已揽收
                    status803: null, // 省内运输中
                    status804: null, // 运输中
                    status805: null, // 派件中
                    status806: null, // 到代收点
                    status807: null, // 已签收
                    status808: null, // 退回中
                    status809: null, // 已退回
                    status810: null, // 疑难件
                    status811: null, // 查询异常
                    over48: null, // 超过48小的运输中的快递
                    flag: null, // 有Flag
                    needReturn: null, // 需退回的快递
                    return: null, // 退件已入库
                }
                this.CNYExpNumber = {
                    all: null, // 全部
                    expressAll: null, // 全部运输中
                    status801: null, // 待揽收
                    status802: null, // 已揽收
                    status803: null, // 省内运输中
                    status804: null, // 运输中
                    status805: null, // 派件中
                    status806: null, // 到代收点
                    status807: null, // 已签收
                    status808: null, // 退回中
                    status809: null, // 已退回
                    status810: null, // 疑难件
                    status811: null, // 查询异常
                }
                this.AfterCNYExpNumber = {
                    all: null, // 全部
                    expressAll: null, // 全部运输中
                    status801: null, // 待揽收
                    status802: null, // 已揽收
                    status803: null, // 省内运输中
                    status804: null, // 运输中
                    status805: null, // 派件中
                    status806: null, // 到代收点
                    status807: null, // 已签收
                    status808: null, // 退回中
                    status809: null, // 已退回
                    status810: null, // 疑难件
                    status811: null, // 查询异常
                }
                this.exPressNumber = {
                    yunda30daySuccess: null, // 韵达总单量
                    yunda30dayTotal: null, // 韵达送达单量
                    zt30daySuccess: null, // 中通总单量
                    zt30dayTotal: null, // 中通送达单量
                    sf30daySuccess: null, // 顺丰总单量
                    sf30dayTotal: null, // 顺丰送达单量
                }
                this.NearExpNumber = {
                    month0name: null,
                    month0value: null,
                    month1name: null,
                    month1value: null,
                    month2name: null,
                    month2value: null,
                    month3name: null,
                    month3value: null,
                    month4name: null,
                    month4value: null,
                    month5name: null,
                    month5value: null,
                    theBig: null,
                },
                    this.getStatusCode()
            },
            getCompanyList() {
                this.companyList = JSON.parse(localStorage.getItem('companyList'))
            },
            getStatusCode() {
                this.statusCode = JSON.parse(localStorage.getItem('constantStatusCode'))
                this.getNumber1()
                this.getNumber2()
                this.getNumber3()
                this.getNumber4()
                // this.getNumberCNY()
                this.getNumberYunda()
                this.getNumberZT()
                this.getNumberYT()
                this.getNumberSF()
                this.getNumberNear()
            },
            // 正常状态的快递
            getNumber1() {
                let self = this
                let params = {}
                apiGetNumber(params).then(res => {
                    let data = res.data
                    self.expNumber.all = data[801] + data[802] + data[803] + data[804] + data[805] + data[806] + data[807] + data[808] + data[809] + data[810] + data[811]
                    self.expNumber.expressAll = data[801] + data[802] + data[803] + data[804] + data[805] + data[806]
                    self.expNumber.status801 = data[801]
                    self.expNumber.status802 = data[802]
                    self.expNumber.status803 = data[803]
                    self.expNumber.status804 = data[804]
                    self.expNumber.status805 = data[805]
                    self.expNumber.status806 = data[806]
                    self.expNumber.status807 = data[807]
                    self.expNumber.status808 = data[808]
                    self.expNumber.status809 = data[809]
                    self.expNumber.status810 = data[810]
                    self.expNumber.status811 = data[811]
                }).catch(err => {
                    console.warn(err)
                })
            },
            getNumberNear() {
                let self = this
                let params0 = {}
                let params1 = {}
                let params2 = {}
                let params3 = {}
                let params4 = {}
                let params5 = {}
                // js获取当前月的第一天和最后一天
                let now = new Date()
                let year = now.getFullYear()
                let month = now.getMonth() + 1
                let day = now.getDate()
                let month0 = month - 0
                let month1 = month - 1
                let month2 = month - 2
                let month3 = month - 3
                let month4 = month - 4
                let month5 = month - 5
                let year0 = year
                let year1 = year
                let year2 = year
                let year3 = year
                let year4 = year
                let year5 = year
                if (month0 <= 0) {
                    month0 = month0 + 12
                    year0 = year - 1
                }
                if (month1 <= 0) {
                    month1 = month1 + 12
                    year1 = year - 1
                }
                if (month2 <= 0) {
                    month2 = month2 + 12
                    year2 = year - 1
                }
                if (month3 <= 0) {
                    month3 = month3 + 12
                    year3 = year - 1
                }
                if (month4 <= 0) {
                    month4 = month4 + 12
                    year4 = year - 1
                }
                if (month5 <= 0) {
                    month5 = month5 + 12
                    year5 = year - 1
                }
                let month0firstDay = new Date(year0, month0 - 1, 1)
                let month0lastDay = new Date(year0, month0, 0)
                let month1firstDay = new Date(year1, month1 - 1, 1)
                let month1lastDay = new Date(year1, month1, 0)
                let month2firstDay = new Date(year2, month2 - 1, 1)
                let month2lastDay = new Date(year2, month2, 0)
                let month3firstDay = new Date(year3, month3 - 1, 1)
                let month3lastDay = new Date(year3, month3, 0)
                let month4firstDay = new Date(year4, month4 - 1, 1)
                let month4lastDay = new Date(year4, month4, 0)
                let month5firstDay = new Date(year5, month5 - 1, 1)
                let month5lastDay = new Date(year5, month5, 0)
                let month0firstDayTimestamp = Math.round(month0firstDay.getTime() / 1000)
                let month0lastDayTimestamp = Math.round(month0lastDay.getTime() / 1000)
                let month1firstDayTimestamp = Math.round(month1firstDay.getTime() / 1000)
                let month1lastDayTimestamp = Math.round(month1lastDay.getTime() / 1000)
                let month2firstDayTimestamp = Math.round(month2firstDay.getTime() / 1000)
                let month2lastDayTimestamp = Math.round(month2lastDay.getTime() / 1000)
                let month3firstDayTimestamp = Math.round(month3firstDay.getTime() / 1000)
                let month3lastDayTimestamp = Math.round(month3lastDay.getTime() / 1000)
                let month4firstDayTimestamp = Math.round(month4firstDay.getTime() / 1000)
                let month4lastDayTimestamp = Math.round(month4lastDay.getTime() / 1000)
                let month5firstDayTimestamp = Math.round(month5firstDay.getTime() / 1000)
                let month5lastDayTimestamp = Math.round(month5lastDay.getTime() / 1000)


                // 0
                params0.createTimeFrom = month0firstDayTimestamp
                params0.createTimeTo = month0lastDayTimestamp
                apiGetNumber(params0).then(res => {
                    let data = res.data
                    self.NearExpNumber.month0name = month0 + '月'
                    self.NearExpNumber.month0value = data[807]
                }).catch(err => {
                    console.warn(err)
                })
                // 1
                params1.createTimeFrom = month1firstDayTimestamp
                params1.createTimeTo = month1lastDayTimestamp
                apiGetNumber(params1).then(res => {
                    let data = res.data
                    self.NearExpNumber.month1name = month1 + '月'
                    self.NearExpNumber.month1value = data[807]
                }).catch(err => {
                    console.warn(err)
                })
                // 2
                params2.createTimeFrom = month2firstDayTimestamp
                params2.createTimeTo = month2lastDayTimestamp
                apiGetNumber(params2).then(res => {
                    let data = res.data
                    self.NearExpNumber.month2name = month2 + '月'
                    self.NearExpNumber.month2value = data[807]
                }).catch(err => {
                    console.warn(err)
                })
                // 3
                params3.createTimeFrom = month3firstDayTimestamp
                params3.createTimeTo = month3lastDayTimestamp
                apiGetNumber(params3).then(res => {
                    let data = res.data
                    self.NearExpNumber.month3name = month3 + '月'
                    self.NearExpNumber.month3value = data[807]
                }).catch(err => {
                    console.warn(err)
                })
                // 4
                params4.createTimeFrom = month4firstDayTimestamp
                params4.createTimeTo = month4lastDayTimestamp
                apiGetNumber(params4).then(res => {
                    let data = res.data
                    self.NearExpNumber.month4name = month4 + '月'
                    self.NearExpNumber.month4value = data[807]
                }).catch(err => {
                    console.warn(err)
                })
                // 5
                params5.createTimeFrom = month5firstDayTimestamp
                params5.createTimeTo = month5lastDayTimestamp
                apiGetNumber(params5).then(res => {
                    let data = res.data
                    self.NearExpNumber.month5name = month5 + '月'
                    self.NearExpNumber.month5value = data[807]
                }).catch(err => {
                    console.warn(err)
                })
                // 查找value中最大的一个
                setTimeout(() => {
                    this.findTheBig()
                }, 5000);
            },
            findTheBig() {
                let theBig = Math.max(this.NearExpNumber.month0value, this.NearExpNumber.month1value, this.NearExpNumber.month2value, this.NearExpNumber.month3value, this.NearExpNumber.month4value, this.NearExpNumber.month5value)
                if (theBig > 20000) {
                    this.NearExpNumber.theBig = theBig
                }
            },
            // 春节
            getNumberCNY() {
                let self = this
                let params = {}
                params.createTimeTo = 1675267199
                params.createTimeFrom = 1674316800
                apiGetNumber(params).then(res => {
                    let data = res.data
                    self.CNYExpNumber.all = data[801] + data[802] + data[803] + data[804] + data[805] + data[806] + data[807] + data[808] + data[809] + data[810] + data[811]
                    self.CNYExpNumber.expressAll = data[801] + data[802] + data[803] + data[804] + data[805] + data[806] + data[807] + data[808] + data[809] + data[810] + data[811]
                    self.CNYExpNumber.status801 = data[801]
                    self.CNYExpNumber.status802 = data[802]
                    self.CNYExpNumber.status803 = data[803]
                    self.CNYExpNumber.status804 = data[804]
                    self.CNYExpNumber.status805 = data[805]
                    self.CNYExpNumber.status806 = data[806]
                    self.CNYExpNumber.status807 = data[807]
                    self.CNYExpNumber.status808 = data[808]
                    self.CNYExpNumber.status809 = data[809]
                    self.CNYExpNumber.status810 = data[810]
                    self.CNYExpNumber.status811 = data[811]
                }).catch(err => {
                    console.warn(err)
                })
            },
            // 超48小时的快递
            getNumber2() {
                let self = this
                let params = {}
                let timestamp = Math.round(new Date().getTime() / 1000)
                params.kdLastTimeTo = timestamp - 48 * 60 * 60
                apiGetNumber(params).then(res => {
                    let data = res.data
                    self.expNumber.over48 = data[801] + data[802] + data[803] + data[804] + data[805] + data[806]
                }).catch(err => {
                    console.warn(err)
                })
            },
            // 有Flag的快递
            getNumber3() {
                let self = this
                let params = {}
                params.flag = "all"
                apiGetNumber(params).then(res => {
                    let data = res.data
                    self.expNumber.flag = data[801] + data[802] + data[803] + data[804] + data[805] + data[806] + data[807] + data[808] + data[809] + data[810] + data[811]
                }).catch(err => {
                    console.warn(err)
                })
            },
            // 已入库退件
            getNumber4() {
                let self = this
                let params = {}
                apiGetReturnNumber(params).then(res => {
                    let data = res.data
                    self.expNumber.needReturn = data[701]
                    self.expNumber.return = data[704]
                }).catch(err => {
                    console.warn(err)
                })
            },
            // 韵达
            getNumberYunda() {
                let self = this
                let params = {}
                let now = Date.parse(new Date()) / 1000
                let day7 = 60 * 60 * 24 * 7
                let day21 = 60 * 60 * 24 * 21
                params.com = 'yunda'
                params.createTimeFrom = now - day21
                params.createTimeTo = now - day7
                apiGetNumber(params).then(res => {
                    let data = res.data
                    self.exPressNumber.yunda30dayTotal = data[801] + data[802] + data[803] + data[804] + data[805] + data[806] + data[807] + data[808] + data[809] + data[810] + data[811]
                    self.exPressNumber.yunda30daySuccess = data[807]
                }).catch(err => {
                    console.warn(err)
                })
            },
            // 中通
            getNumberZT() {
                let self = this
                let params = {}
                let now = Date.parse(new Date()) / 1000
                let day7 = 1000 * 60 * 24 * 7
                let day21 = 1000 * 60 * 24 * 21
                params.com = 'zhongtong'
                params.createTimeFrom = now - day21
                params.createTimeTo = now - day7
                apiGetNumber(params).then(res => {
                    let data = res.data
                    self.exPressNumber.zt30dayTotal = data[801] + data[802] + data[803] + data[804] + data[805] + data[806] + data[807] + data[808] + data[809] + data[810] + data[811]
                    self.exPressNumber.zt30daySuccess = data[807]
                }).catch(err => {
                    console.warn(err)
                })
            },
            // 圆通
            getNumberYT() {
                let self = this
                let params = {}
                let now = Date.parse(new Date()) / 1000
                let day7 = 1000 * 60 * 24 * 7
                let day21 = 1000 * 60 * 24 * 14
                params.com = 'yuantong'
                params.createTimeFrom = now - day21
                params.createTimeTo = now - day7
                apiGetNumber(params).then(res => {
                    let data = res.data
                    console.log(data)
                    self.exPressNumber.yt30dayTotal = data[801] + data[802] + data[803] + data[804] + data[805] + data[806] + data[807] + data[808] + data[809] + data[810] + data[811]
                    self.exPressNumber.yt30daySuccess = data[807]
                }).catch(err => {
                    console.warn(err)
                })
            },
            // 顺丰
            getNumberSF() {
                let self = this
                let params = {}
                let now = Date.parse(new Date()) / 1000
                let day7 = 1000 * 60 * 24 * 7
                let day21 = 1000 * 60 * 24 * 21
                params.com = 'shunfeng'
                params.createTimeFrom = now - day21
                params.createTimeTo = now - day7
                apiGetNumber(params).then(res => {
                    let data = res.data
                    self.exPressNumber.sf30dayTotal = data[801] + data[802] + data[803] + data[804] + data[805] + data[806] + data[807] + data[808] + data[809] + data[810] + data[811]
                    self.exPressNumber.sf30daySuccess = data[807]
                }).catch(err => {
                    console.warn(err)
                })
            },

        }
    }
</script>

<style scoped>
    .index-title {
        width: 100%;
        font-weight: bold;
        font-size: 18px;
        color: #333;
        margin-top: 20px;
        margin-bottom: 15px;
        padding-left: 5px;
    }

    .index-title:first-child {
        margin-top: 0;
    }

    /* 数字区域 */

    .number-wrap {
        width: 100%;
        overflow-x: auto;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
    }


    .number-wrap:last-child {
        margin-bottom: 0;
    }

    .number-box {
        min-width: 150px;
        padding: 15px 20px;
        background-color: #fff;
        border-top: 2px solid #e9e9eb;
        border-bottom: 2px solid #e9e9eb;
        border-left: 2px solid #e9e9eb;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .number-box:hover {
        background-color: #f5f5f7;
    }

    .number-box:first-child {
        border-radius: 10px 0 0 10px;
    }

    .number-box:last-child {
        border-radius: 0 10px 10px 0;
        border-right: 2px solid #e9e9eb;
    }

    .number-water {
        width: 100%;
        height: 0%;
        background-color: #b8a3ed;
        ;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 0;
    }

    .number-wrap-CNY .number-water {
        background-color: #F9BB44;
    }


    .number-wrap-AfterCNY .number-water {
        background-color: rgb(117, 230, 236);
    }

    .number-water-yellow {
        background-color: #F9BB44;
    }

    .number-title {
        font-size: 14px;
        line-height: 1.5;
        z-index: 10;
        position: relative;
    }

    .number-value {
        font-size: 40px;
        line-height: 1.5;
        font-weight: bold;
        z-index: 10;
        position: relative;
    }

    .number-value-0 {
        color: #ccc;
    }

    .number-value-loading {
        color: #ccc;
    }

    .number-value-normal {
        color: #000;
    }

    .number-value-important {
        color: #fd4343;
    }

    .number-value-success {
        color: #66C255;
    }

    .number-value-pre {
        display: inline-block;
        font-size: 16px;
        margin-left: 4px;
    }
</style>