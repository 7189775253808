<template>
    <div class="container">
        <div class="common-topbox">
            <!-- 标题区域 -->
            <Title title="超48小时" intro="未更新物流信息">
                <template v-slot:right>
                    <!-- 右侧按钮区域插槽 -->
                    <div class="common-title-btn-secondary common-title-btn-icon copy" :data-clipboard-text="numbers"
                        v-tippy content="复制本页单号">
                        <font-awesome-icon icon="fa-solid fa-copy" />
                    </div>
                    <div class="common-title-btn-secondary common-title-btn-icon" @click="reflashAll()"
                        v-if="selectedCode != 807 && selectedCode != 808 && selectedCode != 809 && selectedCode != 810"
                        v-tippy content="更新本页物流">
                        <font-awesome-icon icon="fa-solid fa-arrow-rotate-right" />
                    </div>
                </template>
            </Title>
            <!-- 筛选 -->
            <FilterExpress :expNumber="expNumber" :statusCode="statusCode" :filterValue="filterValue"
                :selectedCode="selectedCode" :showFilterBtn="true" @changeStatus="changeStatus" @submitFilter="submitFilter"
                @resetFilter="resetFilter" />
        </div>
        <!-- 表格 -->
        <ExpressTable :tableData="tableData" v-if="tableData" />
        <div class="common-bottombox">
            <Pager :pageSizes="[16, 50, 100, 500]" :tablePage="tablePage" @handlePageChange="handlePageChange" />
        </div>
    </div>
</template>

<script>
import Clipboard from 'clipboard'
import Navbar from '@/components/common/navbar'
import ExpressTable from '@/components/express/expressTable'
import Title from '@/components/common/title'
import FilterExpress from '@/components/express/filterExpress'
import Pager from '@/components/common/pager'
import { Toast, Dialog } from 'vant';
import { apiGetExpressList, apiGetNumber, apiReflashExpress } from '@/request/api'

export default {
    components: {
        Navbar,
        ExpressTable,
        Title,
        FilterExpress,
        Pager,
    },
    name: 'Index',
    // props: {
    //   msg: String
    // },
    data() {
        return {
            loading: false,
            tableData: null,
            tablePage: {
                currentPage: 1,
                pageSize: 100,
                totalResult: 0
            },
            showFilterModal: false,
            companyList: null,
            brandList: null,
            platformList: null,
            filterValue: {
                com: null,
                platformId: null,
                brandId: null,
                overTime: 48,
            },
            statusCode: [],
            selectedCode: 804,
            expNumber: {
                all: null,
                unComplete: null,
                complete: null,
                pai: null,
            },
            numbers: null,
            device: this.GLOBAL.device,
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.initClipboard()
            this.getList()
            this.getCompanyList()
            this.getBrandList()
            this.getStatusCode()
            this.getPlatformList()
        },
        getList() {
            let self = this
            let params = {}
            params.page = this.tablePage.currentPage
            params.size = this.tablePage.pageSize
            if (this.selectedCode) {
                params.status = this.selectedCode
            }
            if (this.filterValue.com) {
                params.com = this.filterValue.com
            }
            if (this.filterValue.platformId) {
                params.platformId = this.filterValue.platformId
            }
            if (this.filterValue.brandId) {
                params.brandId = this.filterValue.brandId
            }
            if (this.filterValue.overTime) {
                let timestamp = Math.round(new Date().getTime() / 1000)
                params.kdLastTimeTo = timestamp - this.filterValue.overTime * 60 * 60
            }
            apiGetExpressList(params).then(res => {

                self.tableData = res.data.items
                self.tablePage.totalResult = res.data.count
                Toast.clear()
                self.getNumbers()
            }).catch(err => {
                console.warn(err)
                Toast.clear()
            })
        },
        submitFilter() {
            this.tablePage.currentPage = 1
            this.getList()
            this.getNumber()
        },
        resetFilter() {
            this.tablePage.currentPage = 1
            this.filterValue = {
                com: null,
                platformId: null,
                brandId: null,
                overTime: 48,
            }
            this.getList()
            this.getNumber()
        },
        getCompanyList() {
            this.companyList = JSON.parse(localStorage.getItem('companyList'))
        },
        getBrandList() {
            this.brandList = JSON.parse(localStorage.getItem('brandList'))
        },
        getPlatformList() {
            this.platformList = JSON.parse(localStorage.getItem('platformList'))
        },
        getStatusCode() {
            this.statusCode = JSON.parse(localStorage.getItem('constantStatusCode'))
            this.getNumber()
        },
        getNumber() {
            let self = this
            let params = {}
            if (this.filterValue.platformId) {
                params.platformId = this.filterValue.platformId
            }
            if (this.filterValue.com) {
                params.com = this.filterValue.com
            }
            if (this.filterValue.brandId) {
                params.brandId = this.filterValue.brandId
            }
            if (this.filterValue.overTime) {
                let timestamp = Math.round(new Date().getTime() / 1000)
                params.kdLastTimeTo = timestamp - this.filterValue.overTime * 60 * 60
            }
            apiGetNumber(params).then(res => {
                self.updateStatusCode(res.data)
                self.getAllNumber(res.data)
            }).catch(err => {
                console.warn(err)
            })
        },
        perFormate(number) {
            return parseInt(number)
        },
        updateStatusCode(data) {
            let statusCodeNew = JSON.parse(JSON.stringify(this.statusCode))
            for (let index = 0; index < statusCodeNew.length; index++) {
                statusCodeNew[index].number = data[statusCodeNew[index].key]
                let importantStatus = "801,802,803,808,810,811"
                if (importantStatus.indexOf(statusCodeNew[index].key) > -1) {
                    statusCodeNew[index].important = true
                }
            }
            this.statusCode = statusCodeNew
        },
        getAllNumber(data) {
            this.expNumber.all = data[801] + data[802] + data[803] + data[804] + data[805] + data[806] + data[807] + data[808] + data[809] + data[810] + data[811]
            this.expNumber.unComplete = data[801] + data[802] + data[803] + data[804] + data[805] + data[806] + data[808] + data[809] + data[810] + data[811]
            this.expNumber.complete = data[807]
            this.expNumber.pai = data[805] + data[806]
        },
        handlePageChange({ currentPage, pageSize }) {
            this.tablePage.currentPage = currentPage
            this.tablePage.pageSize = pageSize
            this.getList()
        },
        openFilterModal() {
            this.showFilterModal = true
        },
        reflashAll() {
            Toast.loading('刷新中')
            let data = JSON.parse(JSON.stringify(this.tableData))
            // for (let index = 0; index < data.length; index++) {
            //     this.reflashOne(data[index])
            // }
            let promiseArr = []
            data.forEach((item, index) => {
                promiseArr.push((async (item) => {
                    await this.reflashOne(item, index)
                })(item))
            })
            Promise.all(promiseArr).then(itemList => {
                Toast.clear()
                Toast.success('全部刷新完成');
                this.getList()
            })
        },
        reflashOne(oneData, index) {
            let self = this
            return new Promise((resolve, reject) => {
                apiReflashExpress(oneData).then(res => {
                    // 
                    Toast.loading('正在刷新 ' + index);
                    resolve('成功')
                }).catch(err => {
                    console.warn(err)
                    reject('失败')
                })
            });
        },
        getNumbers() {
            let result = ""
            for (let index = 0; index < this.tableData.length; index++) {
                result = result + '\n' + this.tableData[index].number
            }
            this.numbers = result
        },
        initClipboard() {
            const clipboard = new Clipboard(".copy");
            clipboard.on("success", function (e) {
                Toast.success('已复制到剪贴板');
                e.clearSelection();
            });
            clipboard.on("error", function (e) {
                Toast.fail('复制失败');
            });
        },
        changeStatus(id) {
            this.selectedCode = id
            this.tablePage.currentPage = 1
            this.getList()
            this.getNumber()
        },
    }
}
</script>

<style scoped>
/* 筛选功能 */
.selectbox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
}

.select-left {
    width: 1px;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.select-single {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #e9e9eb;
    color: #666;
    border-right: 1px solid #fff;
    height: 32px;
    cursor: pointer;
    padding: 0 6px;
}

.select-single:first-child {
    border-radius: 16px 0 0 16px;
    padding-left: 4px;
}

.select-single:last-child {
    border-radius: 0 16px 16px 0;
    border-right: none;
    padding-right: 4px;
}

.select-single:hover {
    opacity: 0.7;
}

.select-single.active {
    background-color: #333;
    color: #fff;
}

.select-name {
    font-size: 14px;
    word-break: keep-all;
    white-space: nowrap;
}

.select-number {
    margin-left: 3px;
    font-size: 12px;
    background-color: #e9e9eb;
    font-weight: bold;
    color: #666;
    padding: 0 4px;
    height: 18px;
    min-width: 18px;
    text-align: center;
    line-height: 18px;
    border-radius: 9px;
    word-break: keep-all;
    white-space: nowrap;
}

.select-number.active {
    background-color: #fd4343;
    color: #fff;
}

/* 选择时间 */

.select-more {
    width: 120px;
    margin-left: 10px;
    height: 32px;
    border-radius: 16px;
    padding: 0 8px;
    border: none;
    background-color: #e9e9eb;
    color: #666;
    outline: none;
    cursor: pointer;
    /* appearance:none; */
}

/* 筛选按钮 */
.select-right {
    margin-left: 6px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 16px;
    font-size: 14px;
    background-color: #e9e9eb;
    color: #666;
    outline: none;
    cursor: pointer;
    text-align: center;
    position: relative;
}

.select-right.active {
    background-color: #fd4343;
    color: #fff;

}

/* 筛选框 */
.common-modalbox {
    padding-left: 20px;
}

.modal-add-checkbox {
    margin-bottom: 15px;
}

.modal-add-checkbox-title {
    margin-bottom: 10px;
    font-size: 14px;
    color: #666;
}

.modal-add-checkbox-option {
    margin-bottom: 6px;
    font-size: 14px;
}

/* 筛选 */
.select-tips {
    position: absolute;
    bottom: 35px;
    right: 0px;
    background-color: rgba(0, 0, 0, .5);
    padding: 0 15px;
    font-size: 12px;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    white-space: nowrap;
    word-break: keep-all;
    animation: myanime 0.6s ease;
}

@keyframes myanime {
    0% {
        transform: scale(0, 0)rotate(10deg)translateX(50%)translateY(50%)
            /* transform: scale(0,0) rotate(30deg) translate(50px -10px) */
    }

    60% {
        transform: scale(1.2, 1.2)rotate(-2deg)translateX(-10%)translateY(-10%)
    }

    100% {
        transform: scale(1, 1)rotate(0)translateX(0)translateY(0)
            /* transform: scale(1,1)  rotate(0deg) translate(0 0) */
    }
}

.select-tips-box {
    display: inline-block;
    color: #fff;
}

.select-tips-box:last-child .select-tips-point {
    display: none;
    color: #fff;
}
</style>