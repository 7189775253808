<template>
    <div class="content-filter">
        <!-- 筛选功能 -->
        <div class="selectbox">
            <!-- PC -->
            <div class="select-left">
                <!-- <div class="select-left" v-if="device != 'mobile'"> -->
                <div :class="selectedCode == null ? 'select-single active' : 'select-single'" @click="changeStatus(null)">
                    <div class="select-name">
                        全部
                    </div>
                </div>
                <div :class="selectedCode == item.key ? 'select-single active' : 'select-single'"
                    v-for="(item, index) in statusCodeNew" @click="changeStatus(item.key)">
                    <div class="select-name">
                        {{ item.value }}
                    </div>
                    <div :class="item.important ? 'select-number active' : 'select-number'"
                        v-if="item.key != 807 && item.number && item.number > 0">
                        {{ item.number }}
                    </div>
                </div>
            </div>
            <!-- Mobile -->
            <!-- <div v-if="device == 'mobile'" class="select-right" @click="openFilterModal()">
                <font-awesome-icon icon="fa-solid fa-filter" />
                <div class="select-tips-mobile">
                    <span class="select-tips-box" v-if="selectedCode == null">全部状态
                        <span class="select-tips-point">，</span>
                    </span>
                    <span class="select-tips-box" v-for="(item, index) in statusCode" v-if="selectedCode == item.key">{{
                        item.value }}
                        <span class="select-tips-point">，</span>
                    </span>
                    <span class="select-tips-box" v-for="(item, index) in companyList"
                        v-if="filterValue.com && item.code == filterValue.com">{{ item.name }}
                        <span class="select-tips-point">，</span>
                    </span>
                    <span class="select-tips-box" v-for="(item, index) in brandList"
                        v-if="filterValue.brandId && item.id == filterValue.brandId">{{ item.name }}
                        <span class="select-tips-point">，</span>
                    </span>
                    <span class="select-tips-box" v-for="(item, index) in platformList"
                        v-if="filterValue.platformId && item.id == filterValue.platformId">{{ item.name }}
                        <span class="select-tips-point">，</span>
                    </span>
                    <span class="select-tips-box" v-if="filterValue.overTime">超{{ filterValue.overTime }}小时
                        <span class="select-tips-point">，</span>
                    </span>
                </div>
            </div> -->
            <!-- PC -->
            <div v-if="showFilterBtn"
                :class="filterValue.com || filterValue.platformId || filterValue.brandId || filterValue.overTime ? 'select-right active' : 'select-right'"
                @click="openFilterModal()" v-tippy content="筛选">
                <font-awesome-icon icon="fa-solid fa-filter" />
                <div class="select-tips"
                    v-if="(filterValue.com || filterValue.platformId || filterValue.brandId || filterValue.overTime) && !showFilterModal">
                    <span class="select-tips-box">筛选：</span>
                    <span class="select-tips-box" v-for="(item, index) in companyList"
                        v-if="filterValue.com && item.code == filterValue.com">{{ item.name }}
                        <span class="select-tips-point">，</span>
                    </span>
                    <span class="select-tips-box" v-for="(item, index) in brandList"
                        v-if="filterValue.brandId && item.id == filterValue.brandId">{{ item.name }}
                        <span class="select-tips-point">，</span>
                    </span>
                    <span class="select-tips-box" v-for="(item, index) in platformList"
                        v-if="filterValue.platformId && item.id == filterValue.platformId">{{ item.name }}
                        <span class="select-tips-point">，</span>
                    </span>
                    <span class="select-tips-box" v-if="filterValue.overTime">超{{ filterValue.overTime }}小时
                        <span class="select-tips-point">，</span>
                    </span>
                </div>
            </div>
        </div>

        <!-- 弹窗 筛选 -->
        <van-dialog v-model:show="showFilterModal" width="360" @confirm="submitFilter()" @cancel="resetFilter()"
            theme="round" title="筛选" show-cancel-button confirm-button-text="确定" confirm-button-color="#6667ab"
            cancel-button-text="重置" cancel-button-color="#666">
            <div class="common-modalbox">
                <!-- <div class="modal-add-checkbox" v-if="device == 'mobile'">
                    <div class="modal-add-checkbox-title">
                        状态 {{ selectedMobile }}
                    </div>
                    <van-radio-group v-model="selectedMobile" direction="horizontal">
                        <van-radio :name="null" class="modal-add-checkbox-option">全部状态</van-radio>
                        <van-radio class="modal-add-checkbox-option" :name="item.key" v-for="(item, index) in statusCode"
                            :key="item.key">{{ item.value }}</van-radio>
                    </van-radio-group>
                </div> -->
                <div class="modal-add-checkbox">
                    <div class="modal-add-checkbox-title">
                        快递公司
                    </div>
                    <van-radio-group v-model="filterValue.com" direction="horizontal">
                        <van-radio :name="null" class="modal-add-checkbox-option">全部快递</van-radio>
                        <van-radio class="modal-add-checkbox-option" :name="item.code" v-for="(item, index) in companyList"
                            :key="item.code">{{ item.name }}</van-radio>
                    </van-radio-group>
                </div>
                <div class="modal-add-checkbox">
                    <div class="modal-add-checkbox-title">
                        品牌
                    </div>
                    <van-radio-group v-model="filterValue.brandId" direction="horizontal">
                        <van-radio :name="null" class="modal-add-checkbox-option">全部品牌</van-radio>
                        <van-radio class="modal-add-checkbox-option" :name="item.id" v-for="(item, index) in brandList"
                            :key="item.id">{{ item.name }}</van-radio>
                    </van-radio-group>
                </div>
                <div class="modal-add-checkbox">
                    <div class="modal-add-checkbox-title">
                        平台
                    </div>
                    <van-radio-group v-model="filterValue.platformId" direction="horizontal">
                        <van-radio :name="null" class="modal-add-checkbox-option">全部平台</van-radio>
                        <van-radio class="modal-add-checkbox-option" :name="item.id" v-for="(item, index) in platformList"
                            :key="item.id">{{ item.name }}</van-radio>
                    </van-radio-group>
                </div>
                <div class="modal-add-checkbox">
                    <div class="modal-add-checkbox-title">
                        物流停滞超过
                    </div>
                    <van-radio-group v-model="filterValue.overTime" direction="horizontal">
                        <van-radio :name="null" class="modal-add-checkbox-option">不限</van-radio>
                        <van-radio name="12" class="modal-add-checkbox-option">12小时</van-radio>
                        <van-radio name="24" class="modal-add-checkbox-option">24小时</van-radio>
                        <van-radio name="36" class="modal-add-checkbox-option">36小时</van-radio>
                        <van-radio name="48" class="modal-add-checkbox-option">2天</van-radio>
                        <van-radio name="72" class="modal-add-checkbox-option">3天</van-radio>
                        <van-radio name="96" class="modal-add-checkbox-option">4天</van-radio>
                        <van-radio name="120" class="modal-add-checkbox-option">5天</van-radio>
                        <van-radio name="144" class="modal-add-checkbox-option">6天</van-radio>
                        <van-radio name="168" class="modal-add-checkbox-option">7天</van-radio>
                    </van-radio-group>
                </div>
            </div>
        </van-dialog>
    </div>
</template>

<script>

export default {
    name: 'expressTable',
    components: {
    },
    props: {
        statusCode: {
            type: Array,
            default: () => {
                return []
            },
        },
        selectedCode: [String, Number],
        filterValue: {
            type: Object,
            default: () => {
                return {
                    com: null,
                    platformId: null,
                    brandId: null,
                    overTime: null
                }
            }

        },
        expNumber: Object,
        showFilterBtn: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        statusCode: {
            handler(newValue) {
                this.statusCodeNew = newValue.map(item => ({ ...item })); // 创建新对象，复制所有属性
            },
            immediate: true,
            deep: true
        }
    },
    data() {
        return {
            device: this.GLOBAL.device,
            showFilterModal: false,
            companyList: null,
            brandList: null,
            platformList: null,
            selectedMobile: null,
            statusCodeNew: [],
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.statusCodeNew = JSON.parse(JSON.stringify(this.statusCode))
            if (this.selectedCode != null) {
                this.selectedMobile = JSON.parse(JSON.stringify(this.selectedCode)) + ''
            }
            this.getCompanyList()
            this.getBrandList()
            this.getPlatformList()
        },
        getCompanyList() {
            this.companyList = JSON.parse(localStorage.getItem('companyList'))
        },
        getBrandList() {
            this.brandList = JSON.parse(localStorage.getItem('brandList'))
        },
        getPlatformList() {
            this.platformList = JSON.parse(localStorage.getItem('platformList'))
        },
        changeStatus(code) {
            this.$emit('changeStatus', code)
        },
        perFormate(number) {
            return parseInt(number)
        },
        openFilterModal() {
            this.showFilterModal = true
        },
        submitFilter() {
            if (this.device === 'mobile') {
                this.changeStatus(this.selectedMobile)
            }
            this.showFilterModal = false
            this.$emit('submitFilter', this.filterValue)
        },
        resetFilter() {
            if (this.device === 'mobile') {
                this.selectedMobile = null
                this.changeStatus(this.selectedMobile)
            }
            this.$emit('resetFilter', null)
        },
    }
}
</script>

<style scoped>
/* 筛选功能 */
.selectbox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 15px;
}

.select-left {
    width: 1px;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
    overflow-y: visible;

}

.select-single {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #e9e9eb;
    color: #666;
    border-right: 1px solid #fff;
    height: 32px;
    cursor: pointer;
    padding: 0 8px;
}

.select-single:first-child {
    border-radius: 16px 0 0 16px;
    padding-left: 12px;
}

.select-single:last-child {
    border-radius: 0 16px 16px 0;
    border-right: none;
    padding-right: 12px;
}

.select-single:hover {
    opacity: 0.7;
}

.select-single.active {
    background-color: #000;
    color: #fff;
}

.select-name {
    font-size: 14px;
    word-break: keep-all;
    white-space: nowrap;
}

.select-number {
    margin-left: 3px;
    font-size: 12px;
    background-color: #e9e9eb;
    font-weight: bold;
    color: #666;
    padding: 0 4px;
    height: 18px;
    min-width: 18px;
    text-align: center;
    line-height: 18px;
    border-radius: 9px;
    word-break: keep-all;
    white-space: nowrap;
}

.select-number.active {
    background-color: #fd4343;
    color: #fff;
}

/* 选择时间 */

.select-more {
    width: 120px;
    margin-left: 10px;
    height: 32px;
    border-radius: 16px;
    padding: 0 8px;
    border: none;
    background-color: #e9e9eb;
    color: #666;
    outline: none;
    cursor: pointer;
    /* appearance:none; */
}

/* 筛选按钮 */
.select-right {
    margin-left: 6px;
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 16px;
    font-size: 14px;
    background-color: #e9e9eb;
    color: #666;
    outline: none;
    cursor: pointer;
    text-align: center;
    position: relative;
}

.select-right.active {
    background-color: #fd4343;
    color: #fff;

}

/* 筛选框 */
.common-modalbox {
    padding-left: 20px;
}

.modal-add-checkbox {
    margin-bottom: 15px;
}

.modal-add-checkbox-title {
    margin-bottom: 10px;
    font-size: 14px;
    color: #666;
}

.modal-add-checkbox-option {
    margin-bottom: 6px;
    font-size: 14px;
}

/* 筛选 */
.select-tips {
    position: absolute;
    bottom: 35px;
    right: 0px;
    background-color: rgba(0, 0, 0, .5);
    padding: 0 15px;
    font-size: 12px;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    white-space: nowrap;
    word-break: keep-all;
    animation: myanime 0.6s ease;
}

@keyframes myanime {
    0% {
        transform: scale(0, 0)rotate(10deg)translateX(50%)translateY(50%)
            /* transform: scale(0,0) rotate(30deg) translate(50px -10px) */
    }

    60% {
        transform: scale(1.2, 1.2)rotate(-2deg)translateX(-10%)translateY(-10%)
    }

    100% {
        transform: scale(1, 1)rotate(0)translateX(0)translateY(0)
            /* transform: scale(1,1)  rotate(0deg) translate(0 0) */
    }
}

.select-tips-box {
    display: inline-block;
    color: #fff;
}

.select-tips-box:last-child .select-tips-point {
    display: none;
}

/* mobile */
@media screen and (max-width: 480px) {
    .selectbox {
        padding: 0 15px;
    }

    .select-tips-box {
        display: inline-block;
        color: #000;
    }

    .select-tips-mobile {
        padding-left: 10px;
        font-size: 12px;
        color: #000;

    }
}
</style>