<template>
    <div class="container-badgeImage">
        <div class="common-topbox">
            <!-- 标题区域 -->
            <Title title="订货图生成工具">
                <template v-slot:right>
                    <!-- 右侧按钮区域插槽 -->
                    <div class="common-title-btn-main upload-btn">
                        <div class="upload-text">上传文件夹</div>
                        <input class="upload-input" type="file" @change="upload($event)" style="width: 320px;"
                            webkitdirectory directory multiple>
                    </div>
                    <div class="common-title-btn-secondary" @click="downloadImg()" v-if="fileTree">
                        下载图片
                    </div>
                </template>
            </Title>
        </div>
        <div class="common-midbox">
            <!-- <div class="treeBox-blank" v-if="!fileTree" @drop.prevent="handleDrop" @dragover.prevent> -->
            <div class="treeBox-blank" v-if="!fileTree">
                <!-- 拖动这个区域上传文件 -->
                <!-- 请先点击右上角上传，或将文件夹拖拽到这里 -->
                请先点击右上角上传
            </div>
            <div class="treeBox" v-if="fileTree">
                <!-- 循环 -->
                <div class="treeBox-wrap" v-for="itemA in fileTree" id="printMe">
                    <!-- 大标题 -->
                    <div class="tree-titlebox">
                        <!-- <FileView :item='itemA' /> -->
                        {{ itemA.name }}
                    </div>
                    <!-- 循环结束 -->
                    <!-- 类别 -->
                    <div :class="itemB.type == 'folder' ? 'tree-folder' : 'tree-file'" v-for="itemB in itemA.children"
                        v-if="itemA.children && itemA.children.length > 0">
                        <FileView :item='itemB' />
                        <!-- 系列 -->
                        <div :class="itemC.type == 'folder' ? 'tree-folder together' : 'tree-file together'"
                            v-for="itemC in itemB.children" v-if="itemB.children && itemB.children.length > 0">
                            <FileView :item='itemC' />
                            <!-- 款式文件/款式文件夹 -->
                            <div :class="itemD.type == 'folder' ? 'tree-folder together' : 'tree-file together'"
                                v-for="itemD in itemC.children" v-if="itemC.children && itemC.children.length > 0">
                                <FileView :item='itemD' />
                                <div :class="itemE.type == 'folder' ? 'tree-folder together' : 'tree-file together'"
                                    v-for="itemE in itemD.children" v-if="itemD.children && itemD.children.length > 0">
                                    <FileView :item='itemE' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="treeBox-tip" v-if="fileTree">
                        * 本文件仅用于核对款式数量，不可用于核对色彩准确度。
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from '@/components/common/navbar'
import Title from '@/components/common/title'
import FileView from '@/components/badge/fileView'
import PSD from "psd.js"
import { Toast, Dialog } from 'vant';
import download from "downloadjs"
import html2canvas from 'html2canvas'

export default {
    components: {
        Navbar,
        Title,
        FileView
    },
    name: 'Index',
    // props: {
    //   msg: String
    // },
    data() {
        return {
            device: this.GLOBAL.device,
            loading: false,
            randomTwoLetters: null,
            fileTree: null,
            imgsrc: null,
            loadStatus: {
                all: 0,
                complete: 0,
            },
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
         },
        // handleDrop(event) {
        //     // this.uploadFile(event.dataTransfer.files)

        //     let items = event.dataTransfer.items;
        //     for (let i = 0; i < items.length; i++) {
        //         let item = items[i].webkitGetAsEntry();
        //         if (item) {
        //             if (item.isDirectory) {
        //                 this.uploadFile(item);
        //             }
        //         }
        //     }
        // },
        getRandomTwoLetters() {
            console.log("==========getRandomTwoLetters")
            // 创建一个包含所有大写英文字母的数组
            const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            // 随机选择两个字母
            const letter1 = letters.charAt(Math.floor(Math.random() * letters.length));
            const letter2 = letters.charAt(Math.floor(Math.random() * letters.length));
            // 将两个字母组合起来
            this.randomTwoLetters = letter1 + letter2;
            console.log(this.randomTwoLetters)
        },
        upload(event) {
            this.uploadFile(event.target.files)
        },
        uploadFile(files) {
            console.log("===========files")
            console.log(files)
            const fileTree = null;
            this.loadStatus.all = 0
            this.loadStatus.complete = 0
            this.buildFileTree(files).then(res => {
                this.fileTree = res
                Toast.clear()
            })
            this.getRandomTwoLetters()
            // this.fileTree = tree
        },
        async buildFileTree(files) {
            let self = this
            const fileTree = [];
            let fileLength = files.length
            this.loadStatus.all = fileLength
            for (let i = 0; i < files.length; i++) {
                let file = files[i];
                const path = file.webkitRelativePath.split('/');
                let currentLevel = fileTree;
                for (let j = 0; j < path.length; j++) {
                    let part = path[j];
                    const existingPath = currentLevel.filter(item => item.name === part);
                    if (existingPath.length > 0) {
                        currentLevel = existingPath[0].children;
                    } else {
                        // 单个文件处理
                        let newPart = {
                            name: part,
                            type: j === path.length - 1 ? 'file' : 'folder',
                            originType: file.type,
                            originPath: file.webkitRelativePath,
                            originFile: file,
                            src: URL.createObjectURL(file),
                            children: [],
                            index: i,
                        }
                        // 文件处理
                        if (newPart.type == 'file') {
                            // loading
                            self.loadStatus.complete++
                            Toast.loading('生成中 ' + self.loadStatus.complete + '/' + self.loadStatus.all);
                            // PSD
                            if (newPart.name.indexOf('.psd') > -1) {
                                let base64
                                await this.loadPSD(newPart.src).then(res => {
                                    // newPart.src = res
                                    base64 = res
                                })
                                newPart.src = await this.resizedataURL(base64, 360);
                            }
                            // jpeg png
                            if (newPart.name.indexOf('.jpg') > -1 || newPart.name.indexOf('.png') > -1) {
                                newPart.index = 999
                            }

                        }
                        // 
                        // 排除 .DS_Store 文件
                        if (newPart.name != ".DS_Store") {
                            currentLevel.push(newPart);
                            currentLevel = newPart.children;
                        }
                    }
                }
            }
            // 按照index 排序
            // fileTree.forEach(itemA => {
            //     if (itemA.children && itemA.children.length > 0) {
            //         itemA.children.sort((a, b) => {
            //             return a.index - b.index
            //         })
            //         itemA.children.forEach(itemB => {
            //             if (itemB.children && itemB.children.length > 0) {
            //                 itemB.children.sort((a, b) => {
            //                     return a.index - b.index
            //                 })
            //                 itemB.children.forEach(itemC => {
            //                     if (itemC.children && itemC.children.length > 0) {
            //                         itemC.children.sort((a, b) => {
            //                             return a.index - b.index
            //                         })
            //                         itemC.children.forEach(itemD => {
            //                             if (itemD.children && itemD.children.length > 0) {
            //                                 itemD.children.sort((a, b) => {
            //                                     return a.index - b.index
            //                                 })
            //                                 itemD.children.forEach(itemE => {
            //                                     if (itemE.children && itemE.children.length > 0) {
            //                                         itemE.children.sort((a, b) => {
            //                                             return a.index - b.index
            //                                         })
            //                                     }
            //                                 })
            //                             }
            //                         })
            //                     }
            //                 })
            //             }
            //         })
            //     }
            // })
            //
            // 按照name 排序
            fileTree.forEach(itemA => {
                if (itemA.children && itemA.children.length > 0) {
                    itemA.children.sort((a, b) => {
                        return a.name.localeCompare(b.name, "zh");
                    })
                    itemA.children.forEach(itemB => {
                        if (itemB.children && itemB.children.length > 0) {
                            itemB.children.sort((a, b) => {
                                return a.name.localeCompare(b.name, "zh");
                            })
                            itemB.children.forEach(itemC => {
                                if (itemC.children && itemC.children.length > 0) {
                                    itemC.children.sort((a, b) => {
                                        return a.name.localeCompare(b.name, "zh");
                                    })
                                    itemC.children.forEach(itemD => {
                                        if (itemD.children && itemD.children.length > 0) {
                                            itemD.children.sort((a, b) => {
                                                return a.name.localeCompare(b.name, "zh");
                                            })
                                            itemD.children.forEach(itemE => {
                                                if (itemE.children && itemE.children.length > 0) {
                                                    itemE.children.sort((a, b) => {
                                                        return a.name.localeCompare(b.name, "zh");
                                                    })
                                                }
                                            })
                                        }
                                    })
                                }
                            })
                        }
                    })
                }
            })
            return fileTree;
        },
        loadPSD(file) {
            let self = this
            return new Promise((resolve, reject) => {
                PSD.fromURL(file).then(function (psd) {
                    console.log("-=-=====PSD")
                    console.log(psd)
                    let base64 = psd.image.toBase64()
                    resolve(base64)
                })
            });
        },
        // Takes a data URI and returns the Data URI corresponding to the resized image at the wanted size.
        resizedataURL(datas, width) {
            return new Promise(async function (resolve, reject) {

                // We create an image to receive the Data URI
                var img = document.createElement('img');

                // When the event "onload" is triggered we can resize the image.
                img.onload = function () {
                    // We create a canvas and get its context.
                    var canvas = document.createElement('canvas');
                    var ctx = canvas.getContext('2d');

                    // We set the dimensions at the wanted size.
                    let finalWidth = width
                    let finalHeight = width / img.width * img.height
                    canvas.width = finalWidth
                    canvas.height = finalHeight

                    // We resize the image with the canvas method drawImage();
                    ctx.drawImage(this, 0, 0, finalWidth, finalHeight);

                    var dataURI = canvas.toDataURL();

                    // This is the return of the Promise
                    resolve(dataURI);
                };

                // We put the Data URI in the image's src attribute
                img.src = datas;

            })
        },// Use it like : var newDataURI = await resizedataURL('yourDataURIHere', 50, 50);
        // print() {
        //     window.print()
        // },

        downloadImg(item) {
            Toast.loading('正在合成图片...')
            let self = this
            // 带背景图片
            let element = document.getElementById('printMe')
            html2canvas(element, {
                useCORS: true, // 开启跨域设置，需要后台设置cors
                backgroundColor: "#ffffff",
                width: self.imgSize,
                height: self.imgSize,
                // windowWidth: self.imgSize,
                // windowHeight: self.imgSize,
            }).then(function (canvas) {
                // let dataURL = canvas.toDataURL("image/jpeg")
                // // 将图片按照A4大小切割
                // // download
                // let x = new XMLHttpRequest();
                // x.open("GET", dataURL, true);
                // x.responseType = "blob";
                // x.onload = function (e) { download(e.target.response, "印刷汇总图.jpg", "image/jpeg"); };
                // x.send();

                let a4Width = 2480; // A4宽度, 300 DPI
                let a4Height = 3508; // A4高度, 300 DPI
                let topMargin = 100; // 页边距
                let pageWidth = canvas.width; // 页面宽度
                let pageHeight = canvas.width / a4Width * a4Height; // 页面高度
                let contentHeight = pageHeight - topMargin * 2; // 内容高度

                console.log("pageWidth", pageWidth)
                console.log("pageHeight", pageHeight)
                console.log("contentHeight", contentHeight)
                console.log(canvas.height / contentHeight)

                let numPages = Math.ceil(canvas.height / contentHeight); // 需要的A4页面数
                
                for (let i = 0; i < numPages; i++) {
                    let adjustedStartY = 0;
                    let adjustedEndY = contentHeight;
                    let adjustedWidth = pageWidth;
                    let adjustedHeight = contentHeight;

                    if (i != 0) {
                        adjustedStartY = self.adjustPageSplit(canvas, i * contentHeight, pageWidth, contentHeight );
                    }
                    if (i < numPages - 1) {
                        adjustedEndY = self.adjustPageSplit(canvas, (i + 1) * contentHeight - 1, pageWidth, contentHeight);
                    }
                    console.log("adjustedStartY", adjustedStartY)
                    console.log("adjustedEndY", adjustedEndY)
                    console.log(adjustedEndY - adjustedStartY)

                    if (i < numPages - 1) {
                        adjustedHeight = adjustedEndY - adjustedStartY
                    } else {
                        adjustedHeight = canvas.height - i * contentHeight
                    }

                    // 创建新的画布来存放每页的内容
                    let pageCanvas = document.createElement('canvas');
                    pageCanvas.width = pageWidth
                    if( adjustedHeight > contentHeight) {
                        pageCanvas.height = adjustedHeight + topMargin * 2
                    } else {
                        pageCanvas.height = pageHeight
                    }
                    let pageCtx = pageCanvas.getContext('2d');
                    pageCtx.fillStyle = "#ffffff"; // 设置填充颜色为白色
                    pageCtx.fillRect(0, 0,  pageCanvas.width, pageCanvas.height); // 填充整个画布
                    
                    // 计算并绘制当前页的内容
                    pageCtx.drawImage(canvas, 0 , adjustedStartY, pageWidth, adjustedHeight, 0, topMargin, pageWidth, adjustedHeight);

                    // 在此处添加绘制页码的代码
                    pageCtx.fillStyle = '#000'; // 设置文本颜色
                    pageCtx.textAlign = 'right'; // 设置文本对齐方式为右对齐
                    pageCtx.textBaseline = 'bottom'; // 设置文本基线为底部对齐
                    pageCtx.font = 'bold 40px Arial';
                    // 底色
                    let pageNumber = self.fileTree[0].name + ' - ' + `${i + 1} / ${numPages}`; // 当前页码 / 总页数
                    // 计算页码位置，确保它位于页面右下角
                    let pageX = pageCanvas.width - 20;
                    let pageY = pageCanvas.height - 20; 
                    pageCtx.fillText(pageNumber, pageX, pageY); // 绘制页码

                    // 导出当前页为图片
                    let dataURL = pageCanvas.toDataURL("image/jpeg");
                    let filename = '印刷汇总图' + self.randomTwoLetters + '-'+ (i + 1) + '.jpg';
                    self.downloadPage(dataURL, filename);
                }
                Toast.clear()
            });
        },
        adjustPageSplit(canvas, startY, pageWidth, contentHeight) {
            let ctx = canvas.getContext('2d');
            let safeY = startY;
            while (!this.checkPageSplitLine(ctx, safeY, pageWidth, contentHeight)) {
                safeY = safeY - 1
            }
            return safeY;
        },
        checkPageSplitLine(ctx, y, pageWidth, contentHeight) {
            let imageData = ctx.getImageData(0, y, pageWidth, 1).data;
            for (let i = 0; i < imageData.length; i += 4) {
                // RGBA颜色值
                let r = imageData[i];
                let g = imageData[i + 1];
                let b = imageData[i + 2];
                let alpha = imageData[i + 3];
                if (!(r === 255 && g === 255 && b === 255 && alpha === 255)) { // 非白色像素
                    return false; // 分页线穿过非背景内容
                }
            }
            return true; // 分页线位置安全
        },
        downloadPage(dataURL, filename) {
            let x = new XMLHttpRequest();
            x.open("GET", dataURL, true);
            x.responseType = "blob";
            x.onload = function(e) {
                download(e.target.response, filename, "image/jpeg");
            };
            x.send();
        },
        go(where) {
            this.$router.push('/' + where)
        },
    }
}
</script>

<style scoped>
.container-badgeImage {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
}

.container-badgeImage .common-topbox {
    padding-bottom: 0;
}

.container-badgeImage .common-midbox {
    height: auto;
}

/* 上传按钮 */
.upload-btn {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.upload-text {
    display: inline-block;
    cursor: pointer;
}

.upload-input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 2;
}

/*  */
.treeBox {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    /* padding: 20px; */
    /* border: 4px solid #e9e9eb; */
    flex: 1;
}

.treeBox-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1000px;
    padding: 5px 15px 15px;
    background-color: #fff;
    margin: 10px auto;
}

.tree-titlebox {
    padding-top: 10px;
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 900;
    color: #000;
    text-align: center;
}

.tree-file {
    width: auto;
}

.tree-folder {
    max-width: 100%;
    min-width: 130px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    padding-right: 14px;
}

.treeBox-tip {
    font-size: 14px;
    margin: 10px 0;
    width: 100%;
}

.treeBox-blank {
    width: 100%;
    height: 200px;
    text-align: center;
    line-height: 200px;
    border-radius: 20px;
    background-color: #eee;
    font-weight: bold;
    font-size: 16px;
    margin-top: 15px;
}
</style>
<style>
/* 打印样式 */

@media print {
    html {
        height: auto;
        overflow: auto;
        background-color: white;
    }

    body {
        height: auto;
        background-color: white;
    }

    #app {
        height: auto;
    }

    @page {
        size: A3 portrait;
        margin: 2cm;
        /* 国家标准公文页边距 GB/T 9704-2012 */
    }

    .container-navbar {
        display: none;
    }

    .navbar-main {
        display: none;
    }

    .navbar-secondary {
        display: none;
    }

    .common-topbox {
        display: none;
        padding: 0;
    }

    .together {
        page-break-inside: avoid;
    }

    .common-midbox {
        padding: 0;
    }

    @page {

        /* 页面内容区域底部添加一条 1px 的灰线 */
        @bottom-center {
            border-top: 1px solid gray;
        }

        /* 页脚中间显示格式如 "第 3 页" 的页码 */
        @bottom-center {
            content: "第" counter(page) "页";
        }
    }
}
</style>