<template>
    <div id="app">
        <Navbar v-if="$route.path != '/login' && $route.path != '/index' && $route.path != '/' && device != 'mobile'" />
        <router-view class="router-view"></router-view>
        <NavbarMobile v-if="device == 'mobile'" />
    </div>
</template>

<script>
import Navbar from './components/common/navbar'
import NavbarMobile from './components/common/navbarMobile'
// import store from "./components/common/store.js"
// import { apiGetConstant } from './request/api'

export default {
    name: 'App',
    // store,
    components: {
        Navbar,
        NavbarMobile,
    },
    data() {
        return {
            device: this.GLOBAL.device,
            // constantData: null
        }
    },
    beforeMount() {
        this.getDevice()
    },
    mounted() {
        window.addEventListener('resize', this.handleResize)
        this.init()
    },
    methods: {
        init() {
        },
        handleResize() {
            this.getDevice()
        },
        getDevice() {
            let os = function () {
                let ua = navigator.userAgent,
                    isWindowsPhone = /(?:Windows Phone)/.test(ua),
                    isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
                    isAndroid = /(?:Android)/.test(ua),
                    isFireFox = /(?:Firefox)/.test(ua),
                    isChrome = /(?:Chrome|CriOS)/.test(ua),
                    isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox &&
                        /(?:Tablet)/.test(ua)),
                    isPhone = /(?:iPhone)/.test(ua) && !isTablet,
                    isPc = !isPhone && !isAndroid && !isSymbian;
                return {
                    isTablet: isTablet,
                    isPhone: isPhone,
                    isAndroid: isAndroid,
                    isPc: isPc
                };
            }();
            let device
            if (os.isAndroid || os.isPhone) {
                // 手机
                device = "mobile"
            } else if (os.isTablet) {
                // 平板
                device = "pad"
            } else {
                // 电脑
                device = "pc"
            }
            this.GLOBAL.device = device
            this.device = device
        }
    }
}
</script>

<style>
html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: none;
    background-color: #FBFBFB;
}

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    /* transition: all 0.3s ease; */
}

#app {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: row;
}

.router-view {
    flex: 1;
    width: 1px;
}

input[type="button"],
input[type="submit"],
input[type="reset"] {
    -webkit-appearance: none;
}

textarea {
    -webkit-appearance: none;
}

/*以及圆角*/
button {
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
}

@media screen and (min-width:769px) {
    .lang-zh {
        font-family: system-ui, -apple-system, Arial, sans-serif;
    }

    .lang-en {
        font-family: system-ui, -apple-system, Arial, sans-serif;
    }

    .lang-es {
        font-family: system-ui, -apple-system, Arial, sans-serif;
    }
}

@media screen and (max-width:768px) {
    .lang-zh {
        font-family: -apple-system, BlinkMacSystemFont, Tahoma, Arial, "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    }

    .lang-en {
        font-family: -apple-system, "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    .lang-es {
        font-family: -apple-system, "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
}

/* vxe表格全局央视 */
.vxe-pager {
    background: none !important;
}

/* 全局动画 */
*,
*:hover {
    transition: all 0.3s ease;
}

/* 全局container */
.container {
    display: flex;
    flex-direction: column;
}

.common-topbox {
    padding: 15px 30px 20px;
}

.common-midbox {
    flex: 1;
    padding: 0 30px;
    overflow: auto;
    height: 1px;
}

.common-bottombox {
    padding: 15px 30px;
}

.common-modalbox {
    width: 100%;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;

}

/* 顶部按钮 */

.common-title-btn-main {
    margin-left: 10px;
    background: #6667ab;
    color: #fff;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    font-size: 14px;
    padding: 0 16px;
    cursor: pointer;
    position: relative;
}

.common-title-btn-main:hover {
    transform: scale(1.05, 1.05);
}

.common-title-btn-secondary {
    margin-left: 10px;
    background: #b8a3ed;
    color: #fff;
    height: 40px;
    line-height: 40px;
    border-radius: 20px;
    font-size: 14px;
    padding: 0 16px;
    cursor: pointer;
}

.common-title-btn-secondary:hover {
    transform: scale(1.05, 1.05);
}

.common-title-btn-icon {
    width: 40px;
    padding: 0;
    text-align: center;
}

@media screen and (max-width: 480px) {

    #app {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    .router-view {
        flex: 1;
        height: 1px;
        width: auto;
    }

    .common-topbox {
        padding: 0;
        width: 100%;
        background-color: #fff;
    }

    .common-bottombox {
        padding: 5px 15px;
        /* display: none; */
    }

    /* 顶部按钮 */

    .common-title-btn-main {
        margin-left: 4px;
        height: 34px;
        line-height: 34px;
        border-radius: 17px;
        font-size: 14px;
        padding: 0 10px;
    }

    .common-title-btn-secondary {
        margin-left: 4px;
        height: 34px;
        line-height: 34px;
        border-radius: 17px;
        font-size: 14px;
        padding: 0 10px;
    }

    .common-title-btn-icon {
        width: 34px;
        padding: 0;
        text-align: center;
    }

    .common-midbox {
        padding: 10px 15px;
        overflow: auto;
    }

}

/* 打印样式 */
</style>