<template>
  <div class="container">
    <!-- 标题区域 -->
    <div class="common-topbox">
      <Title title="快速验货" intro="">
        <template v-slot:right>
          <!-- 右侧按钮区域插槽 -->
          <div
            class="common-title-btn-secondary common-title-btn-icon"
            @click="reflash()"
          >
            <font-awesome-icon icon="fa-solid fa-arrow-rotate-right" />
          </div>
        </template>
      </Title>
      <div class="search-time-box">
        <div class="search-time-one calendar" @click="openCalendar()">
          {{ showToday(today) }}
        </div>
        <div
          :class="
            searchNow == item ? 'search-time-one active' : 'search-time-one'
          "
          v-for="item in todayHours"
          @click="search(item)"
        >
          {{ showHouer(item) }}
        </div>
      </div>
      <div class="search-right-addcheckbox-title">
        <span v-if="loading">
          <van-loading size="18px" type="spinner">加载中...</van-loading>
        </span>
        <span v-else>本页共 {{ photos.length }} 张照片</span>
      </div>
    </div>
    <div class="common-midbox">
      <div
        class="search-right-addcheckbox"
        v-if="!loading && photos && photos.length > 0"
      >
        <viewer
          class="search-photo-box"
          ref="viewer"
          :images="photos"
          :options="photoOptions"
        >
          <div class="search-photo-one" v-for="item in photos">
            <img :src="item.src" :data-source="item.dataSource" alt="" />
            <div class="search-photo-name">{{ item.filename }}</div>
            <div v-if="item.checked" class="search-photo-tip">
              <font-awesome-icon icon="fa-solid fa-eye" />
            </div>
          </div>
          <!-- <div
            class="search-photo-loadmore"
            @click="loadMorePhoto()"
            v-if="this.photoLoadPage < 6"
          >
            加载更多
          </div>
          <div class="search-photo-loadmore-end" v-else>到底了</div> -->
        </viewer>
      </div>
    </div>
    <van-calendar
      v-model="showCalendar"
      :show-confirm="false"
      @confirm="onConfirmCalendar"
      :min-date="new Date(2010, 0, 1)"
    />
  </div>
</template>

<script>
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);
import Title from "@/components/common/title";
import { Toast, Dialog, Loading, Calendar } from "vant";
import { apiGetExpressPhoto } from "@/request/api";
// import { apiName } from '@/request/api'

export default {
  components: {
    Title,
  },
  name: "Index",
  // props: {
  //   msg: String
  // },
  data() {
    return {
      loading: false,
      loadingPhoto: false,
      showCalendar: false,
      device: this.GLOBAL.device,
      today: "",
      hours: [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
      ],
      todayHours: "",
      photos: [],
      searchNow: null,
      photoOptions: {
        toolbar: {
          zoomIn: 2,
          zoomOut: 2,
          oneToOne: 2,
          reset: 2,
          rotateLeft: 2,
          rotateRight: 2,
          prev: 1,
          next: 1,
          flipHorizontal: 0,
          flipVertical: 0,
          play: 0,
          // download: {
          //   show: 1,
          //   title: "下载图片",
          //   icon: "custom-icon-download", // 自定义图标类名，需要自己定义CSS样式
          //   click: () => {
          //     const viewer = this.$refs.viewer;
          //     const index = viewer.$viewer.index;
          //     const img = viewer.images[index];
          //     console.log("点击的图片:", img);
          //     if (img) {
          //       let url = img.dataSource;
          //       let filename = img.filename;
          //       this.downloadItem(url, filename);
          //     } else {
          //       console.log("未找到图片元素");
          //     }
          //   },
          // },
        },
        viewed: (e) => {
          // this.$refs.viewer.$viewer.rotateTo(-90);
          this.$refs.viewer.$viewer.scale(2);
          // 调用 this.viewedFun(e.detail.index) 方法
          this.viewedFun(e.detail.index);
        },
        title: 2,
        url: "data-source",
        zIndex: 10000,
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getToday();
    },
    markViewedPhotos() {
      const viewedPhotos =
        JSON.parse(localStorage.getItem("viewedPhotos")) || [];
      this.photos.forEach((photo) => {
        if (viewedPhotos.includes(photo.filename)) {
          photo.checked = true;
        }
      });
    },
    viewedFun(index) {
      const viewedPhotos =
        JSON.parse(localStorage.getItem("viewedPhotos")) || [];
      const photoName = this.photos[index].filename;
      if (!viewedPhotos.includes(photoName)) {
        viewedPhotos.push(photoName);
        localStorage.setItem("viewedPhotos", JSON.stringify(viewedPhotos));
      }
      this.photos[index].checked = true; // 更新当前查看的照片状态
    },
    openCalendar() {
      this.showCalendar = true;
    },
    onConfirmCalendar(date) {
      this.showCalendar = false;
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      this.today = `${year}${month < 10 ? "0" + month : month}${
        day < 10 ? "0" + day : day
      }`;
      this.getHours();
    },
    getToday() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      this.today = `${year}${month < 10 ? "0" + month : month}${
        day < 10 ? "0" + day : day
      }`;
      this.getHours();
    },
    getHours() {
      let newHours = [];
      for (let i = 0; i < this.hours.length; i++) {
        let item = this.today + "_" + this.hours[i];
        newHours.push(item);
      }
      this.todayHours = newHours;
      if (this.searchNow) {
        let time = this.searchNow.split("_")[1];
        this.search(this.today + "_" + time);
      } else {
        this.search(newHours[10]);
      }
    },
    reflash() {
      this.search(this.searchNow);
    },
    async search(time) {
      this.searchNow = time;
      this.photos = [];
      let minutes = ["0", "1", "2", "3", "4", "5"];
      this.loading = true;
      let promises = minutes.map((minute) => this.searchPhoto(time + minute));
      await Promise.all(promises);
      // 对this.photos 排序，按照字段filename排序
      if (this.photos && this.photos.length > 0) {
        this.photos.sort((a, b) => {
          return a.filename.localeCompare(b.filename);
        });
      }
      this.markViewedPhotos(); // 在所有请求完成后执行
      this.loading = false;
    },
    searchPhoto(time) {
      return new Promise((resolve, reject) => {
        let self = this;
        let params = {
          filename: "IMG_" + time,
        };
        Toast.loading({
          message: "加载中",
          forbidClick: true,
          duration: 0,
        });
        apiGetExpressPhoto(params)
          .then((res) => {
            let data = res.data;
            let photos = data;
            let newPhotos = [];
            for (let i = 0; i < photos.length; i++) {
              let item = {
                src:
                  "https://mrp-express-photo.moemee.com/" +
                  photos[i] +
                  "?x-oss-process=style/sq",
                dataSource:
                  "https://mrp-express-photo.moemee.com/" +
                  photos[i] +
                  "?x-oss-process=style/3k",
                filename: photos[i].split("/").pop(),
                loadPatch: 1,
                checked: false,
              };
              newPhotos.push(item);
            }
            self.photos.push(...newPhotos);
            Toast.clear();
            resolve(); // 请求成功后调用 resolve
          })
          .catch((err) => {
            console.warn(err);
            Toast.clear();
            reject(err); // 请求失败后调用 reject
          });
      });
    },
    showHouer(item) {
      let houer = item.split("_")[1];
      // 转换为数字
      houer = Number(houer);
      return houer + ":00";
    },
    showToday(today) {
      let year = today.slice(0, 4);
      let month = today.slice(4, 6);
      let day = today.slice(6, 8);
      return `${year}-${month}-${day}`;
    },
  },
};
</script>

<style scoped>
.common-topbox {
  padding-bottom: 0px;
}
/* 搜索区域 */
.searchbox {
  width: 320px;
  display: flex;
  /* flex: 1; */
  flex-direction: row;
  align-items: center;
}

.search-input {
  flex: 1;
  border: 2px solid #e9e9eb;
  outline: none;
  height: 40px;
  border-radius: 20px 0 0 20px;
  padding: 0 15px;
  font-size: 14px;
}

.search-btn {
  width: 80px;
  border: none;
  outline: none;
  height: 40px;
  background-color: #e9e9eb;
  color: #333;
  border-radius: 0 20px 20px 0;
  cursor: pointer;
  font-size: 14px;
  word-break: keep-all;
  white-space: nowrap;
}

.search-btn:hover {
  opacity: 0.7;
}

.search-modalbox-photo-box {
  overflow-y: auto;
  flex: 1;
  padding: 15px 20px 15px;
}

.search-modalbox-photo {
  background: #fbfbfb;
  border: 1px solid #efeeee;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 15px;
  margin-bottom: 15px;
  position: relative;
}

.search-right-addcheckbox {
  margin-bottom: 15px;
}

.search-right-addcheckbox-title {
  margin-bottom: 10px;
  font-size: 14px;
  color: #666;
}

.search-photo-time-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.search-photo-time {
  font-size: 14px;
  padding: 4px 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  background-color: #e9e9eb;
  color: #666;
  border-radius: 6px;
  cursor: pointer;
}

.search-photo-time-tip {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.search-photo-search {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.search-photo-input {
  flex: 1;
  border: 1px solid #e9e9eb;
  outline: none;
  height: 32px;
  border-radius: 16px 0 0 16px;
  padding: 0 15px;
  font-size: 14px;
}

.search-photo-search-btn {
  width: 80px;
  border: none;
  outline: none;
  height: 32px;
  background-color: #e9e9eb;
  color: #333;
  border-radius: 0 16px 16px 0;
  cursor: pointer;
}

.search-photo-box {
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 5px;
}

.search-photo-one {
  display: block;
  width: 200px;
  height: auto;
  height: auto;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
  background-color: #222;
  border-radius: 5px;
}

.search-photo-one:hover {
  background-color: #000;
}
.search-photo-one img {
  display: block;
  width: 200px;
  height: 200px;
  border-radius: 5px;
}
.search-photo-loadmore {
  width: 100%;
  text-align: center;
  height: 30px;
  line-height: 30px;
  color: #fff;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 15px;
  background-color: #b8a3ed;
}

.search-photo-loadmore:hover {
  background-color: #a18de0;
}

.search-photo-loadmore-end {
  width: 100%;
  text-align: center;
  height: 30px;
  line-height: 30px;
  color: #666;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 15px;
}
.search-time-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 16px;
}
.search-time-one {
  font-size: 13px;
  padding: 4px 8px;
  margin-bottom: 10px;
  margin-right: 6px;
  background-color: #e9e9eb;
  color: #666;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  font-weight: bold;
}
.search-time-one.active {
  background-color: #b8a3ed;
  color: #fff;
}

.search-time-one.calendar {
  background-color: #000;
  color: #fff;
}

.search-photo-tip {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2%;
  pointer-events: none;
}
.search-photo-name {
  font-size: 10px;
  color: #999;
  margin-top: 5px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  padding-bottom: 4px;
}

.search-photo-one:hover .search-photo-name {
  color: #fff;
}

@media screen and (max-width: 768px) {
  .search-time-box {
    padding-left: 15px;
    padding-right: 5px;
    margin-top: 10px;
  }
  .search-right-addcheckbox-title {
    padding-left: 15px;
  }
  .search-time-one {
    font-size: 10px;
    padding: 4px 6px;
    margin-bottom: 8px;
    margin-right: 6px;
  }
  .search-photo-one {
    width: 31%;
    margin-right: 2%;
  }
  .search-photo-one:nth-child(3n) {
    margin-right: 0;
  }
  .search-photo-one img {
    width: 100%;
    height: auto;
  }
}
</style>
