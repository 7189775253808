<template>
    <div class="content-link">
        <div class="c-link-title">
            常用链接
        </div>
        <div class="c-link-linkbox">
            <div class="c-link-single" v-for="(item, index) in linkA" @click="open(item, index)">
                <img class="c-link-icon" :src="'/oss-assets/link/' + item.icon" alt="">
                <div class="c-link-name">
                    <span>{{ item.name }}</span>
                    <span v-if="item.local" class="local">本地</span>
                </div>
            </div>
        </div>
        <div class="c-link-title">
            其他链接
        </div>
        <div class="c-link-linkbox">
            <div class="c-link-single" v-for="(item, index) in linkB" @click="open(item, index)">
                <img class="c-link-icon" :src="'/oss-assets/link/' + item.icon" alt="">
                <div class="c-link-name">
                    <span>{{ item.name }}</span>
                    <span v-if="item.local" class="local">本地</span>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import Title from '@/components/common/title'
// import { apiName } from '@/request/api'

export default {
    components: {
        Title,
    },
    name: 'Index',
    // props: {
    //   msg: String
    // },
    data() {
        return {
            loading: false,
            device: this.GLOBAL.device,
            linkA: [
                {
                    name: '我打ERP',
                    icon: 'icon-woda.png',
                    url: 'https://erp.woda.com',
                    local: false,
                },
                {
                    name: '千牛',
                    icon: 'icon-qianniu.png',
                    url: 'https://myseller.taobao.com',
                    local: false,
                },
                {
                    name: '拼多多',
                    icon: 'icon-pdd.png',
                    url: 'https://mms.pinduoduo.com',
                    local: false,
                },
                {
                    name: '抖店',
                    icon: 'icon-doudian.png',
                    url: 'https://fxg.jinritemai.com/login',
                    local: false,
                },
                {
                    name: '中通快递管家',
                    icon: 'icon-zto.png',
                    url: 'https://yundadan.zto.com',
                    local: false,
                },
                {
                    name: '圆通快递管家',
                    icon: 'icon-yto.png',
                    url: 'https://kh.yto.net.cn/new/login',
                    local: false,
                },
                {
                    name: '韵达客户助手',
                    icon: 'icon-yunda.png',
                    url: 'https://kh.yundasys.com',
                    local: false,
                },
            ],

            linkB: [
                {
                    name: '快递单导出',
                    icon: 'icon-wodadaochu.png',
                    url: 'https://erp.woda.com/module/report#/parcelReport',
                    local: false,
                },
                {
                    name: '创小摊',
                    icon: 'icon-mihoyo.png',
                    url: 'https://webstatic.mihoyo.com/kol/admin/index.html#/login',
                    local: false,
                },
                {
                    name: '小红书',
                    icon: 'icon-xiaohongshu.png',
                    url: 'https://ark.xiaohongshu.com/app-system/home',
                    local: false,
                },
                {
                    name: '微店',
                    icon: 'icon-weidian.png',
                    url: 'https://www.weidian.com',
                    local: false,
                },
                {
                    name: '千岛同人谷',
                    icon: 'icon-qiandao.png',
                    url: 'https://shop.qiandaoapp.com',
                    local: false,
                },
                {
                    name: '小米云',
                    icon: 'icon-micloud.png',
                    url: 'https://i.mi.com',
                    local: false,
                },
                {
                    name: '摄像机',
                    icon: 'icon-camera.png',
                    url: 'http://192.168.31.116:5000/webman/3rdparty/SurveillanceStation/',
                    local: true,
                },
                {
                    name: 'NAS',
                    icon: 'icon-nas.png',
                    url: 'http://192.168.31.116:5000',
                    local: true,
                },
                {
                    name: 'HASS',
                    icon: 'icon-hass.png',
                    url: 'http://192.168.31.116:8123',
                    local: true,
                },
            ]
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() { },
        api() {
            let self = this
            apiName({
                page: 1,
                size: 10,
            }).then(res => {

            }).catch(err => {
                console.warn(err)
            })
        },
        open(item, index) {
            window.open(item.url)
        }
    }
}
</script>
  
<style scoped>
.content-link {
    width: 100%;
}

.c-link-title {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-left: 10px;
}

.c-link-linkbox {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    position: relative;
}

.c-link-single {
    width: 120px;
    background-color: #efefef;
    border-radius: 30px;
    padding: 15px 15px 13px;
    margin-right: 30px;
    margin-bottom: 15px;
    cursor: pointer;
    text-decoration: none;
    border: 2px;
}



.c-link-icon {
    display: block;
    width: 100%;
    border-radius: 20px;
}

.c-link-name {
    width: 100%;
    text-align: center;
    color: #333;
    font-size: 15px;
    margin-top: 10px;
}

.c-link-single:hover {
    background-color: #FBFBFB;
}

.c-link-single:hover .c-link-name {
    transform: translateY(4px)
}

.c-link-single:hover .c-link-icon {
    transform: scale(1.1);
}

.local {
    font-size: 12px;
    padding-left: 4px;
    color: #666;

}

/* mobile */
@media screen and (max-width: 480px) {
    .c-link-single {
        width: 30%;
        border-radius: 28px;
        padding: 10px 10px 8px;
        margin-right: 5%;
        margin-bottom: 10px;
        border: 2px;
    }

    .c-link-single:nth-child(3n) {
        margin-right: 0;
    }

    .c-link-icon {
        border-radius: 20px;
    }

    .c-link-name {
        font-size: 13px;
        margin-top: 6px;
    }
}
</style>