<template>
    <div class="container-login">
        <div class="login-box">
            <div class="login-title">
                MRP管理系统
            </div>
            <input type="number" class="login-input" v-model="uesrInfo.phone" placeholder="手机号" />
            <input type="password" class="login-input" v-model="uesrInfo.password" placeholder="密码" />
            <button class="login-btn" @click="login()">登录</button>
        </div>
        <a target="_blank" class="icp" href="https://beian.miit.gov.cn">
            苏ICP备18002403号-2 ©MoeMee
        </a>
    </div>
</template>

<script>
import { apiLogin, apiGetConstant, apiGetBrandList, apiGetCompanyList, apiGetPlatformList, apiGetExpressList } from '../request/api'
import { Toast, Dialog } from 'vant';

export default {
    components: {

    },
    name: 'Index',
    // props: {
    //   msg: String
    // },
    data() {
        return {
            loading: false,
            uesrInfo: {
                phone: null,
                password: null,
            },
            device: this.GLOBAL.device,
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.checkToken()
        },
        checkToken() {
            let token = localStorage.getItem('token')
            if (token) {
                let self = this
                let params;
                params = {
                    number: '666666'
                }
                apiGetExpressList(params).then(res => {
                    self.goHome()
                    Toast.clear()
                }).catch(err => {
                    console.warn(err)
                })
            }
        },
        login() {
            let md5 = require('md5-js');
            // 登录
            let self = this
            let params;
            params = JSON.parse(JSON.stringify(this.uesrInfo))
            params.password = md5(this.uesrInfo.password)
            apiLogin(params).then(res => {
                localStorage.setItem('token', res.data.token)
                localStorage.setItem('nickname', res.data.nickname)
                localStorage.setItem('role', JSON.stringify(res.data.role))
                self.getConstant()
                self.getCompanyList()
                self.getBrandList()
                self.getPlatformList()
                self.goHome()
            }).catch(err => {
                console.warn(err)
            })
        },
        goHome() {
            this.go('desktop')
        },
        getConstant() {
            let self = this
            apiGetConstant().then(res => {
                let data = res.data
                // this.constantData = data
                localStorage.setItem('constantIsCommon', JSON.stringify(data.isCommon))
                localStorage.setItem('constantRoles', JSON.stringify(data.roles))
                localStorage.setItem('constantStatus', JSON.stringify(data.status))
                localStorage.setItem('constantStatusCode', JSON.stringify(data.statusCode))
                localStorage.setItem('constantFlag', JSON.stringify(data.flag))
                localStorage.setItem('constantReturnStatusCode', JSON.stringify(data.returnStatusCode))
            }).catch(err => {
                console.warn(err)
            })
        },
        getCompanyList() {
            let self = this
            apiGetCompanyList({
                page: 1,
                size: 999,
            }).then(res => {
                let data = res.data
                localStorage.setItem('companyList', JSON.stringify(data.items))
            }).catch(err => {
                console.warn(err)
            })
        },
        getBrandList() {
            let self = this
            apiGetBrandList({
                page: 1,
                size: 999,
            }).then(res => {
                let data = res.data
                localStorage.setItem('brandList', JSON.stringify(data.items))
            }).catch(err => {
                console.warn(err)
            })
        },
        getPlatformList() {
            let self = this
            apiGetPlatformList({
                page: 1,
                size: 999,
            }).then(res => {
                let data = res.data
                localStorage.setItem('platformList', JSON.stringify(data.items))
            }).catch(err => {
                console.warn(err)
            })
        },
        go(where) {
            this.$router.push('/' + where)
        },
    }
}
</script>

<style scoped>
.container-login {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-image: linear-gradient(180deg, #FDB3E2 0%, #9A79CC 99%); */
    background-image: url("/oss-assets/login/bg.jpg");
    background-color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.login-box {
    width: 320px;
    height: auto;
    padding: 30px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: -60px;
}

.login-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 10px;
}

.login-input {
    width: 100%;
    height: 36px;
    border-radius: 18px;
    font-size: 14px;
    margin-top: 16px;
    border: 1px solid #999;
    padding: 0 10px;
}

.login-btn {
    display: block;
    width: 120px;
    height: 40px;
    border-radius: 20px;
    font-size: 14px;
    margin-top: 30px;
    border: none;
    background-color: #6667ab;
    color: #fff;
    cursor: pointer;
}

.icp {
    color: #fff;
    font-size: 12px;
    opacity: 0.5;
    position: absolute;
    bottom: 20px;
}

.icp:hover {
    opacity: 1;
}
</style>