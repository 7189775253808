<template>
    <div class="container">
        <div class="common-topbox">
            <!-- 标题区域 -->
            <Title title="品牌管理">
                <template v-slot:right>
                    <!-- 右侧按钮区域插槽 -->
                    <div class="common-title-btn-main common-title-btn-icon" @click="openModal('add')" v-tippy
                        content="添加品牌">
                        <font-awesome-icon icon="fa-solid fa-plus" />
                    </div>
                </template>
            </Title>
        </div>
        <div class="common-midbox" v-if="tableData">
            <vxe-table round border highlight-hover-row resizable ref="xTable" :data="tableData.items">
                <vxe-table-column type="seq" width="60"></vxe-table-column>
                <!-- <vxe-table-column field="id" title="ID" width="60"></vxe-table-column> -->
                <vxe-table-column field="name" title="品牌名称"></vxe-table-column>
                <vxe-table-column field="enName" title="品牌英文名"></vxe-table-column>
                <vxe-table-column field="status" :formatter="formatStatus" title="状态" width="100"></vxe-table-column>
                <vxe-table-column field="remarks" title="备注"></vxe-table-column>
                <vxe-table-column title="操作" width="102">
                    <template #default="{ row }">
                        <vxe-button @click="edit(row)" circle>
                            <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                        </vxe-button>
                        <vxe-button @click="del(row)" circle>
                            <font-awesome-icon icon="fa-solid fa-trash-can" />
                        </vxe-button>
                    </template>
                </vxe-table-column>
            </vxe-table>
        </div>
        <div class="common-bottombox">
            <Pager :pageSizes="[10, 100]" :tablePage="tablePage" @handlePageChange="handlePageChange" />
        </div>
        <!-- 弹窗 -->
        <van-dialog v-model:show="modal.show" @confirm="submit()" theme="round"
            :title="modal.type == 'add' ? '添加品牌' : '修改品牌'" show-cancel-button confirm-button-text="提交"
            confirm-button-color="#6667ab" cancel-button-text="取消" cancel-button-color="#666">
            <div class="common-modalbox">
                <input class="modal-add-input" v-model="info.name" type="text" placeholder="品牌名称" />
                <input class="modal-add-input" v-model="info.enName" type="text" placeholder="英文名" />
                <select class="modal-add-select" v-model="info.status" placeholder="状态">
                    <!-- Todo -->
                    <option value="1">启用</option>
                    <option value="0">停用</option>
                </select>
                <textarea class="modal-add-textarea" v-model="info.remarks" placeholder="备注"></textarea>
            </div>
        </van-dialog>
    </div>
</template>

<script>
import Navbar from '@/components/common/navbar'
import Title from '@/components/common/title'
import Pager from '@/components/common/pager'
import { Toast, Dialog } from 'vant';
import { apiGetBrandList, apiAddBrand, apiEditBrand, apiDelBrand } from '@/request/api'

export default {
    components: {
        Navbar,
        Title,
        Pager,
    },
    name: 'Index',
    // props: {
    //   msg: String
    // },
    data() {
        return {
            device: this.GLOBAL.device,
            loading: false,
            tableData: null,
            tablePage: {
                currentPage: 1,
                pageSize: 10,
                totalResult: 0
            },
            modal: {
                show: false,
                type: "add"
            },
            info: {
                name: null,
                enName: null,
                status: 1,
                remarks: null
            },
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.getList()
        },
        getList() {
            let self = this
            apiGetBrandList({
                page: this.tablePage.currentPage,
                size: this.tablePage.pageSize,
            }).then(res => {

                self.tableData = res.data
                self.tablePage.totalResult = res.data.count
            }).catch(err => {
                console.warn(err)
            })
        },
        handlePageChange({ currentPage, pageSize }) {
            this.tablePage.currentPage = currentPage
            this.tablePage.pageSize = pageSize
            this.getList()
        },
        openModal(type) {
            if (type == 'add') {
                this.info = {
                    name: null,
                    enName: null,
                    status: 1,
                    remarks: null
                }
            }
            this.modal = {
                show: true,
                type: type
            }
        },
        submit() {
            Toast.loading('提交中')
            let self = this
            if (this.modal.type == 'add') {
                // 新增
                let params;
                params = this.info
                apiAddBrand(params).then(res => {

                    Toast.clear()
                    Toast.success('添加成功');
                    self.getList()
                }).catch(err => {
                    console.warn(err)
                    Toast.clear()
                })
            } else {
                // 编辑
                let params = {};
                params = this.info
                apiEditBrand(params).then(res => {

                    Toast.clear()
                    Toast.success('修改成功');
                    self.getList()
                }).catch(err => {
                    console.warn(err)
                    Toast.clear()
                })
            }
        },
        edit(row) {
            this.info = JSON.parse(JSON.stringify(row))
            this.openModal('edit')
        },
        del(row) {
            let self = this
            Dialog.confirm({
                message: '删除' + row.name + '？',
                confirmButtonText: '删除'
            })
                .then(() => {
                    // on confirm
                    let params = JSON.parse(JSON.stringify(row))
                    apiDelBrand(params).then(res => {

                        Toast.success('删除成功');
                        self.getList()
                    }).catch(err => {
                        console.warn(err)
                    })
                })
                .catch(() => {
                    // on cancel
                });
            let params;
        },
        formatStatus(table) {
            let id = table.cellValue
            let result
            if (id == 1) {
                result = "启用"
            } else {
                result = "停用"
            }
            return result
        }
    }
}
</script>

<style scoped>
/* 弹窗 手动录入快递 */
.modal-add-select {
    width: 100%;
    height: 30px;
    border-radius: 15px;
    border: 1px solid #e6e6ef;
    outline: none;
    margin-bottom: 15px;
    padding: 0 10px;
    font-size: 14px;
    color: #333;
}

.modal-add-textarea {
    width: 100%;
    height: 80px;
    border-radius: 15px;
    border: 1px solid #e6e6ef;
    outline: none;
    padding: 10px 10px;
    font-size: 14px;
    color: #333;
    resize: none;
}

.modal-add-input {
    width: 100%;
    height: 30px;
    border-radius: 15px;
    border: 1px solid #e6e6ef;
    outline: none;
    margin-bottom: 15px;
    padding: 0 10px;
    font-size: 14px;
    color: #333;
}
</style>