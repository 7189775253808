// 参考网址：https://juejin.cn/post/6844903652881072141

// 在http.js中引入axios
import axios from 'axios'; // 引入axios
// import QS from 'qs'; // 引入qs模块，用来序列化post类型的数据，后面会提到
// vant的toast提示框组件，大家可根据自己的ui组件更改。
// import { Toast } from 'vant'; 

import Vue from 'vue';
import { Toast } from 'vant';
import router from "../router/index";

Vue.use(Toast);


// 环境的切换
if (process.env.NODE_ENV == 'development') {
    axios.defaults.baseURL = 'http://localhost:8080';
}
else if (process.env.NODE_ENV == 'debug') {
    axios.defaults.baseURL = 'http://mymsapi.moemee.com';
}
else if (process.env.NODE_ENV == 'production') {
    axios.defaults.baseURL = 'https://mrpapi.moemee.com';
    // axios.defaults.baseURL = 'http://mymsapi.moemee.com';
}

// 设置请求超时时间
axios.defaults.timeout = 6000;

// post请求头的设置
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// 返回登陆界面
function goLogin() {
    Toast({
        message: "凭证过期，请重新登录",
    })
    localStorage.removeItem('token')
    setTimeout(() => {
        // window.location.href = "/login"
        router.push('/login')
    }, 1000);
}

/* GET */
export function get(url, params) {
    if (params) {
        params.random = Math.random()
    } else {
        params = {
            random: Math.random()
        }
    }
    return new Promise((resolve, reject) => {
        axios({
            method: "get",
            url: url,
            params: params,
            headers: {
                'content-type': 'application/json', // 默认值
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'developer': 'MoeMee'
            }
        }).then(res => {
            if (res.data.code == 0) {
                resolve(res.data);
            } else if (res.data.code == 1) {
                Toast({
                    message: res.data.message,
                })
                console.warn("GET错误")
                console.warn(res)
                reject(res.data)
            } else {
                Toast({
                    message: "网络错误，请重试",
                })
                console.warn("GET错误")
                console.warn(res)

                reject(res.data)
            }
        }).catch(err => {
            if (err.response.status == 401) {
                goLogin()
            } else {
                Toast({
                    message: "网络超时，请重试",
                    icon: 'none',
                    duration: 2000
                })
            }
            reject(err)
        })
    });
}


/* POST */
export function post(url, params) {
    let self = this
    return new Promise((resolve, reject) => {
        axios({
            method: "post",
            url: url,
            data: params,
            headers: {
                'content-type': 'application/json', // 默认值
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'developer': 'eWei'
            }
        }).then(res => {
            if (res.data.code == 0) {
                resolve(res.data);
            } else if (res.data.code == 1) {
                Toast({
                    message: res.data.message,
                })
                console.warn("POST错误")
                console.warn(res)
                reject(res.data)
            } else {
                Toast({
                    message: "网络错误，请重试",
                })
                console.warn("GET错误")
                console.warn(res)

                reject(res.data)
            }
        }).catch(err => {
            if (err.response.status == 401) {
                goLogin()
            } else {
                Toast({
                    message: "网络超时，请重试",
                    icon: 'none',
                    duration: 2000
                })
            }
            reject(err)
        })
    });
}

/* PUT */
export function put(url, params) {
    return new Promise((resolve, reject) => {
        axios({
            method: "put",
            url: url,
            data: params,
            headers: {
                'content-type': 'application/json', // 默认值
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'developer': 'eWei'
            }
        }).then(res => {
            if (res.data.code == 0) {
                resolve(res.data);
            } else if (res.data.code == 1) {
                Toast({
                    message: res.data.message,
                })
                console.warn("PUT错误")
                console.warn(res)
                reject(res.data)
            } else {
                Toast({
                    message: "网络错误，请重试",
                })
                console.warn("GET错误")
                console.warn(res)

                reject(res.data)
            }
        }).catch(err => {
            if (err.response.status == 401) {
                goLogin()
            } else {
                Toast({
                    message: "网络超时，请重试",
                    icon: 'none',
                    duration: 2000
                })
            }
            reject(err)
        })
    });
}


/* DELETE */
export function del(url, params) {
    return new Promise((resolve, reject) => {
        axios({
            method: "delete",
            url: url,
            data: params,
            headers: {
                'content-type': 'application/json', // 默认值
                'authorization': 'Bearer ' + localStorage.getItem('token'),
                'developer': 'eWei'
            }
        }).then(res => {
            console.warn("2")
            console.warn(res)
            if (res.data.code == 0) {
                resolve(res.data);
            } else if (res.data.code == 1) {
                Toast({
                    message: res.data.message,
                })
                console.warn("GET错误")
                console.warn(res)
                reject(res.data)
            } else {
                Toast({
                    message: "网络错误，请重试",
                })
                console.warn("DEL错误")
                console.warn(res)

                reject(res.data)
            }
        }).catch(err => {
            if (err.response.status == 401) {
                goLogin()
            } else {
                Toast({
                    message: "网络超时，请重试",
                    icon: 'none',
                    duration: 2000
                })
            }
            reject(err)
        })
    });
}