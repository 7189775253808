<template>
  <div class="container-index">
    正在跳转
    <!-- <button @click="go('login')">Web登录</button> -->
    <!-- <button @click="go('mp/index')">Go mpindex</button> -->
    <!-- <font-awesome-icon icon="user-secret" /> -->
  </div>
</template>

<script>

  export default {
    components: {

    },
    name: 'Index',
    props: {
      msg: String
    },
    data() {
      return {
        loading: false
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.go('login')
      },
      go(where) {
        this.$router.push('/'+where)
      }
    }
  }
</script>

<style scoped>
</style>