<script>
const device = null;
const nav = [
  {
    title: "首页",
    icon: "icon-home-o.png",
    iconActive: "icon-home.png",
    path: "desktop",
  },
  {
    title: "单号查询",
    icon: "icon-search-o.png",
    iconActive: "icon-search.png",
    path: "express/search",
  },
  {
    title: "小黑屋",
    icon: "icon-darkroom-o.png",
    iconActive: "icon-darkroom.png",
    path: "tools/darkroom",
  },
  // {
  //     title:"个人中心",
  //     icon:"icon-person-o.png",
  //     iconActive:"icon-person.png",
  //     path:"index"
  // },
];
const menu = [
  {
    id: 1,
    path: "desktop",
    name: "桌面",
    iconName: "icon-desktop.png",
    iconNameActive: "icon-desktop-active.png",
    subMenu: [],
  },
  {
    id: 2,
    path: "express",
    name: "快递",
    iconName: "icon-express.png",
    iconNameActive: "icon-express-active.png",
    subMenu: [
      {
        subId: 201,
        path: "express/search",
        name: "单号查询",
      },
      {
        subId: 202,
        path: "express/add",
        name: "录入新快递",
      },
      {
        subId: 203,
        path: "express/express",
        name: "快递跟踪",
      },
      {
        subId: 204,
        path: "express/cny2024",
        name: "2024春节",
        icon: "newyear",
      },
      {
        subId: 205,
        path: "express/important",
        name: "超48小时",
        icon: "important",
      },
      {
        subId: 206,
        path: "express/flag",
        name: "Flag",
        icon: "flag",
      },
      {
        subId: 207,
        path: "express/return",
        name: "退件",
      },
      {
        subId: 208,
        path: "express/data",
        name: "物流数据",
      },
      {
        subId: 209,
        path: "express/packaging",
        name: "打包扫码",
      },
      {
        subId: 211,
        path: "express/checkDay",
        name: "快速验货",
      },
      {
        subId: 210,
        path: "express/rule",
        name: "省内运输规则",
      },
      // {
      //     subId: 209,
      //     path: "express/uploadImg",
      //     name: "上传快递照片",
      // },
      // {
      //     subId: 207,
      //     path: "express/yunda",
      //     name: "韵达停滞查询",
      // },
      // {
      //     subId: 207,
      //     path: "express/setting",
      //     name: "快递设置",
      // },
    ],
  },
  {
    id: 3,
    path: "tools",
    name: "工具",
    iconName: "icon-tools.png",
    iconNameActive: "icon-tools-active.png",
    subMenu: [
      {
        subId: 301,
        path: "tools/darkroom",
        name: "小黑屋",
      },
      {
        subId: 302,
        path: "tools/badgeImage",
        name: "订货图生成",
      },
      // {
      //     subId: 303,
      //     path: "tools/consumables",
      //     name: "耗材"
      // }
    ],
  },
  {
    id: 99,
    path: "setting",
    name: "设置",
    iconName: "icon-setting.png",
    iconNameActive: "icon-setting-active.png",
    subMenu: [
      {
        subId: 9901,
        path: "setting/person",
        name: "成员管理",
      },
      {
        subId: 9902,
        path: "setting/company",
        name: "快递公司设置",
      },
      {
        subId: 9903,
        path: "setting/brand",
        name: "品牌管理",
      },
      {
        subId: 9904,
        path: "setting/platform",
        name: "平台管理",
      },
    ],
  },
];
export default {
  device, //设备信息
  menu, //菜单
  nav, //导航
};
</script>
