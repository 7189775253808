<template>
    <div class="content-nav">
        <div class="c-nav-title">
            功能
        </div>
        <div class="c-navbox">
            <div class="c-nav-main" v-for="(item, index) in finalMenu">
                <div class="c-nav-main-title" v-if="item.subMenu && item.subMenu.length > 0">{{ item.name }}</div>
                <div class="c-nav-submenu">
                    <div class="c-nav-submenu-item" v-for="(subItem, subIndex) in item.subMenu" :key="subIndex">
                        <router-link :to="subItem.path">{{ subItem.name }}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import Title from '@/components/common/title'
// import { apiName } from '@/request/api'

export default {
    components: {
        Title,
    },
    name: 'Index',
    // props: {
    //   msg: String
    // },
    data() {
        return {
            loading: false,
            device: this.GLOBAL.device,
            menu: this.GLOBAL.menu,
            finalMenu: [],
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            this.getRole()
        },
        getRole() {
            let role = JSON.parse(localStorage.getItem('role'))
            let menu = this.menu
            let finalMenu = []
            for (let index2 = 0; index2 < menu.length; index2++) {
                for (let index = 0; index < role.length; index++) {
                    if (role[index] == menu[index2].path) {
                        finalMenu.push(menu[index2])
                        break
                    }
                }

            }
            this.finalMenu = finalMenu
        },

    }
}
</script>
  
<style scoped>
.content-nav {
    width: 100%;
}

.c-nav-title {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-left: 10px;
}

.c-navbox {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    position: relative;
}

.c-nav-main {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;

}

.c-nav-main-title {
    font-size: 16px;
    font-weight: bold;
    color: #aaa;
    height: 30px;
    line-height: 30px;
    margin-right: 15px;
    padding-left: 10px;

}

.c-nav-submenu {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    width: 1px;
    flex: 1;
}

.c-nav-submenu-item {
    background-color: #e9e9eb;
    padding: 0 15px;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    font-size: 14px;
    margin-right: 8px;
    margin-bottom: 8px;
}

.c-nav-submenu-item a {
    color: #000;

}

/* mobile */
@media screen and (max-width: 480px) {}
</style>