<template>
    <div class="container">
        <div class="common-topbox">
            <!-- 标题区域 -->
            <Title title="快递跟踪"
                :intro="'未签收 ' + expNumber.unComplete + '单，派件和到代收点 ' + expNumber.pai + '单，签收率 ' + perFormate(expNumber.complete / expNumber.all * 100) + '%'">
                <template v-slot:right>
                    <!-- 右侧按钮区域插槽 -->
                    <div class="common-title-btn-secondary common-title-btn-icon copy" :data-clipboard-text="numbers"
                        v-tippy content="复制本页单号">
                        <font-awesome-icon icon="fa-solid fa-copy" />
                    </div>
                    <div class="common-title-btn-secondary common-title-btn-icon" @click="reflashAll()"
                        v-if="selectedCode != 807 && selectedCode != 808 && selectedCode != 809 && selectedCode != 810"
                        v-tippy content="更新本页物流">
                        <font-awesome-icon icon="fa-solid fa-arrow-rotate-right" />
                    </div>
                </template>
            </Title>
            <!-- 筛选 -->
            <FilterExpress :expNumber="expNumber" :statusCode="statusCode" :filterValue="filterValue"
                :selectedCode="selectedCode" :showFilterBtn="true" @changeStatus="changeStatus" @submitFilter="submitFilter"
                @resetFilter="resetFilter" />
        </div>
        <!-- 表格 -->
        <ExpressTable :tableData="tableData" v-if="tableData" />
        <div class="common-bottombox">
            <Pager :pageSizes="[16, 50, 100, 500]" :tablePage="tablePage" @handlePageChange="handlePageChange" />
        </div>
    </div>
</template>

<script>
import Clipboard from 'clipboard'
import Navbar from '@/components/common/navbar'
import ExpressTable from '@/components/express/expressTable'
import Title from '@/components/common/title'
import FilterExpress from '@/components/express/filterExpress'
import Pager from '@/components/common/pager'
import { Toast, Dialog } from 'vant';
import { apiGetExpressList, apiGetNumber, apiReflashExpress } from '@/request/api'
import { reflashAllExpress } from '@/utils/express'

export default {
    components: {
        Navbar,
        ExpressTable,
        Title,
        FilterExpress,
        Pager,
    },
    name: 'Index',
    // props: {
    //   msg: String
    // },
    data() {
        return {
            loading: false,
            tableData: null,
            tablePage: {
                currentPage: 1,
                pageSize: 100,
                totalResult: 0
            },
            filterValue: {
                com: null,
                platformId: null,
                brandId: null,
                overTime: null,
            },
            statusCode: [],
            selectedCode: null,
            expNumber: {
                all: null,
                unComplete: null,
                complete: null,
                pai: null,
            },
            numbers: null,
            device: this.GLOBAL.device,
        }
    },
    mounted() {
        // url参数
        let query = this.$route.query
        if (query.status) {
            this.selectedCode = query.status
        }
        this.init()
    },
    methods: {
        init() {
            this.initClipboard()
            // selectedCode
            this.getList()
            this.getStatusCode()
        },
        getList() {
            let self = this
            let params = {}
            params.page = this.tablePage.currentPage
            params.size = this.tablePage.pageSize
            if (this.selectedCode) {
                params.status = this.selectedCode
            }
            if (this.filterValue.com) {
                params.com = this.filterValue.com
            }
            if (this.filterValue.platformId) {
                params.platformId = this.filterValue.platformId
            }
            if (this.filterValue.brandId) {
                params.brandId = this.filterValue.brandId
            }
            if (this.filterValue.overTime) {
                let timestamp = Math.round(new Date().getTime() / 1000)
                params.kdLastTimeTo = timestamp - this.filterValue.overTime * 60 * 60
            }
            apiGetExpressList(params).then(res => {

                self.tableData = res.data.items
                self.tablePage.totalResult = res.data.count
                Toast.clear()
                self.getNumbers()
            }).catch(err => {
                console.warn(err)
                Toast.clear()
            })
        },
        submitFilter(filterValue) {
            this.filterValue = filterValue
            this.tablePage.currentPage = 1
            this.getList()
            this.getNumber()
        },
        resetFilter() {
            this.tablePage.currentPage = 1
            this.filterValue = {
                com: null,
                platformId: null,
                brandId: null,
                overTime: null,
            }
            this.getList()
            this.getNumber()
        },
        getStatusCode() {
            this.statusCode = JSON.parse(localStorage.getItem('constantStatusCode'))
            this.getNumber()
        },
        getNumber() {
            let self = this
            let params = {}
            if (this.filterValue.com) {
                params.com = this.filterValue.com
            }
            if (this.filterValue.platformId) {
                params.platformId = this.filterValue.platformId
            }
            if (this.filterValue.brandId) {
                params.brandId = this.filterValue.brandId
            }
            if (this.filterValue.overTime) {
                let timestamp = Math.round(new Date().getTime() / 1000)
                params.kdLastTimeTo = timestamp - this.filterValue.overTime * 60 * 60
            }
            apiGetNumber(params).then(res => {
                self.updateStatusCode(res.data)
                self.getAllNumber(res.data)
            }).catch(err => {
                console.warn(err)
            })
        },
        perFormate(number) {
            return parseInt(number)
        },
        updateStatusCode(data) {
            let statusCodeNew = JSON.parse(JSON.stringify(this.statusCode))
            for (let index = 0; index < statusCodeNew.length; index++) {
                statusCodeNew[index].number = data[statusCodeNew[index].key]
                let importantStatus = "801,802,803,808,810,811"
                if (importantStatus.indexOf(statusCodeNew[index].key) > -1) {
                    statusCodeNew[index].important = true
                }
            }
            this.statusCode = statusCodeNew
        },
        getAllNumber(data) {
            this.expNumber.all = data[801] + data[802] + data[803] + data[804] + data[805] + data[806] + data[807] + data[808] + data[809] + data[810] + data[811]
            this.expNumber.unComplete = data[801] + data[802] + data[803] + data[804] + data[805] + data[806] + data[808] + data[809] + data[810] + data[811]
            this.expNumber.complete = data[807]
            this.expNumber.pai = data[805] + data[806]
        },
        handlePageChange({ currentPage, pageSize }) {
            this.tablePage.currentPage = currentPage
            this.tablePage.pageSize = pageSize
            this.getList()
        },
        reflashAll() {
            Toast.loading('刷新中')
            let data = JSON.parse(JSON.stringify(this.tableData))
            // for (let index = 0; index < data.length; index++) {
            //     this.reflashOne(data[index])
            // }
            let promiseArr = data.map(async (item, index) => {
                await this.reflashOne(item, index)
            })
            Promise.all(promiseArr).then(itemList => {
                Toast.clear()
                Toast.success('全部刷新完成');
                this.getList()
            })
        },
        async reflashOne(oneData, index) {
            try {
                await apiReflashExpress(oneData).then(res => {
                    console.log(res)
                }).catch(err => {
                    console.warn(err)
                })
                Toast.loading('正在刷新 ' + index)
                return '成功'
            } catch (err) {
                console.warn(err)
                return '失败'
            }
        },
        getNumbers() {
            let result = ""
            for (let index = 0; index < this.tableData.length; index++) {
                result = result + '\n' + this.tableData[index].number
            }
            this.numbers = result
        },
        initClipboard() {
            const clipboard = new Clipboard(".copy");
            clipboard.on("success", function (e) {
                Toast.success('已复制到剪贴板');
                e.clearSelection();
            });
            clipboard.on("error", function (e) {
                Toast.fail('复制失败');
            });
        },
        changeStatus(id) {
            this.selectedCode = id
            this.tablePage.currentPage = 1
            this.getList()
            this.getNumber()
        },
    }
}
</script>

<style scoped></style>