<template>
    <div class="container-search">
        <div class="common-topbox">
            <!-- 标题区域 -->
            <Title title="单号查询">
            </Title>
            <!-- 输入框 -->
            <div class="searchbox">
                <!-- 筛选 箭头 -->
                <div class="search-function">
                    <div :class="seleted == 'express' ? 'search-option active' : 'search-option'"
                        @click="changeSeleted('express')">
                        物流单号
                    </div>
                    <div :class="seleted == 'order' ? 'search-option active' : 'search-option'"
                        @click="changeSeleted('order')">
                        订单号
                    </div>
                </div>
                <input class="search-input" v-model="number" type="text" @keyup.enter="search()"
                    :placeholder="seleted == 'express' ? '请输入物流单号' : '请输入订单号'" />
                <button class="search-btn" @click="search()">
                    <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
                </button>
            </div>
        </div>
        <div class="search-mainbox">
            <div class="search-left">
                <div class="search-min-title" style="padding: 0 40px;">
                    物流信息
                </div>
                <van-loading style="padding: 10px 40px;" color="#1989fa" v-if="loadingExp" />
                <!-- 表格 -->
                <ExpressTable :tableData="tableData" v-if="tableData" :showMobile="true" />
                <!-- <div class="common-bottombox" v-if="tableData">
                    <Pager :pageSizes="[10, 100]" :tablePage="tablePage" @handlePageChange="handlePageChange" />
                </div> -->
            </div>
            <div class="search-right">
                <div class="search-min-title" style="padding: 0 20px;">
                    发货照片
                </div>
                <van-loading style="padding: 10px 20px;" color="#1989fa" v-if="loadingPhoto" />
                <div class="search-modalbox-photo-box">
                    <div class="search-modalbox-photo" v-if="photoTimes">
                        <!-- 搜索框 -->
                        <div class="search-right-addcheckbox">
                            <div class="search-photo-search">
                                <input v-model="photoTimeSearchText" class="search-photo-input" type="text"
                                    placeholder="请输入 或 选择发货时间" />
                                <!-- 搜索按钮 -->
                                <button class="search-photo-search-btn" @click="searchPhotoBtn()">
                                    <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
                                </button>
                            </div>
                            <div class="search-right-addcheckbox-title">发货时间</div>
                            <div class="search-photo-time-box" v-if="photoTimes && photoTimes.length > 0">
                                <div class="search-photo-time" v-for="item in photoTimes" :key="item"
                                    @click="clickPhotoTime(item)">
                                    <div>{{ item }}</div>
                                </div>
                            </div>
                            <div class="search-photo-time-tip" v-else-if="photoTimes && photoTimes.length <= 0">
                                无发货时间
                            </div>
                            <van-loading style="padding: 10px 40px;" color="#1989fa" v-else />
                        </div>
                        <!-- Loading -->
                        <div class="search-right-addcheckbox" v-if="photos && photos.length > 0">
                            <div class="search-right-addcheckbox-title">照片</div>
                            <viewer class="search-photo-box" ref="viewer" :images="photos" :options="photoOptions">
                                <img class="search-photo-one" :src="item.src" :data-source="item.dataSource"
                                    v-for="item in photos" alt="" />
                                <div class="search-photo-loadmore" @click="loadMorePhoto()"
                                    v-if="this.photoLoadPage < 6">
                                    加载更多
                                </div>
                                <div class="search-photo-loadmore-end" v-else>
                                    到底了
                                </div>
                            </viewer>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
    import 'viewerjs/dist/viewer.css'
    import VueViewer from 'v-viewer'
    import Vue from 'vue'
    Vue.use(VueViewer)
    import Navbar from '@/components/common/navbar'
    import ExpressTable from '@/components/express/expressTable'
    import Title from '@/components/common/title'
    import Pager from '@/components/common/pager'
    import { Toast, Dialog, Loading } from 'vant';
    import { apiGetExpressList, apiGetExpressPhoto, apiGetPackList } from '@/request/api'
    import download from "downloadjs"

    export default {
        components: {
            Navbar,
            ExpressTable,
            Title,
            Pager,
        },
        name: 'Index',
        // props: {
        //   msg: String
        // },
        data() {
            return {
                loadingExp: false,
                loadingPhoto: false,
                tableData: null,
                seleted: 'express',
                tablePage: {
                    currentPage: 1,
                    pageSize: 50,
                    totalResult: 0
                },
                historyData: null,
                number: null,
                device: this.GLOBAL.device,
                //

                photoTimes: null,
                photoTimeSearchText: null,
                photoLoadPage: 1,
                photos: [],
                photoOptions: {
                    toolbar: {
                        zoomIn: 2,
                        zoomOut: 2,
                        oneToOne: 2,
                        reset: 2,
                        rotateLeft: 2,
                        rotateRight: 2,
                        prev: 1,
                        next: 1,
                        flipHorizontal: 0,
                        flipVertical: 0,
                        play: 0,
                        download: {
                            show: 1,
                            title: '下载图片',
                            icon: 'custom-icon-download', // 自定义图标类名，需要自己定义CSS样式
                            click: () => {
                                const viewer = this.$refs.viewer;
                                const index = viewer.$viewer.index;
                                const img = viewer.images[index];
                                console.log('点击的图片:', img);
                                if (img) {
                                    let url = img.dataSource
                                    let filename = img.filename
                                    this.downloadItem(url, filename)
                                } else {
                                    console.log('未找到图片元素');
                                }
                            }
                        }
                    },
                    title: 2,
                    url: 'data-source',
                    zIndex: 10000,
                },

            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {
            },
            search() {
                // null
                if (!this.number) {
                    Toast.fail('请输入单号')
                    return
                }
                this.tableData = null
                this.photos = []
                this.photoTimes = null
                this.photoLoadPage = 1
                this.photoTimeSearchText = null
                this.getList()
                if (this.seleted == "express") {
                    this.getPhotoTime(this.number)
                }
            },
            getList() {
                let self = this
                this.loadingExp = true
                let params = {}
                params.page = this.tablePage.currentPage
                params.size = this.tablePage.pageSize
                // 删除前后空格
                this.number = this.number.trim()
                if (this.seleted == "express") {
                    params.number = this.number
                } else {
                    params.orderNumber = this.number
                }
                apiGetExpressList(params).then(res => {
                    self.tableData = res.data.items
                    self.tablePage.totalResult = res.data.count
                    if (this.seleted == 'order') {
                        self.getPhotoTime(self.tableData[0].number)
                    }
                    self.loadingExp = false
                }).catch(err => {
                    self.loadingExp = false
                    console.warn(err)
                })
            },
            changeSeleted(type) {
                this.seleted = type
            },
            handlePageChange({ currentPage, pageSize }) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getList()
            },
            //发货时间和照片
            getPhotoTime(value) {
                let self = this
                this.loadingPhoto = true
                let number = value
                let params = {
                    number: number
                }
                apiGetPackList(params).then(res => {
                    let data = res.data.items
                    let times = []
                    for (let i = 0; i < data.length; i++) {
                        let time = data[i].createTime
                        time = self.formatTimePhoto(time)
                        times.push(time)
                    }
                    // 去重
                    times = Array.from(new Set(times))
                    self.photoTimes = times
                    if (times.length > 0) {
                        self.photoTimeSearchText = times[0]
                        self.searchPhoto()
                    }
                    self.loadingPhoto = false
                }).catch(err => {
                    self.loadingPhoto = false
                    Toast.clear()
                    // self.submitFailer()
                })

            },
            searchPhoto(time) {
                let self = this
                let params
                if (time) {
                    params = {
                        filename: time
                    }
                } else {
                    params = {
                        filename: this.photoTimeSearchText
                    }
                }
                Toast.loading({
                    message: '加载中',
                    forbidClick: true,
                    duration: 0
                })
                apiGetExpressPhoto(params).then(res => {
                    let data = res.data
                    let photos = data
                    // 没有照片
                    if (photos.length <= 0) {
                        Toast.fail('此时间没有照片')
                        return
                    }
                    let newPhotos = []
                    //  加上网址 https://mrp-express-photo.moemee.com/
                    for (let i = 0; i < photos.length; i++) {
                        let item = {
                            src: 'https://mrp-express-photo.moemee.com/' + photos[i] + '?x-oss-process=style/sq',
                            dataSource: 'https://mrp-express-photo.moemee.com/' + photos[i] + '?x-oss-process=style/hd',
                            filename: photos[i].split('/').pop()
                        }
                        newPhotos.push(item)
                    }
                    self.photos.push(...newPhotos)
                    if (self.photoLoadPage == 1) {
                        self.loadMorePhoto()
                    }
                    Toast.clear()
                }).catch(err => {
                    console.warn(err);
                    // self.submitFailer()
                    Toast.clear()
                })
            },
            loadMorePhoto() {
                let newTime = this.timeMore(this.photoLoadPage)
                this.photoLoadPage++
                this.searchPhoto(newTime)
            },
            timeMore(value) {
                // 点击加载更多，吧搜索的photoTimeSearchText时间减1分钟
                // 例如：20240519_1421 变为20240519_1420
                if (!this.photoTimeSearchText) {
                    return
                }
                let timeArr = this.photoTimeSearchText.split('_')
                let data = timeArr[0] // 这个是年月日
                let time = timeArr[1] // 这个是时+分
                let hour = time.slice(0, 2)
                let minute = time.slice(2, 4)
                // 减 value 分钟
                minute = parseInt(minute) - value
                if (minute < 0) {
                    minute = 59
                    hour = parseInt(hour) - 1
                    if (hour < 0) {
                        hour = 23
                        let date = data.slice(6, 8)
                        date = parseInt(date) - 1
                        if (date < 10) {
                            date = '0' + date
                        }
                        data = data.slice(0, 6) + date
                    }
                }
                if (minute < 10) {
                    minute = '0' + minute
                }
                if (hour < 10) {
                    hour = '0' + hour
                }
                let newTime = data + '_' + hour + minute
                return newTime
            },
            downloadPhoto() {
                const viewer = this.$refs.viewer.viewer;
                if (viewer) {
                    const img = viewer.image;
                    if (img) {
                        // 创建并触发下载
                        const a = document.createElement('a');
                        a.href = img.src;
                        a.download = img.alt || 'download';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }
                }
            },
            formatTimePhoto(value) {
                let time = new Date(value)
                let year = time.getFullYear()
                let month = time.getMonth() + 1
                let date = time.getDate()
                let hours = time.getHours()
                let minute = time.getMinutes()
                let second = time.getSeconds()
                if (month < 10) { month = '0' + month }
                if (date < 10) { date = '0' + date }
                if (minute < 10) { minute = '0' + minute }
                if (second < 10) { second = '0' + second }
                return year + month + date + '_' + hours + minute
            },
            downloadItem(dataURL, filename) {
                let x = new XMLHttpRequest();
                x.open("GET", dataURL, true);
                x.responseType = "blob";
                x.onload = function (e) {
                    download(e.target.response, filename, "image/jpeg");
                };
                x.send();
            },
        }
    }
</script>

<style scoped>
    .container-search {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    /* 搜索区域 */
    .searchbox {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 15px;
    }

    .search-input {
        flex: 1;
        border: 1px solid #e9e9eb;
        outline: none;
        height: 32px;
        border-radius: 16px 0 0 16px;
        padding: 0 15px;
        font-size: 14px;
    }

    .search-btn {
        width: 80px;
        border: none;
        outline: none;
        height: 32px;
        background-color: #e9e9eb;
        color: #333;
        border-radius: 0 16px 16px 0;
        cursor: pointer;
    }

    .search-btn:hover {
        opacity: 0.7;
    }

    /* 选择分类 */
    .search-function {
        width: 190px;
        margin-right: 10px;
        height: 32px;
        border-radius: 16px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        overflow: hidden;
    }

    .search-option {
        background-color: #e9e9eb;
        color: #999;
        height: 32px;
        line-height: 32px;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        border-right: 1px solid #fff;
        flex: 1;
    }

    .search-option:last-child {
        border-right: none;
    }

    .search-option.active {
        background-color: #000;
        color: #fff;
    }

    .search-option:hover {
        color: #333;

    }

    .search-mainbox {
        flex: 1;
        display: flex;
        flex-direction: row;
        overflow: hidden;
    }

    .search-left {
        width: 50%;
        flex: 1;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
    }

    .search-right {
        width: 50%;
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .search-min-title {
        font-size: 14px;
        font-weight: bold;
    }



    .search-right-addcheckbox {
        margin-bottom: 15px;
    }

    .search-right-addcheckbox-title {
        margin-bottom: 10px;
        font-size: 14px;
        color: #666;
    }


    /*  */

    .search-modalbox-photo-box {
        overflow-y: auto;
        flex: 1;
        padding: 15px 20px 15px;
    }

    .search-modalbox-photo {
        background: #FBFBFB;
        border: 1px solid #EFEEEE;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, .1);
        border-radius: 20px;
        padding: 15px;
        margin-bottom: 15px;
        position: relative;
    }

    .search-right-addcheckbox {
        margin-bottom: 15px;
    }

    .search-right-addcheckbox-title {
        margin-bottom: 10px;
        font-size: 14px;
        color: #666;
    }

    .search-photo-time-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
    }

    .search-photo-time {
        font-size: 14px;
        padding: 4px 10px;
        margin-bottom: 10px;
        margin-right: 10px;
        background-color: #e9e9eb;
        color: #666;
        border-radius: 6px;
        cursor: pointer;
    }

    .search-photo-time-tip {
        font-size: 14px;
        color: #666;
        margin-bottom: 10px;
    }

    .search-photo-search {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
    }

    .search-photo-input {
        flex: 1;
        border: 1px solid #e9e9eb;
        outline: none;
        height: 32px;
        border-radius: 16px 0 0 16px;
        padding: 0 15px;
        font-size: 14px;
    }

    .search-photo-search-btn {
        width: 80px;
        border: none;
        outline: none;
        height: 32px;
        background-color: #e9e9eb;
        color: #333;
        border-radius: 0 16px 16px 0;
        cursor: pointer;
    }

    .search-photo-box {
        display: flex;
        flex-wrap: wrap;
        overflow-x: hidden;
        overflow-y: auto;
        padding-bottom: 5px;
    }

    .search-photo-one {
        display: block;
        width: 19%;
        height: auto;
        margin-right: 1%;
        margin-bottom: 1%;
        cursor: pointer;
        border-radius: 2%;
    }

    @media screen and (max-width:768px) {

        .search-photo-one {
            width: 49%;
            margin-right: 1%;
        }

        .search-mainbox {
            display: block;
            overflow-x: hidden;
            overflow-y: auto;
        }

        .search-left {
            width: 100%;
            /* min-height: 400px; */
        }

        .search-right {
            width: 100%;
            display: block;
        }

        .search-min-title {
            padding: 0 20px !important;
            margin-top: 15px;
        }

        .search-modalbox-photo-box {

            padding: 15px;
        }
    }

    .search-photo-loadmore {
        width: 100%;
        text-align: center;
        height: 30px;
        line-height: 30px;
        color: #fff;
        cursor: pointer;
        margin-top: 10px;
        border-radius: 15px;
        background-color: #b8a3ed;
    }

    .search-photo-loadmore:hover {
        background-color: #a18de0;
    }

    .search-photo-loadmore-end {
        width: 100%;
        text-align: center;
        height: 30px;
        line-height: 30px;
        color: #666;
        cursor: pointer;
        margin-top: 10px;
        border-radius: 15px;
    }

    /* mobile */
    @media screen and (max-width: 480px) {
        .searchbox {
            padding: 0 15px;
            flex-wrap: wrap;
        }

        .search-function {
            width: 100%;
            height: 28px;
            border-radius: 14px;
            margin-right: 0;
            margin-bottom: 15px;
        }

        .search-option {
            height: 28px;
            line-height: 28px;
            font-size: 13px;
        }

        .search-input {
            height: 40px;
            border-radius: 20px 0 0 20px;
        }

        .search-btn {
            height: 40px;
            border-radius: 0 20px 20px 0;
        }
    }
</style>


<style>
    .viewer-download {
        color: #fff;
        font-size: 0.45rem;
        line-height: 1.5rem;
        text-align: center;
    }

    .viewer-download::before {
        content: "下载";

    }
</style>