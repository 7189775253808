<template>
    <!-- 标题区域 -->
    <div :class="item.type == 'folder' ? 'content-folderShow' : 'content-fileShow'">
        <!-- 文件夹 -->
        <div class="c-fileshow-folder" v-if="item.type == 'folder'">
            <!-- 根目录文件夹 -->
            <div class="folder-title"
                v-if="item.name.indexOf('老款') > -1 || item.name.indexOf('新款') > -1 || item.name.indexOf('未到') > -1">
                <img class="folder-icon" src="/oss-assets/badge/icon-folder.png" />
                <span>{{ item.name }}</span>
                <img v-if="item.name.indexOf('糖果袋') > -1" class="folder-icon2"
                    src="https://mrp.moemee.com/oss-assets/cover/icon-tang.png" />
                <img v-if="item.name.indexOf('普通袋') > -1" class="folder-icon2"
                    src="https://mrp.moemee.com/oss-assets/cover/icon-zi.png" />
            </div>
            <!-- 带有数量的文件夹 -->
            <div class="folder-number" v-else-if="item.name.indexOf('【') > -1">
                <div class="folder-number-title">
                    <div class="folder-number-black">单款对应多张设计图</div>
                    <div>{{ getName(item.name) }}</div>
                </div>
                <div class="folder-number-box">
                    <div class="folder-number-single">
                        <div class="folder-number-single-title">
                            定量
                        </div>
                        <div class="folder-number-single-value">
                            {{ getNumber(item.name) }}
                        </div>
                    </div>
                    <div class="folder-number-single">
                        <div class="folder-number-single-title">
                            实发
                        </div>
                        <div class="folder-number-single-value">
                            &nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <!-- 普通文件夹 -->
            <div class="folder-name" v-else>
                <span>{{ item.name }}</span>
            </div>
        </div>
        <!-- 文件 -->
        <!-- 图片 -->
        <div class="c-fileshow-img" v-else-if="item.originType === 'image/jpeg' || item.originType === 'image/png'">
            <div class="img-wrap img-wrap-tip" v-if="/(配件|说明|实物图)/.test(item.name)">
                <div class="img-show-box">
                    <img class="img-show" :src="item.src" alt="">
                </div>
                <div class="img-intro">
                    {{ item.name }}
                </div>
            </div>
            <div class="img-wrap" v-else>
                <img class="img-show" :src="item.src" alt="" style="width: 100%;">
                <div :class="item.name.indexOf('【') > -1 ? 'img-name' : 'img-name circle'">{{ getName(item.name) }}
                </div>
                <div class="img-number" v-if="item.name.indexOf('【') > -1">
                    <div class="img-number-title">订量</div>
                    <div>{{ getNumber(item.name) }}</div>
                </div>
                <div class="img-sq" v-if="item.name.indexOf('【') > -1">
                    <div>实发</div>
                </div>
            </div>
        </div>
        <!-- PSD -->
        <div class="c-fileshow-img" v-else-if="item.name.indexOf('.psd') > -1">
            <div class="img-show-box">
                <img class="img-show" :src="item.src" alt="" style="width: 100%;">
            </div>
            <div :class="item.name.indexOf('【') > -1 ? 'img-name' : 'img-name circle'">{{ getName(item.name) }}</div>
            <div class="img-number" v-if="item.name.indexOf('【') > -1">
                <div class="img-number-title">订量</div>
                <div>{{ getNumber(item.name) }}</div>
            </div>
            <div class="img-sq" v-if="item.name.indexOf('【') > -1">
                <div>实发</div>
            </div>
        </div>
        <!-- 表格 -->
        <div class="c-fileshow-xlsx"
            v-else-if="item.originType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'">
            <!-- 表格不显示 -->
        </div>
        <div class="c-fileshow-info" v-else>
            文件名: {{ item.name }}, 文件类型: {{ item.originType }}, info: {{ item }}
        </div>
    </div>
</template>

<script>
    // import { apiName } from '@/request/api'

    export default {
        components: {

        },
        name: 'Index',
        props: {
            item: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {
                loading: false,
                device: this.GLOBAL.device,
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {

            },
            getNumber(val) {
                // 正则获取【 】大括号中的文字
                let reg = /\【(.+?)\】/g
                let arr = val.match(reg)
                if (arr && arr.length > 0) {
                    return arr[0].replace('【', '').replace('】', '')
                }
                return ''
            },
            getName(val) {
                // 正则获取】后的文字
                let reg = /\】(.+?)$/g
                let arr = val.match(reg)
                console.log(arr)
                if (arr && arr.length > 0) {
                    return arr[0].replace('】', '')
                }
                return val
            }
        }
    }
</script>

<style scoped>
    .content-fileShow {
        display: block;
        font: "苹方", "思源黑体", "微软雅黑", "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    .content-folderShow {
        /* width: 100%; */
        flex-basis: 100%;
    }

    .c-fileshow-info {
        font-size: 12px;
        color: #999;
        margin-bottom: 15px;
    }

    /* 图片 */
    .c-fileshow-img {
        max-width: 120px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        margin-right: 10px;
        margin-bottom: 10px;
        justify-content: center;
        align-items: center;
    }

    .tree-file:last-child .c-fileshow-img {
        margin-right: 0;
    }

    .c-fileshow-img .img-show-box {
        width: 120px;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        overflow: hidden;
        z-index: 1;
    }

    .c-fileshow-img .img-show {
        width: 120px;
        object-fit: cover;
        border-radius: 6px;
        z-index: 1;
    }

    .img-wrap-tip {
        width: 80px;
        max-height: 180px;
        overflow: hidden;
    }

    .img-wrap-tip .img-show-box {
        width: 80px;
        height: 100px;
    }

    .img-wrap-tip .img-show {
        width: 80px;
        object-fit: contain;
        border-radius: 6px;
        z-index: 1;
    }

    .c-fileshow-img .img-intro {
        width: 100%;
        padding: 4px;
        color: #000;
        font-size: 14px;
    }

    .c-fileshow-img .img-number {
        width: 100%;
        font-size: 20px;
        height: 30px;
        line-height: 30px;
        color: #000;
        background-color: #fff;
        border: 1px solid #000;
        border-bottom: 0;
        font-weight: bold;
        text-align: center;
        padding: 0 5px;
        position: relative;
    }

    .c-fileshow-img .img-number-title {
        position: absolute;
        left: 4px;
        top: 50%;
        color: #bbb;
        text-align: left;
        font-size: 12px;
        line-height: 12px;
        height: 12px;
        margin-top: -6px;
        font-weight: bold;
    }

    .c-fileshow-img .img-name {
        position: relative;
        border: 2px solid #000;
        border-radius: 10px 10px 0 0;
        width: 100%;
        font-size: 12px;
        height: 30px;
        line-height: 30px;
        color: #fff;
        background-color: #000;
        padding: 0 5px;
        overflow: hidden;
        text-overflow: clip;
        word-break: keep-all;
        white-space: nowrap;
        margin-top: -30px;
        text-align: center;
        z-index: 2;
        box-sizing: border-box;
    }

    .c-fileshow-img .img-name.circle {
        height: 40px;
        line-height: 18px;
        border-radius: 10px;
        word-break: break-all;
        white-space: normal;
    }

    .c-fileshow-img .img-sq {
        width: 100%;
        border: 1px solid #000;
        font-weight: bold;
        text-align: left;
        padding: 0 5px;
        height: 30px;
        line-height: 30px;
        border-radius: 0 0 10px 10px;
        font-size: 12px;
        color: #bbb;
    }


    .c-fileshow-img .img-bk {
        width: 100%;
        margin-top: -5px;
        height: 10px;
        border-radius: 0 0 10px 10px;
        background-color: #000;
    }

    /* 文件夹 */
    .c-fileshow-folder {
        width: 100%;
    }

    .c-fileshow-folder .folder-name {
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        max-width: 100%;
        border-top: 2px dotted #000;
        /* background-color: #000; */
        color: #000;
        padding: 5px 5px;
        margin-bottom: 5px;
        margin-top: 4px;
    }

    .c-fileshow-folder .folder-title {
        width: 100%;
        font-size: 24px;
        line-height: 1;
        font-weight: bold;
        width: 100%;
        padding: 5px 5px;
        margin-top: 20px;
        /* border-left: 20px dotted #000; */
        /* background-color: #fff; */
        color: #000;
        /* border: 2px solid #000; */
    }

    .c-fileshow-folder .folder-number {
        width: 100%;
        font-size: 16px;
        font-weight: bold;
        width: 100%;
        border-top: 2px dotted #000;
        /* background-color: #000; */
        color: #000;
        padding: 5px 5px;
        margin-bottom: 10px;
        margin-top: 4px;
    }

    .folder-number-title {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .folder-number-black {
        font-size: 12px;
        height: 22px;
        line-height: 22px;
        background-color: #000;
        color: #fff;
        display: block;
        padding: 0 5px;
        margin-right: 5px;
        border-radius: 4px;
    }

    .folder-number-box {
        /* width: 200px; */
        display: flex;
        flex-direction: row;
        margin-top: 8px;
    }

    .folder-number-single {
        width: 122.5px;
        border-left: 2px solid #000;
        border-top: 2px solid #000;
        border-bottom: 2px solid #000;
        padding: 5px 5px;
    }

    .folder-number-single:first-child {
        border-radius: 10px 0 0 10px;
    }

    .folder-number-single:last-child {
        border-right: 2px solid #000;
        border-radius: 0 10px 10px 0;
    }

    .folder-number-single-title {
        font-size: 12px;
        color: #bbb;
    }

    .folder-number-single-value {
        font-size: 20px;
    }

    .folder-icon {
        width: 24px;
        height: 24px;
        display: inline-block;
        margin-right: 4px;
        position: relative;
        top: 3px;
    }

    .folder-icon2 {
        width: 30px;
        height: 30px;
        display: inline-block;
        /* margin-right: 4px; */
        position: relative;
        top: 6px;
    }


    /* mobile */
    @media screen and (max-width: 480px) {}
</style>