<template>
    <div class="container">
        <div class="common-topbox">
            <!-- 标题区域 -->
            <Title title="Flag" intro="标记重点关注的快递">
                <template v-slot:right>
                    <!-- 右侧按钮区域插槽 -->
                    <div class="common-title-btn-secondary common-title-btn-icon" @click="reflashAll()" v-tippy
                        content="更新本页物流">
                        <font-awesome-icon icon="fa-solid fa-arrow-rotate-right" />
                    </div>
                    <!-- <div class="common-title-btn-main" @click="openFlagModal()">
                        添加Flag快递
                    </div> -->
                </template>
            </Title>
            <!-- 筛选功能 -->
            <div class="selectbox">
                <div class="select-left">
                    <div :class="selectedFlag == 'all' ? 'select-single active' : 'select-single'"
                        @click="changeFlag('all')">
                        <div class="select-name">
                            全部
                        </div>
                    </div>
                    <div :class="selectedFlag == item.key ? 'select-single active' : 'select-single'"
                        v-for="(item, index) in flags" @click="changeFlag(item.key)">
                        <div class="select-name" v-if="device == 'mobile'">
                            <span v-if="item.key == 'straw'">🍓 重点</span>
                            <span v-if="item.key == 'orang'">🍊 仅退款</span>
                            <span v-if="item.key == 'grape'">🍇 已处理</span>
                        </div>
                        <div class="select-name" v-else>
                            <span v-if="item.key == 'straw'">🍓 重点关注问题件</span>
                            <span v-if="item.key == 'orang'">🍊 发货仅退款</span>
                            <span v-if="item.key == 'grape'">🍇 客服已回复处理结果</span>
                        </div>
                        <div :class="item.important ? 'select-number active' : 'select-number'"
                            v-if="item.number && item.number > 0">
                            {{ item.number }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 表格 -->
        <ExpressTable :tableData="tableData" v-if="tableData" />
        <div class="common-bottombox">
            <Pager :pageSizes="[16, 50, 100, 500]" :tablePage="tablePage" @handlePageChange="handlePageChange" />
        </div>
        <!-- 弹窗 -->
        <van-dialog v-model:show="showModal" @confirm="submit()" theme="round" title="添加Flag快递" show-cancel-button
            confirm-button-text="提交" confirm-button-color="#6667ab" cancel-button-text="取消" cancel-button-color="#666">
            <div class="common-modalbox">
                <textarea class="modal-add-numbers" placeholder="快递单号(使用空格或换行分隔多个单号)"></textarea>
                <select class="modal-add-select" placeholder="旗帜颜色">
                    <option>选择旗帜色彩</option>
                    <option>重点关注问题件🍓</option>
                    <option>发货仅退款🍊</option>
                    <option>客服已回复处理结果🍇</option>
                </select>
                <textarea class="modal-add-textarea" placeholder="备注（选填）"></textarea>
            </div>
        </van-dialog>
    </div>
</template>

<script>
import Navbar from '@/components/common/navbar'
import ExpressTable from '@/components/express/expressTable'
import Title from '@/components/common/title'
import Pager from '@/components/common/pager'
import { Toast, Dialog } from 'vant';
import { apiGetExpressList, apiGetFlagNumber } from '@/request/api'

export default {
    components: {
        Navbar,
        ExpressTable,
        Title,
        Pager,
    },
    name: 'Index',
    // props: {
    //   msg: String
    // },
    data() {
        return {
            loading: false,
            showModal: false,
            tableData: null,
            tablePage: {
                currentPage: 1,
                pageSize: 100,
                totalResult: 0
            },
            companyList: null,
            brandList: null,
            platformList: null,
            flags: [],
            selectedFlag: 'all',
            device: this.GLOBAL.device,
        }
    },
    mounted() {
        this.init()
    },

    methods: {
        init() {
            this.getCompanyList()
            this.getBrandList()
            this.getPlatformList()
            this.getFlagList()
            this.getList()
        },
        getList() {
            let self = this
            let params = {}
            params.page = this.tablePage.currentPage
            params.size = this.tablePage.pageSize
            params.flag = this.selectedFlag
            apiGetExpressList(params).then(res => {
                self.tableData = res.data.items
                self.tablePage.totalResult = res.data.count
            }).catch(err => {
                console.warn(err)
            })
        },
        getCompanyList() {
            this.companyList = JSON.parse(localStorage.getItem('companyList'))
        },
        getBrandList() {
            this.brandList = JSON.parse(localStorage.getItem('brandList'))
        },
        getPlatformList() {
            this.platformList = JSON.parse(localStorage.getItem('platformList'))
        },
        getFlagList() {
            this.flags = JSON.parse(localStorage.getItem('constantFlag'))
            this.getNumber()
        },
        getNumber() {
            let self = this
            apiGetFlagNumber().then(res => {

                self.updateStatusCode(res.data)
            }).catch(err => {
                console.warn(err)
            })
        },
        updateStatusCode(data) {
            let flagNew = JSON.parse(JSON.stringify(this.flags))
            flagNew.forEach((item, index) => {
                item.number = data[item.key]
            })
            this.flags = flagNew
        },
        changeFlag(id) {
            this.selectedFlag = id
            this.tablePage.currentPage = 1
            this.getList()
            this.getNumber()
        },
        openFlagModal() {
            this.showModal = true
        },
        handlePageChange({ currentPage, pageSize }) {
            this.tablePage.currentPage = currentPage
            this.tablePage.pageSize = pageSize
            this.getList()
        },
        reflashAll() {
            Toast.loading('刷新中')
            let data = JSON.parse(JSON.stringify(this.tableData))
            // for (let index = 0; index < data.length; index++) {
            //     this.reflashOne(data[index])
            // }
            let promiseArr = []
            data.forEach((item, index) => {
                promiseArr.push((async (item) => {
                    await this.reflashOne(item, index)
                })(item))
            })
            Promise.all(promiseArr).then(itemList => {
                Toast.clear()
                Toast.success('全部刷新完成');
                this.getList()
            })
        },
        submit() {

        },
    }
}
</script>

<style scoped>
/* 弹窗 手动录入快递 */
.modal-add-select {
    width: 100%;
    height: 30px;
    border-radius: 15px;
    border: 1px solid #e6e6ef;
    outline: none;
    margin-bottom: 15px;
    padding: 0 10px;
    font-size: 14px;
    color: #333;
}

.modal-add-numbers {
    width: 100%;
    height: 200px;
    border-radius: 15px;
    border: 1px solid #e6e6ef;
    outline: none;
    padding: 10px 10px;
    font-size: 14px;
    color: #333;
    resize: none;
    margin-bottom: 15px;
}

.modal-add-textarea {
    width: 100%;
    height: 80px;
    border-radius: 15px;
    border: 1px solid #e6e6ef;
    outline: none;
    padding: 10px 10px;
    font-size: 14px;
    color: #333;
    resize: none;
}

/* 筛选功能 */
.selectbox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
}

.select-left {
    width: 1px;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

}

.select-single {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #e9e9eb;
    color: #666;
    border-right: 1px solid #fff;
    height: 32px;
    cursor: pointer;
    padding: 0 6px;
}

.select-single:first-child {
    border-radius: 16px 0 0 16px;
    padding-left: 4px;
}

.select-single:last-child {
    border-radius: 0 16px 16px 0;
    border-right: none;
    padding-right: 4px;
}

.select-single:hover {
    opacity: 0.7;
}

.select-name {
    font-size: 14px;
    word-break: keep-all;
    white-space: nowrap;
}

.select-number {
    margin-left: 3px;
    font-size: 12px;
    background-color: #e9e9eb;
    font-weight: bold;
    color: #666;
    padding: 0 4px;
    height: 18px;
    min-width: 18px;
    text-align: center;
    line-height: 18px;
    border-radius: 9px;
    word-break: keep-all;
    white-space: nowrap;
}

.select-number.active {
    background-color: #fd4343;
    color: #fff;
}

.select-single.active {
    background-color: #000;
    color: #fff;
}

/* mobile */
@media screen and (max-width: 480px) {
    .selectbox {
        padding: 0 15px;
    }
}
</style>