<template>
    <div class="content-navbarmobile" v-if="selectIndex != null">
        <div class="navbarmobile-box" v-for="(item, index) in finalNav" @click="go(item.path, index)">
            <div class="navbarmobile-icon">
                <img v-if="selectIndex == index" :src="'/oss-assets/navbar/' + item.iconActive" alt="">
                <img v-else :src="'/oss-assets/navbar/' + item.icon" alt="">
            </div>
            <div class="navbarmobile-text">
                {{ item.title }}
            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    components: {
    },
    name: 'Index',
    data() {
        return {
            loading: false,
            device: this.GLOBAL.device,
            showNavbar: false,
            nav: this.GLOBAL.nav,
            finalNav: [],
            selectIndex: null,
        }
    },
    mounted() {
        this.init()
    },
    watch: {
        $route(to, from) {
            console.log(to.path);
            this.checkNavbar()
        }
    },
    methods: {
        init() {
            this.checkNavbar()
            this.getRole()
        },

        getRole() {
            let role = JSON.parse(localStorage.getItem('role'))
            let menu = this.menu
            console.log(role);
            let finalNav = []
            finalNav.push(this.nav[0])
            if (role.indexOf('express') != -1) {
                finalNav.push(this.nav[1])
            }
            if (role.indexOf('tools') != -1) {
                finalNav.push(this.nav[2])
            }
            this.finalNav = finalNav
        },
        checkNavbar() {
            this.selectIndex = null
            for (let index = 0; index < this.nav.length; index++) {
                if ('/' + this.nav[index].path == this.$route.path) {
                    this.selectIndex = index
                }
            }

        },
        go(where, index) {
            this.selectIndex = index
            this.$router.push('/' + where)
        },
    }
}
</script>
  
<style scoped>
.content-navbarmobile {
    position: relative;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #eee;
    display: flex;
    flex-direction: row;
}

.navbarmobile-box {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
    padding-bottom: constant(safe-area-inset-bottom);
    /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom);
    /* 兼容 iOS >= 11.2 */
}

.navbarmobile-icon {
    padding: 10px;
}

.navbarmobile-icon img {
    width: 20px;
    height: 20px;
    display: block;
}

.navbarmobile-text {
    width: 100%;
    text-align: center;
    font-size: 12px;
    line-height: 1;
    padding-bottom: 8px;
}
</style>