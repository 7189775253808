<template>
    <div class="container-darkroom">
        <div class="common-topbox">
            <!-- 标题区域 -->
            <Title title="小黑屋">
                <template v-slot:right>
                    <!-- 右侧按钮区域插槽 -->
                    <div class="common-title-btn-secondary common-title-btn-icon copy" :data-clipboard-text="keywords"
                        v-tippy content="复制本页关键词">
                        <font-awesome-icon icon="fa-solid fa-copy" />
                    </div>
                    <!-- 按钮 -->
                    <div class="common-title-btn-main common-title-btn-icon" @click="openModal('add')" v-tippy
                        content="添加黑名单">
                        <font-awesome-icon icon="fa-solid fa-plus" />
                    </div>
                </template>
            </Title>
            <!-- 搜索框 -->
            <div class="searchbox">
                <!-- 筛选 箭头 -->
                <input class="searchbox-input" v-model="searchWord" type="text" @keyup.enter="search()"
                    placeholder="请输入关键词" ref="inputRef" />
                <button class="searchbox-btn" @click="search()">
                    <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
                </button>
                <div class="searchbox-clear" v-if="searchWord && searchWord.length > 0" @click="clearSearch()">
                    <font-awesome-icon icon="fa-solid fa-circle-xmark" />
                </div>
            </div>
        </div>
        <div class="common-midbox">
            <!-- 卡片 h5 -->
            <div class="darkCard" v-if="device == 'mobile'">
                <div class="darkCard-Single" v-for="item in tableData">
                    <div class="darkCard-keyword copy" :data-clipboard-text="item.keyword">
                        {{ item.keyword }}
                    </div>
                    <div class="darkCard-address">
                        {{ item.address }}
                    </div>
                    <div class="darkCard-platform copy" :data-clipboard-text="item.orderNumber">
                        <div class="darkCard-platform-name" v-for="item2 in platformList"
                            v-if="item.platformId == item2.id">
                            {{ item2.name }}
                        </div>
                        <div class="darkCard-platform-number">
                            {{ item.orderNumber }}
                        </div>
                    </div>
                    <div class="darkCard-remark">
                        {{ item.remark || '暂无备注' }}
                    </div>
                    <div :class="'darkCard-level darkCard-level-' + item.level">
                        <span v-if="item.level == 3">严重</span>
                        <span v-if="item.level == 2">中等</span>
                        <span v-if="item.level == 1">一般</span>
                    </div>
                    <div class="darkCard-btnbox">
                        <vxe-button class="darkCard-btn-circle" @click="edit(item)" round>
                            <font-awesome-icon icon="fa-solid fa-pen-to-square" /> 编辑
                        </vxe-button>
                        <vxe-button class="darkCard-btn-circle" @click="del(item)" round>
                            <font-awesome-icon icon="fa-solid fa-trash-can" /> 删除
                        </vxe-button>
                        <div class="darkCard-createtime">
                            {{ formatDate(item.createAt) }} 登记
                        </div>
                    </div>
                    <!-- 循环结束 -->
                </div>

                <infinite-loading @infinite="infiniteHandler" force-use-infinite-wrapper=".common-midbox">
                    <div slot="no-more">已经到底了</div>
                    <div slot="no-results">没有结果</div>
                </infinite-loading>
            </div>
            <!-- 表格 PC-->
            <vxe-table round border highlight-hover-row resizable ref="xTable" :data="tableData"
                v-if="device != 'mobile'">
                <!-- <vxe-table-column type="seq" width="60"></vxe-table-column> -->
                <vxe-table-column field="id" title="ID" width="60"></vxe-table-column>
                <vxe-table-column field="keyword" title="拉黑关键词" width="150"></vxe-table-column>
                <vxe-table-column field="address" title="收件人信息">
                    <!-- 换行 -->
                    <template #default="{ row }">
                        <div style="white-space: normal;">{{ row.address }}</div>
                    </template>
                </vxe-table-column>
                <vxe-table-column field="platformId" title="平台" width="80">
                    <template #default="{ row }">
                        <span v-for="item in platformList" v-if="row.platformId == item.id">
                            {{ item.name }}
                        </span>
                    </template>
                </vxe-table-column>
                <vxe-table-column field="orderNumber" title="问题订单号" width="220"></vxe-table-column>
                <!-- 严重等级，3-严重、2-中等、1-一般 -->
                <vxe-table-column field="level" title="严重等级" width="80" :align="'center'">
                    <template #default="{ row }">
                        <span v-if="row.level == 3" style="color: #fd4343;">严重</span>
                        <span v-if="row.level == 2" style="color: rgb(248, 206, 39);">中等</span>
                        <span v-if="row.level == 1" style="color: #5cb85c;">一般</span>
                    </template>
                </vxe-table-column>
                <vxe-table-column field="remark" title="备注">
                    <!-- 换行 -->
                    <template #default="{ row }">
                        <div style="white-space: normal;" v-tippy :content="row.remark">{{ row.remark }}</div>
                    </template>
                </vxe-table-column>
                <!-- 登记时间 -->
                <vxe-table-column field="createTime" title="登记时间" width="120">
                    <template #default="{ row }">
                        {{ formatDate(row.createAt) }}
                    </template>
                </vxe-table-column>
                <vxe-table-column title="操作" width="102">
                    <template #default="{ row }">
                        <vxe-button @click="edit(row)" circle>
                            <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                        </vxe-button>
                        <vxe-button @click="del(row)" circle v-if="showDel">
                            <font-awesome-icon icon="fa-solid fa-trash-can" />
                        </vxe-button>
                    </template>
                </vxe-table-column>
            </vxe-table>
        </div>
        <div class="common-bottombox" v-if="device != 'mobile'">
            <Pager :pageSizes="[10, 20, 100, 1000]" :tablePage="tablePage" @handlePageChange="handlePageChange" />
        </div>
        <!-- 弹窗 新增/编辑 -->
        <van-dialog v-model:show="modal.show" @confirm="submit()" theme="round"
            :title="modal.type == 'add' ? '添加黑名单' : '修改黑名单'" show-cancel-button confirm-button-text="提交"
            confirm-button-color="#6667ab" cancel-button-text="取消" cancel-button-color="#666">
            <div class="common-modalbox">
                <textarea class="modal-add-textarea" v-model="info.address" type="text" placeholder="收件人信息"
                    @blur="autoKeyword()"></textarea>
                <input class="modal-add-input" v-model="info.keyword" type="text" placeholder="拉黑关键词" />
                <input class="modal-add-input" v-model="info.orderNumber" type="text" placeholder="订单号（选填）"
                    @blur="autoPlatform()" />
                <select class="modal-add-select" v-model="info.platformId" placeholder="所属平台">
                    <option :value="null">平台</option>
                    <option :value="item.id" v-for="item in platformList">{{ item.name }}</option>
                </select>
                <div class="modal-add-checkbox">
                    <div class="modal-add-checkbox-title">
                        严重等级
                    </div>
                    <van-radio-group v-model="info.level" direction="horizontal">
                        <van-radio :name="3" class="modal-add-checkbox-option">严重</van-radio>
                        <van-radio :name="2" class="modal-add-checkbox-option">中等</van-radio>
                        <van-radio :name="1" class="modal-add-checkbox-option">一般</van-radio>
                    </van-radio-group>
                </div>
                <textarea class="modal-add-textarea" v-model="info.remark" placeholder="备注（选填）"></textarea>
                <!-- 备注快速输入 -->
                <div class="modal-add-checkbox" style="margin-bottom: 0;">
                    <!-- <div class="modal-add-checkbox-title">快捷备注</div> -->
                    <div class="modal-quick-box">
                        <div class="modal-quickword" @click="quickMark(item)" v-for="item in quickwords">{{ item }}
                        </div>
                    </div>
                </div>
            </div>
        </van-dialog>
    </div>
</template>

<script>
    import Clipboard from 'clipboard'
    import Title from '@/components/common/title'
    import Pager from '@/components/common/pager'
    import { Toast, Dialog } from 'vant';
    import { apiAddDarkroom, apiDarkroomList, apiDelDarkroom, apiEditDarkroom } from '@/request/api'

    export default {
        components: {
            Title,
            Pager,
        },
        name: 'Index',
        // props: {
        //   msg: String
        // },
        data() {
            return {
                device: this.GLOBAL.device,
                showDel: false,
                tableData: null,
                tablePage: {
                    currentPage: 1,
                    pageSize: 20,
                    totalResult: 0
                },
                quickwords: [
                    "发货后仅退款",
                    "白嫖",
                    "完美主义者",
                    "使用后退货"
                ],
                searchWord: null,
                modal: {
                    show: false,
                    type: "add"
                },
                platformList: null,
                info: {
                    id: null,
                    address: null,
                    keyword: null,
                    platformId: null,
                    orderNumber: null,
                    remark: null,
                    level: 1
                },
                keywords: null,
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {
                this.initClipboard()
                this.getRule()
                this.getPlatformList()
                if (this.device != 'mobile') {
                    this.getList()
                }
            },
            getRule() {
                let nickname = localStorage.getItem('nickname')
                let allowNick = ["eWei", "小舞"]
                if (allowNick.indexOf(nickname) > -1) {
                    this.showDel = true
                }
            },
            search() {
                this.tablePage.currentPage = 1
                this.reflash()
            },
            clearSearch() {
                this.searchWord = null
                this.tablePage.currentPage = 1
                this.reflash()
            },
            reflash() {
                this.tablePage.currentPage = 1
                this.tablePage.totalResult = 0
                this.tableData = null
                this.getList()
            },
            quickMark(word) {
                if (this.info.remark) {
                    this.info.remark = this.info.remark + '，' + word;
                } else {
                    this.info.remark = word;
                }
            },
            getList() {
                Toast.loading('加载中')
                let self = this
                let params = {
                    page: this.tablePage.currentPage,
                    size: this.tablePage.pageSize,
                }
                if (this.searchWord) {
                    params.search = this.searchWord
                }
                apiDarkroomList(params).then(res => {
                    if (self.device == 'mobile') {
                        if (!self.tableData) {
                            self.tableData = []
                        }
                        self.tableData.push(...res.data.items)
                        self.tablePage.currentPage = this.tablePage.currentPage + 1
                    } else {
                        self.tableData = res.data.items
                    }
                    self.keywords = self.tableData.map(item => item.keyword).join(',')
                    self.tablePage.totalResult = res.data.count
                    Toast.clear()
                }).catch(err => {
                    console.warn(err)
                })
            },
            infiniteHandler() {
                console.log("infiniteHandler-" + new Date())
                Toast.loading('加载中')
                this.getList()
            },
            getPlatformList() {
                this.platformList = JSON.parse(localStorage.getItem('platformList'))
            },
            handlePageChange({ currentPage, pageSize }) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.getList()
            },
            autoKeyword() {
                let self = this
                if (this.info.keyword) {
                    return
                }
                let addressInfo = this.info.address
                // 去掉空格
                addressInfo = addressInfo.replace(/(^\s*)|(\s*$)/g, "")
                if (!addressInfo) {
                    return
                }
                // addressInfo 按照逗号分数组
                let reg = /[,，]/g;
                let addressArr = addressInfo.split(reg);
                console.log(addressArr)
                let address
                for (let item of addressArr) {
                    if (item.indexOf('省') > -1) {
                        address = item
                        break
                    }
                }
                if (!address) {
                    return
                }
                // 去除地址前后的空格
                address = address.replace(/(^\s*)|(\s*$)/g, "")
                console.log(address)
                // 去除address中的[]
                let reg0 = /\[(.+?)\]/g;
                address = address.replace(reg0, "")
                // 获取详细地址
                let regX = /.+?(省|市|自治区|自治州|县|区|街道|镇|路)/g;
                let areaArr = address.match(regX);
                // 匹配后剩余部分加入数组
                if (areaArr && areaArr.length > 0) {
                    let area = areaArr[areaArr.length - 1]
                    let index = address.indexOf(area)
                    let detailAddress = address.substring(index + area.length)
                    areaArr.push(detailAddress)
                }
                console.log(areaArr)
                if (!areaArr || areaArr.length <= 1) {
                    return
                }
                // 去除数组中的空值
                for (let i = 0; i < areaArr.length; i++) {
                    if (!areaArr[i]) {
                        areaArr.splice(i, 1);
                        i = i - 1;
                    }
                }
                let detailAddress = areaArr[areaArr.length - 1]
                // 去除[]符号的部分
                let reg1 = /\[(.+?)\]/g;
                let keywordArr = detailAddress.match(reg1);
                if (keywordArr && keywordArr.length > 0) {
                    detailAddress = detailAddress.substring(0, detailAddress.indexOf(keywordArr[0]))
                }
                // 去除数字和之后的部分
                let reg2 = /[\d]+/g;
                let detailAddressArr = detailAddress.match(reg2);
                if (detailAddressArr && detailAddressArr.length > 0) {
                    detailAddress = detailAddress.substring(0, detailAddress.indexOf(detailAddressArr[0]))
                }
                // 去掉一、二、三、四、五、六、七、八、九、十之后的部分
                let reg3 = /一|二|三|四|五|六|七|八|九|十/g;
                let detailAddressArr2 = detailAddress.match(reg3);
                if (detailAddressArr2 && detailAddressArr2.length > 0) {
                    detailAddress = detailAddress.substring(0, detailAddress.indexOf(detailAddressArr2[0]))
                }
                // 去掉头尾的空格
                detailAddress = detailAddress.replace(/(^\s*)|(\s*$)/g, "")
                console.log(detailAddress)
                this.info.keyword = detailAddress
            },
            autoPlatform() {
                let orderNumber = this.info.orderNumber
                let name = ""
                console.log(orderNumber)
                if (!orderNumber) {
                    return
                }
                // 拼多多订单号判断
                if (orderNumber.indexOf('-') != -1) {
                    name = '拼多多'
                }
                // 优化订单号判断 Todo
                for (let item of this.platformList) {
                    if (item.name == name) {
                        this.info.platformId = item.id
                        return
                    }
                }


            },
            submit() {
                let self = this
                if (!this.info.address) {
                    Toast.fail('收件人信息不能为空');
                    this.$message.error('收件人信息不能为空')
                    return
                }
                if (!this.info.keyword) {
                    Toast.fail('拉黑关键词不能为空');
                    return
                }
                if (this.modal.type == 'add') {
                    apiAddDarkroom(this.info).then(res => {
                        self.reflash()
                        self.modal.show = false
                        Toast.success('添加成功');
                    }).catch(err => {
                        console.warn(err)
                    })
                } else {
                    apiEditDarkroom(this.info).then(res => {
                        // self.reflash()
                        self.tableData = null
                        self.getList()
                        self.modal.show = false
                        Toast.success('修改成功')
                    }).catch(err => {
                        console.warn(err)
                    })
                }
            },
            openModal(type) {
                if (type == 'add') {
                    this.info = {
                        id: null,
                        address: null,
                        keyword: null,
                        platformId: null,
                        orderNumber: null,
                        remark: null,
                        level: 1
                    }
                }
                this.modal = {
                    show: true,
                    type: type
                }
            },
            edit(row) {
                this.info = JSON.parse(JSON.stringify(row))
                this.openModal('edit')
            },
            del(row) {
                let self = this
                Dialog.confirm({
                    title: '提示',
                    message: '确定删除吗？',
                }).then(() => {
                    apiDelDarkroom(row).then(res => {
                        self.reflash()
                        Toast.success('删除成功');
                    }).catch(err => {
                        console.warn(err)
                    })
                }).catch(() => {
                    // on cancel
                });
            },
            initClipboard() {
                const clipboard = new Clipboard(".copy");
                clipboard.on("success", function (e) {
                    Toast.success('已复制到剪贴板');
                    e.clearSelection();
                });
                clipboard.on("error", function (e) {
                    Toast.fail('复制失败');
                });
            },
            // 人性化时间
            formatDate(value) {
                let minute = 1000 * 60;
                let hour = minute * 60;
                let day = hour * 24;
                let halfamonth = day * 15;
                let month = day * 30;

                var now = new Date().getTime();
                var diffValue = now - value;

                if (diffValue < 0) {
                    console.warn("结束日期不能小于开始日期！");
                }

                var monthC = diffValue / month;
                var weekC = diffValue / (7 * day);
                var dayC = diffValue / day;
                var hourC = diffValue / hour;
                var minC = diffValue / minute;

                let result;

                if (monthC >= 1) {
                    result = parseInt(monthC) + "个月前";
                }
                else if (weekC >= 1) {
                    result = parseInt(weekC) + "周前";
                }
                else if (dayC >= 1) {
                    result = parseInt(dayC) + "天前";
                }
                else if (hourC >= 1) {
                    result = parseInt(hourC) + "小时前";
                }
                else if (minC >= 1) {
                    result = parseInt(minC) + "分钟前";
                } else {
                    result = "刚刚";
                }
                return result;

            }
        }
    }
</script>

<style scoped>
    .container-darkroom {
        width: 100%;
        height: 100%;
        overflow: auto;
        position: relative;
        display: flex;
        flex-direction: column;
    }

    /* 弹窗 */
    .modal-add-select {
        width: 100%;
        height: 30px;
        border-radius: 15px;
        border: 1px solid #e6e6ef;
        outline: none;
        margin-bottom: 15px;
        padding: 0 10px;
        font-size: 14px;
        color: #333;
    }

    .modal-add-textarea {
        width: 100%;
        height: 80px;
        border-radius: 15px;
        border: 1px solid #e6e6ef;
        outline: none;
        padding: 10px 10px;
        font-size: 14px;
        color: #333;
        resize: none;
        margin-bottom: 15px;
    }

    .modal-add-textarea:last-child {
        margin-bottom: 0;
    }

    .modal-add-input {
        width: 100%;
        height: 30px;
        border-radius: 15px;
        border: 1px solid #e6e6ef;
        outline: none;
        margin-bottom: 15px;
        padding: 0 10px;
        font-size: 14px;
        color: #333;
    }


    .modal-add-checkbox {
        margin-bottom: 15px;
    }

    .modal-add-checkbox-title {
        margin-bottom: 10px;
        font-size: 14px;
        color: #666;
    }

    .modal-add-checkbox-option {
        margin-bottom: 6px;
        font-size: 14px;
    }

    .modal-quick-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
    }

    .modal-quickword {
        font-size: 13px;
        padding: 4px 10px;
        margin-bottom: 10px;
        margin-right: 10px;
        background-color: #e9e9eb;
        color: #666;
        border-radius: 6px;
        cursor: pointer;
    }

    /* 搜索 */

    .searchbox {
        width: 100%;
        padding: 20px 0 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
    }

    .searchbox-input {
        flex: 1;
        border: 1px solid #e9e9eb;
        outline: none;
        height: 32px;
        border-radius: 16px 0 0 16px;
        padding: 0 15px;
        font-size: 14px;
    }

    .searchbox-btn {
        width: 80px;
        border: none;
        outline: none;
        height: 32px;
        background-color: #e9e9eb;
        color: #333;
        border-radius: 0 16px 16px 0;
        cursor: pointer;
    }

    .searchbox-clear {
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        position: absolute;
        top: 21px;
        right: 85px;
        color: #ddd;
        cursor: pointer;
    }

    /* 卡片 */
    .darkCard {
        width: 100%;
    }

    /* 卡片手机版 */
    .darkCard-Single {
        background: #FBFBFB;
        border: 1px solid #EFEEEE;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, .1);
        border-radius: 20px;
        padding: 15px;
        margin-bottom: 15px;
        position: relative;
        overflow: hidden;
        overflow: hidden;
    }

    .darkCard-keyword {
        font-size: 16px;
        color: #000;
        font-weight: bold;
    }

    .darkCard-address {
        margin-top: 5px;
        font-size: 14px;
        line-height: 1.6;
        color: #000;
    }

    .darkCard-platform {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 6px 0;
    }

    .darkCard-platform-name {
        font-size: 12px;
        line-height: 1.6;
        color: #999;
        margin-right: 10px;

    }

    .darkCard-platform-number {
        flex: 1;
        font-size: 12px;
        line-height: 1.6;
        color: #999;
    }

    .darkCard-remark {
        margin-top: 2px;
        font-size: 14px;
        color: #666;
        background-color: #efefef;
        padding: 10px 13px;
        border-radius: 10px;
        line-height: 1.6;
    }

    .darkCard-level {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 12px;
        color: #fff;
        background-color: #ddd;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 0 0 0 10px;
        padding: 0 15px;
    }

    .darkCard-level-1 {
        background-color: #5cb85c;
    }

    .darkCard-level-2 {
        background-color: rgb(248, 206, 39);
    }

    .darkCard-level-3 {
        background-color: #fd4343;
    }

    .darkCard-btnbox {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        margin-top: 15px;
    }


    .darkCard-btn:hover {
        opacity: 0.8;
    }

    .darkCard-btn span {
        margin-left: 6px;
    }

    .darkCard-createtime {
        font-size: 12px;
        flex: 1;
        text-align: right;
        color: #b2b2b2;
        padding: 0 5px;
    }



    /* mobile */
    @media screen and (max-width: 480px) {
        .searchbox {
            padding: 20px 15px 10px;
        }

        .searchbox-input {
            height: 40px;
            border-radius: 20px 0 0 20px;
        }

        .searchbox-btn {
            height: 40px;
            border-radius: 0 20px 20px 0;
        }

        .searchbox-clear {
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            position: absolute;
            top: 25px;
            right: 100px;
            color: #ddd;
        }
    }

    .no-more,
    .no-results {
        text-align: center;
        color: #999;
        font-size: 12px;
        padding: 10px 0;
    }
</style>